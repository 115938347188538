import { put, call, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Store
import * as a from './actions';
// Utils
import { checkProjectTab } from '../../Projects/ProjectsTabs/utils';

function* getRankSelectionSetDataWorker({ payload }) {
  try {
    const { setId, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = !isProjectTab ? Api.getSimpleOrEffectSetContent : Api.getProjectSet;
    const requestData = !isProjectTab ? setId : { projectId, setId };
    const { data } = yield call(apiToCall, requestData);

    yield put(a.setSelectedSetInfoAction({
      setId: setId,
      projectId,
      setName: data.name,
    }));
    yield put(a.setRankSelectionSetDataAction({
      ...data,
      tags: data.tags.map((name, id) => ({ id, name })),
    }));
  } catch (e) {
    yield put(a.throwRankSelectionSetDataAction(e.message));
  }
}

function* setRankSelectionInputDataWorker({ payload }) {
  try {
    const { conceptId } = payload;
    const { data } = yield call(Api.conceptName, conceptId);
    yield put(a.setSelectedConceptAction({
      id: conceptId,
      data,
    }));
  } catch (e) {
    yield put(a.throwRankSelectionSetDataAction(e.message));
  }

}

function* editSetSaga() {
  yield takeLatest(a.getRankSelectionSetDataAction, getRankSelectionSetDataWorker);
  yield takeLatest(a.setRankSelectionInputDataAction, setRankSelectionInputDataWorker);
}

export default editSetSaga;
