import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Actions
import * as a from './actions';

const initialState = fromJS({
  inputValue: '',
  concepts: {
    data: null,
    loading: false,
    error: '',
  },
  filters: {
    semanticCategory: null,
    semanticType: null,
    taxonomy: null,
  },
  categories: {
    data: null,
    loading: false,
    error: '',
  },
  selectedSetInfo: {
    setId: null,
    projectId: 'personal',
    setName: null,
  },
  activeCategories: null,
  geneTaxonomies: [],
  semanticTypesCounts: {},
  semanticCategoriesCounts: {},
  suggestions: [],
});

const reducer = handleActions(
  {
    [a.setSearchConceptFiltersAction]: (state, { payload }) =>
      state
        .update('filters', filters => filters.merge(fromJS(payload))),
    [a.resetSearchConceptFiltersAction]: state =>
      state
        .update('filters', () => initialState.get('filters')),
    [a.getSearchConceptDataAction]: state =>
      state
        .updateIn(['concepts', 'loading'], () => true),
    [a.setActiveCategoriesAction]: (state, { payload }) =>
      state
        .updateIn(['activeCategories'], () => payload)
        .update('filters', filters => filters.merge(fromJS({semanticType: payload ? payload[0] : null, semanticCategory: null, taxonomy: null}))),
    [a.setSearchConceptDataAction]: (state, { payload }) =>
      state
        .updateIn(['concepts', 'data'], () => fromJS(payload.result))
        .updateIn(['concepts', 'loading'], () => false)
        .updateIn(['concepts', 'error'], () => '')
        .update('suggestions', () => fromJS(payload.suggestions))
        .update('geneTaxonomies', () => fromJS(payload.geneTaxonomyCounts))
        .update('semanticTypesCounts', () => fromJS(payload.semanticTypesCounts))
        .update('semanticCategoriesCounts', () => fromJS(payload.semanticCategoriesCounts)),
    [a.throwSearchConceptDataErrorAction]: (state, { payload }) =>
      state
        .updateIn(['concepts', 'data'], () => initialState.getIn(['concepts', 'data']))
        .updateIn(['concepts', 'loading'], () => false)
        .updateIn(['concepts', 'error'], () => payload)
        .update('geneTaxonomies', () => initialState.get('geneTaxonomies'))
        .update('semanticTypesCounts', () => initialState.get('semanticTypesCounts'))
        .update('semanticCategoriesCounts', () => initialState.get('semanticCategoriesCounts')),
    [a.setSearchConceptInputValueAction]: (state, { payload }) =>
      state.update('inputValue', () => payload),
    [a.getConceptSearchCategoriesAction]: state =>
      state
        .updateIn(['categories', 'loading'], () => true),
    [a.setConceptSearchCategoriesAction]: (state, { payload }) =>
      state
        .updateIn(['categories', 'data'], () => fromJS(payload))
        .updateIn(['categories', 'loading'], () => false)
        .updateIn(['categories', 'error'], () => ''),
    [a.setSelectedSetInfoAction]: (state, { payload }) =>
      state
        .updateIn(['selectedSetInfo'], () => fromJS(payload)),
    [a.throwConceptSearchCategoriesErrorAction]: (state, { payload }) =>
      state
        .updateIn(['categories', 'data'], () => initialState.getIn(['categories', 'data']))
        .updateIn(['categories', 'loading'], () => false)
        .updateIn(['categories', 'error'], () => payload),
    [a.resetSearchConceptDataAction]: state =>
      state
        .updateIn(['concepts', 'data'], () => initialState.getIn(['concepts', 'data']))
        .updateIn(['concepts', 'loading'], () => false)
        .update('suggestions', () => initialState.get('suggestions'))
        .update('geneTaxonomies', () => initialState.get('geneTaxonomies'))
        .update('semanticTypesCounts', () => initialState.get('semanticTypesCounts'))
        .update('semanticCategoriesCounts', () => initialState.get('semanticCategoriesCounts')),
    [a.resetSearchConceptAction]: state => state.merge(initialState),
  },
  initialState
);

export default reducer;
