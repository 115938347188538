import { createAction } from 'redux-actions';

export const getCreateSetConceptsAction = createAction('@createSet/GET_CONCEPTS');
export const setCreateSetConceptsAction = createAction('@createSet/SET_CONCEPTS');
export const setCreateSetPublicationSourceAction = createAction('@createSet/SET_CREATE_SET_PUBLICATION_SOURCE');
export const getCreateSetAllConceptsAction = createAction('@createSet/GET_ALL_CONCEPTS');
export const setCreateSetAllConceptsAction = createAction('@createSet/SET_ALL_CONCEPTS');
export const updateCreateSetNameAction = createAction('@createSet/UPDATE_NAME');
export const updateCreateSetDescriptionAction = createAction('@createSet/UPDATE_DESCRIPTION');
export const updateCreateSetTagsAction = createAction('@createSet/UPDATE_TAGS');
export const toggleCreateSetLoaderAction = createAction('@createSet/TOGGLE_LOADER');
export const throwCreateSetErrorAction = createAction('@createSet/HANDLE_ERROR');
export const throwSaveSetErrorAction = createAction('@createSet/HANDLE_SAVE_SET_ERROR');
export const throwSaveSetFreeUserErrorAction = createAction('@createSet/HANDLE_SAVE_SET_FREE_USER_ERROR');
export const clearSaveSetErrorAction = createAction('@createSet/CLEAR_SAVE_SET_ERROR');
export const clearSaveSetFreeUserErrorAction = createAction('@createSet/CLEAR_SAVE_SET_FREE_USER_ERROR');
export const resetCreateSetAction = createAction('@createSet/RESET');
export const saveCreatedSetAction = createAction('@createSet/SAVE');
export const setAvailableFiltersAction = createAction('@createSet/SET_AVAILABLE_FILTERS');
export const getGeneSetFilterOptionsAction = createAction('@createSet/GET_GENE_SET_FILTER_OPTIONS');
export const setGeneSetFilterOptionsAction = createAction('@createSet/SET_GENE_SET_FILTER_OPTIONS');
export const setGeneSetFilterDataAction = createAction('@createSet/SET_GENE_SET_FILTER_DATA');
export const setSavedSuccessfullyAction = createAction('@createSet/SAVED_SUCCESSFULLY_CREATED_SET');
export const clearSavedSuccessfullyAction = createAction('@createSet/CLEAR_SAVED_SUCCESSFULLY_CREATED_SET');
export const updateCreateSetUIFiltersAction = createAction('@createSet/UPDATE_UI_FILTERS');
export const sortCreateSetConceptsAction = createAction('@createSet/SORT_CONCEPTS');
export const getSourceDetailsFilterOptionsAction = createAction('@createSet/GET_SOURCE_DETAILS_FILTER_OPTIONS');
export const setSourceDetailsFilterOptionsAction = createAction('@createSet/SET_SOURCE_DETAILS_FILTER_OPTIONS');
export const selectCreateSetProjectAction = createAction('@createSet/SELECT_PROJECT');
