import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Styles
import './styles.scss';

import Loader from '../Loader/Loader';
import BilevelPartitionDiagram from '../../graphics/BilevelPartitionDiagram/BilevelPartitionDiagram';
import {apiTypes} from '../../Sets/CreateSet/enums';
import {isEmptyObject} from '../../Utils/Utils';
import Error from '../Error/Error';
import {RELATIVE_PATH} from '../../../constantsCommon';
import ShortConceptCardCell from '../../Concept/ShortConceptCard/ShortConceptCardCell';
import ModalComponent from '../../ModalComponent/ModalComponent';
import CreateSet from '../../Sets/CreateSet/CreateSet';

const propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  loadConceptToConceptIndirect: PropTypes.func,
  resetSelectGeneOptions: PropTypes.func,
  data: PropTypes.object,
  diseaseName: PropTypes.string,
  customClassName: PropTypes.string,
  clearFieldAfterSelect: PropTypes.bool,
};

const ConceptToConceptIndirectChart = (props) => {
  const {
    error,
    loading,
    loadConceptToConceptIndirect,
    data,
    customClassName,
  } = props;

  const { concept1, concept2} = useParams();
  const [createSetPopup, setCreateSetPopup] = useState({show: false});

  useEffect(() => {
    loadConceptToConceptIndirect({
      concept1,
      concept2,
    });
  }, []);

  const openCreateSetPopup = (semanticType) => {
    const { id, name } = semanticType;
    setCreateSetPopup({
      show: true,
      config: {
        conceptIds: data.conceptsBySemType[id].map(c => c.id),
        conceptNames: data.conceptsBySemType[id].map(c => c.name),
        recommendationName: name,
        semanticType: id,
        categoryName: name,
        apiType: apiTypes.filterWithoutStartConcept,

        setName: 'Associations score',
        category: name,
        concepts: data.conceptsBySemType[id],
      },
      initialFilters: {
        SKIP_ZERO_PUBLICATIONS: false,
      },
    });
  };

  const closeCreateSetPopup = () => {
    setCreateSetPopup({
      show: false,
      config: null,
    });
  };

  const showTooltipCell = useCallback((concept) => {
    if (!concept) return null;

    const { id, name, semanticCategory } = concept;
    const link = semanticCategory === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    return (
      <ShortConceptCardCell
        id={id}
        uniqueKey={`tooltip-${id}`}
        name={name}
        link={link}
      />
    );
  }, []);

  return (
    <div className={classnames('concept-to-concept', customClassName)}>
      <div className="concept-to-concept-title">Analyse associations</div>
      <div className="concept-to-concept-separator"/>
      <div className="concept-to-concept-body">
        <div className="concept-to-concept-info">
          Association score: {data.score}%.<br/><br/>
          Click on a segment to view the concepts that are associated with both:<br/>
          {showTooltipCell(data.concept1)}
          and
          {showTooltipCell(data.concept2)}
        </div>
        <div className="concept-to-concept-chart">
          {
            data.intermediates && !isEmptyObject(data.intermediates) && !error &&
            <BilevelPartitionDiagram
              selectTypeCb={openCreateSetPopup}
              data={data.intermediates}
            />
          }
          <Loader isLoading={loading && !error}/>
          <Error show={error && !loading} error={error}/>
        </div>
        {
          createSetPopup.show &&
          <ModalComponent
            isOpen={createSetPopup.show}
            closeCb={closeCreateSetPopup}
            modalClassName="modal_no-paddings"
          >
            <CreateSet
              config={createSetPopup.config}
              initialFilters={createSetPopup.initialFilters}
              closePopup={closeCreateSetPopup}
            />
          </ModalComponent>
        }
      </div>
    </div>
  );
};

ConceptToConceptIndirectChart.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getConceptToConceptDataSelector(state),
    loading: SELECTORS.getConceptToConceptLoaderSelector(state),
    error: SELECTORS.getConceptToConceptErrorSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadConceptToConceptIndirect(data) {
      dispatch(ACTIONS.getConceptToConceptIndirectAction(data));
    },
    resetSelectGeneOptions(data) {
      dispatch(ACTIONS.resetSelectDiseaseAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConceptToConceptIndirectChart);
