import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import {
  getSelectedConceptSelector,
  getRankSelctionMethodSelector,
  getSelectedSetInfoSelector
} from '../../store/selectors';
import {
  getKeysByBool,
} from '../../../Utils/Utils';

import {
  RANK_SELECTION_FAILED,
  RANK_SELECTION_SUCCEEDED,
  RANK_SELECTION_REQUESTED,
  LOADING,
  RANK_SELECTION_RESULT_TABLE,
} from './constants';

export function* getDataForRankRequest() {
  try {
    const selectedSet = yield select(getSelectedSetInfoSelector);
    const disease = yield select(getSelectedConceptSelector);
    const rankingTypes = yield select(getRankSelctionMethodSelector);

    let requestData = {
      setId: selectedSet.setId,
      projectId: selectedSet.projectId === 'personal' ? null : selectedSet.projectId,
      disorderGi: disease.id,
    };

    requestData = Object.assign(requestData, {
      rankingItems: getKeysByBool(rankingTypes, true),
    });

    if (requestData.rankingItems.indexOf('associationScore') > -1) {
      requestData = Object.assign(requestData, {
        associationScore: {
          conceptsGis: [disease.id],
        },
      });
    }

    if (requestData.rankingItems.indexOf('literatureSearch') > -1) {
      requestData = Object.assign(requestData, {
        literatureSearch: {
          phrases: [{ id: disease.id, name: disease.name }],
        },
      });
    }

    if (requestData.rankingItems.indexOf('differentialExpression') > -1) {
      requestData = Object.assign(requestData, {
        differentialExpression: {
          id: disease.id,
          name: disease.name,
        },
      });
    }

    if (requestData.rankingItems.indexOf('geneVariation') > -1) {
      requestData = Object.assign(requestData, {
        geneVariation: {
          id: disease.id,
          name: disease.name,
        },
      });
    }

    return requestData;
  } catch (error) {
    console.log(error);
  }
}

function* rankRequestSaga() {
  try {
    yield put({ type: RANK_SELECTION_RESULT_TABLE + LOADING, data: true });

    const response = yield call(Api.ranking, {
      post: yield getDataForRankRequest(),
      params: {
        size: 20000,
        page: 0,
      },
    });

    response.data.content.forEach((item) => {
      if (item.literatureSearchData) {
        item.literatureSearch = item.literatureSearchData[0].literatureSearch;
      }
      if (item.associationScore) {
        item.associationScore = item.associationScore[Object.keys(item.associationScore)[0]];
      }
      if (item.targetValidation) {
        const targetValidationKeys = Object.keys(item.targetValidation);
        targetValidationKeys.forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item[key] = item.targetValidation[key];
        });
      }
    });

    yield put({ type: RANK_SELECTION_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: RANK_SELECTION_FAILED, message: e.message });
  }
}

function* rankSelectionResultSaga() {
  yield takeLatest(RANK_SELECTION_REQUESTED, rankRequestSaga);
}

export default rankSelectionResultSaga;
