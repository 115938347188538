import React, { useState, useCallback, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { change } from 'redux-form';
// Components
import SearchFilter from '../SearchFilter/SearchFilter';
import SearchPageHeader from '../SearchPageHeader/SearchPageHeader';
import SearchResultItem from '../SearchResultItem/SearchResultItem';
import SearchCategoryTabs from '../SearchCategoryTab/SearchCategoryTabs';
import SearchRecomendations from '../SearchRecommendations/SearchRecomendations';
import SearchPublicationsManage from '../SearchPublicationsManage/SearchPublicationsManage';
import SearchRelatedConceptsCardWrapper from '../SearchRelatedConceptsCard/SearchRelatedConceptsCardWrapper';
import FindResultsForSection from '../FindResultsForSection/FindResultsForSection';
import ConceptCards from '../../../Concept/ConceptCards/ConceptCards';
import CreateSetModal from '../../../Sets/CreateSetModal/CreateSetModal';
import Loader from '../../../common/Loader/Loader';
import Error from '../../../common/Error/Error';
import NoData from '../../../common/NoData/NoData';
import PublicationsFilterGraph from '../../../graphics/PublicationsFilterGraph/PublicationsFilterGraph';
// Utils
import { SearchSourceFilterEnum } from '../../enums';
import { getSearchLinkParams, buildSearchLink } from '../../utils';
import { manageCheckedItem } from '../../../Publications/PublicationsManagement/utils';
// Constants
import { SEARCH_FILTER_FORM } from '../SearchFilter/constants';
// Store
import { getRightFindUrlSuffix, isReprintDeskAllowed, isRightFindAllowed } from '../../../Header/selectors';
import { savePublicationsToProjectAction } from '../../../Modals/SelectProjectModal/store/actions';
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';
// Styles
import './index.scss';

const propTypes = {
  publications: PropTypes.instanceOf(Array),
  selectedPublicationsIds: PropTypes.instanceOf(Array),
  updateSelectedPublicationsIds: PropTypes.func,
  savePublicationsToProject: PropTypes.func,
  pubSources: PropTypes.instanceOf(Array),
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  pageNumber: PropTypes.number,
  searchTypes: PropTypes.instanceOf(Object),
  searchValues: PropTypes.instanceOf(Object),
  recommendations: PropTypes.instanceOf(Object),
  relatedConcepts: PropTypes.instanceOf(Object),
  searchFilterInitialValues: PropTypes.instanceOf(Object),
  exactSearchValue: PropTypes.string,
  pubmedUrlPrefix: PropTypes.string,
  selectedPubmedIds: PropTypes.instanceOf(Array),
  updateSelectedPubmedIds: PropTypes.func,
  checkSearchRelatedConcept: PropTypes.func,
  showRightFind: PropTypes.bool,
  showReprintDesk: PropTypes.bool,
  rightFindUrlSuffix: PropTypes.string,
  updateSavedLink: PropTypes.func,
  toggleSearch: PropTypes.func,
  resetSearchPage: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  changeYearsFormValues: PropTypes.func,
  publicationFilterExtended: PropTypes.bool,
  setPublicationFilterExtended: PropTypes.func,
};

const SearchPageMain = (props) => {
  const {
    publications,
    selectedPublicationsIds,
    updateSelectedPublicationsIds,
    savePublicationsToProject,
    pubSources,
    totalItems,
    totalPages,
    pageNumber,
    searchTypes,
    searchValues = {},
    recommendations,
    relatedConcepts,
    checkSearchRelatedConcept,
    searchFilterInitialValues,
    exactSearchValue,
    pubmedUrlPrefix,
    selectedPubmedIds,
    updateSelectedPubmedIds,
    showRightFind,
    showReprintDesk,
    rightFindUrlSuffix,
    updateSavedLink,
    toggleSearch,
    resetSearchPage,
    error,
    loading,
    changeYearsFormValues,
    publicationFilterExtended,
    setPublicationFilterExtended,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [createSetPopup, setCreateSetPopup] = useState({ show: false, config: null });
  const [extendedGraphWidth, setExtendedGraphWidth] = useState(0);

  useLayoutEffect(() => {
    function handleResize() {
      setExtendedGraphWidth(
        document.querySelector('.search-page-main__filters').clientWidth +
        document.querySelector('.search-page-main__content').clientWidth +
        document.querySelector('.search-page-main__sidebar-wrap').clientWidth
      );
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const pushNewLink = useCallback((link) => {
    navigate(link);
    updateSavedLink(link);
  }, [navigate, updateSavedLink]);

  const submitSearchFilters = useCallback((passedSearchValues = {}) => {
    const searchParams = getSearchLinkParams(location.search);

    if (searchValues.publicationSource === SearchSourceFilterEnum.DATABASE_PUBMED) {
      searchValues.sources = ['0-49'];
    }

    const link = buildSearchLink({
      ...(searchParams.plainValue && { plainValue: searchParams.plainValue }),
      ...(searchParams.exactValue && { exactValue: searchParams.exactValue }),
      ...(searchValues && { ...searchValues, ...passedSearchValues }),
    });

    pushNewLink(link);
  }, [location, searchValues]);

  const startSearchWithNewParams = useCallback((data = {}) => {
    const link = buildSearchLink(data);
    pushNewLink(link);
    resetSearchPage();
  }, []);

  const handlePageChange = useCallback(({ selected: page }) => {
    const searchParams = getSearchLinkParams(location.search);
    const link = buildSearchLink({
      ...searchParams,
      page,
    });
    pushNewLink(link);
  }, [location]);

  const checkPublication = useCallback(({ id, pmid }) => {
    const newSelectedPublicationsIds = manageCheckedItem(id, selectedPublicationsIds);
    updateSelectedPublicationsIds(newSelectedPublicationsIds);
    if (pmid) {
      const newSelectedPubmedIds = manageCheckedItem(pmid, selectedPubmedIds);
      updateSelectedPubmedIds(newSelectedPubmedIds);
    }
  }, [selectedPublicationsIds, selectedPubmedIds]);

  const openCreateSetPopup = useCallback((c) => {
    const config = {
      ...c,
      categoryName: c.categoryName || relatedConcepts.categoryName,
    };
    setCreateSetPopup({ show: true, config });
  }, [relatedConcepts, setCreateSetPopup]);

  const isDatabase = useMemo(
    () => searchValues.publicationSource === SearchSourceFilterEnum.DATABASE,
    [searchValues]
  );

  const isSentenceCooccurrence = useMemo(
    () => searchValues.cooccurrenceType === SearchSourceFilterEnum.SENTENCE,
    [searchValues]
  );

  return (
    <div className={classNames('search-page-main', { 'search-page-main--extended': publicationFilterExtended })}>
      <SearchPageHeader
        searchValues={searchValues}
        submitSearchFilters={submitSearchFilters}
        toggleSearch={toggleSearch}
      />
      <div className="search-page-main__wrap">
        <div className="search-page-main__graph-block">
          <div className="search-page-main__total title5">
            Total results: {totalItems}
          </div>
          <div className="search-page-main__filter-graph-block">
            {
              !isDatabase &&
                <PublicationsFilterGraph
                  showTitle={false}
                  abilityToExtend={true}
                  geneId={exactSearchValue}
                  graphId="search-section-graph"
                  changeYearsValues={changeYearsFormValues}
                  dateFrom={Number(searchValues.dateFrom)}
                  dateTo={Number(searchValues.dateTo)}
                  extendedGraphWidth={extendedGraphWidth}
                  publicationSource={searchValues.publicationSource}
                  publicationFilterExtended={publicationFilterExtended}
                  setPublicationFilterExtended={setPublicationFilterExtended}
                />
            }
          </div>
        </div>

        <div className="search-page-main__filters">
          <SearchFilter
            handleOnSubmit={submitSearchFilters}
            pubSources={pubSources}
            sources={searchValues && searchValues.sources ? searchValues.sources : []}
            searchValues={searchValues}
            searchTypes={searchTypes}
            searchValue={exactSearchValue}
            initialValues={searchFilterInitialValues}
            publicationFilterExtended={publicationFilterExtended}
            setPublicationFilterExtended={setPublicationFilterExtended}
            isSentenceCooccurrence={isSentenceCooccurrence}
            isDatabase={isDatabase}
          />
        </div>
        <div className="search-page-main__content">
          {
            publications.map((item, index) => (
              <SearchResultItem
                key={index}
                publication={item}
                pubmedUrlPrefix={pubmedUrlPrefix}
                pmidList={selectedPubmedIds}
                showRightFind={showRightFind}
                showReprintDesk={showReprintDesk}
                rightFindUrlSuffix={rightFindUrlSuffix}
                onChange={checkPublication}
                isAvailableForSelecting={true}
              />
            ))
          }
          {
            totalPages > 1 &&
            <div className="search-page__pagination">
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakClassName="break-me"
                pageCount={totalPages}
                forcePage={pageNumber}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>
          }
          <Loader
            isLoading={loading}
            withOverlay={true}
          />
          <NoData
            show={!loading && totalItems === 0}
            customClassName="search-page__no-results"
          />
          <Error
            show={!loading && error}
            error={error}
          />
        </div>
        <div className="search-page-main__sidebar">
          <div className="search-page-main__sidebar-wrap">
            <ConceptCards />
            <SearchPublicationsManage
              showRightFind={showRightFind}
              selectedPubmedIds={selectedPubmedIds}
              selectedPublicationsIds={selectedPublicationsIds}
              savePublicationsToProject={savePublicationsToProject}
              updateSelectedPublicationsIds={updateSelectedPublicationsIds}
            />
            <SearchCategoryTabs
              openCreateSetPopup={openCreateSetPopup}
              selectedSearchCategory={(relatedConcepts.categoryName || '').toLowerCase()}
            />
            <SearchRecomendations
              recommendations={recommendations}
              startNewSearch={startSearchWithNewParams}
              openCreateSetPopup={openCreateSetPopup}
            />
            <SearchRelatedConceptsCardWrapper
              startNewSearch={startSearchWithNewParams}
              checkRelatedConceptCb={checkSearchRelatedConcept}
              selectedSearchCategory={(relatedConcepts.categoryName || '').toLowerCase()}
              concepts={relatedConcepts.concepts}
              categoryConceptsSize={relatedConcepts.categoryConceptsSize}
              openCreateSetPopup={openCreateSetPopup}
            />
            <FindResultsForSection
              recommendations={recommendations.FIND_RESULTS_FOR}
              startNewSearch={startSearchWithNewParams}
            />
          </div>
        </div>
      </div>
      <CreateSetModal
        config={createSetPopup.config}
        isOpen={createSetPopup.show}
        closeCb={() => { setCreateSetPopup({ show: false, config: null }); }}
      />
    </div>
  );
};

SearchPageMain.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    publications: SELECTORS.getPublications(state),
    selectedPublicationsIds: SELECTORS.getSelectedPublicationsIdsSelector(state),
    pubSources: SELECTORS.getPubSources(state),
    pageNumber: SELECTORS.getPageNumber(state),
    totalItems: SELECTORS.getTotalItems(state),
    recommendations: SELECTORS.getRecommendations(state),
    relatedConcepts: SELECTORS.getRelatedConcepts(state),
    pubmedUrlPrefix: SELECTORS.getPubmedUrlPrefix(state),
    selectedPubmedIds: SELECTORS.getSelectedPubmedIdsSelector(state),
    searchTypes: SELECTORS.getSearchTypesSelector(state),
    exactSearchValue: SELECTORS.getExactSearchValue(state),
    searchFilterInitialValues: SELECTORS.getSearchFilterInitialValuesSelector(state),
    showRightFind: isRightFindAllowed(state),
    showReprintDesk: isReprintDeskAllowed(state),
    rightFindUrlSuffix: getRightFindUrlSuffix(state),
    error: SELECTORS.getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkSearchRelatedConcept(data) {
      dispatch(ACTIONS.checkSearchRelatedConceptAction(data));
    },
    updateSavedLink(data) {
      dispatch(ACTIONS.updateSearchSavedLinkAction(data));
    },
    updateSelectedPublicationsIds(data) {
      dispatch(ACTIONS.updateSelectedPublicationsIdsAction(data));
    },
    updateSelectedPubmedIds(data) {
      dispatch(ACTIONS.updateSelectedPubmedIdsAction(data));
    },
    savePublicationsToProject(data) {
      dispatch(savePublicationsToProjectAction(data));
    },
    changeYearsFormValues(values) {
      dispatch(change(SEARCH_FILTER_FORM, 'dateFrom', values[0]));
      dispatch(change(SEARCH_FILTER_FORM, 'dateTo', values[1]));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPageMain);
