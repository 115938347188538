import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

// Utils
import { createSetSortingTypes, publicationsFiltersNames } from '../enums';
import { SearchSourceFilterEnum } from '../../../Search/enums';
// Actions
import * as a from './actions';

const initialState = fromJS({
  name: '',
  description: 'References: ALL.',
  selectedProject: null,
  tags: [],
  concepts: null,
  allConcepts: null,
  loading: false,
  error: null,
  errorOnSave: null,
  availableFilters: null,
  setSavedSuccessfully: null,
  filtersData: {
    filter: null,
    filters: null,
  },
  publicationSource: SearchSourceFilterEnum.ALL,
  uiFilters: {
    conceptName: '',
  },
  sorting: {
    sortBy: createSetSortingTypes.COUNT,
    sortDirection: 'DESC',
  },
  savedSetInfo: {
    setId: null,
    projectId: null,
    setName: null,
  }
});

const reducer = handleActions(
  {
    [a.setCreateSetConceptsAction]: (state, { payload }) =>
      state
        .update('concepts', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null)
        .update('errorOnSave', () => null),
    [a.setCreateSetAllConceptsAction]: (state, { payload }) =>
      state
        .update('concepts', () => fromJS(payload))
        .update('allConcepts', () => fromJS(payload))
        .update('error', () => null)
        .update('errorOnSave', () => null),
    [a.updateCreateSetNameAction]: (state, { payload }) =>
      state.update('name', () => payload),
    [a.updateCreateSetDescriptionAction]: (state, { payload }) =>
      state.update('description', () => payload),
    [a.toggleCreateSetLoaderAction]: (state, { payload }) =>
      state.update('loading', () => payload),
    [a.throwCreateSetErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [a.throwSaveSetErrorAction]: (state, { payload }) =>
      state.update('errorOnSave', () => payload),
    [a.clearSaveSetErrorAction]: state =>
      state.update('errorOnSave', () => null),
    [a.updateCreateSetTagsAction]: (state, { payload }) =>
      state.update('tags', () => fromJS(payload)),
    [a.setAvailableFiltersAction]: (state, { payload }) =>
      state.update('availableFilters', () => fromJS(payload)),
    [a.getGeneSetFilterOptionsAction]: (state, { payload }) =>
      state.updateIn(['availableFilters', payload.filterName, 'isLoading'], () => true),
    [a.setGeneSetFilterOptionsAction]: (state, { payload }) =>
      state.updateIn(['availableFilters', payload.filterName], () => fromJS(payload)),
    [a.getSourceDetailsFilterOptionsAction]: state => (
      state.updateIn(['availableFilters', publicationsFiltersNames.SOURCE_DETAILS_FILTER, 'isLoading'], () => true)
    ),
    [a.setSourceDetailsFilterOptionsAction]: (state, { payload }) =>
      state.updateIn(['availableFilters', publicationsFiltersNames.SOURCE_DETAILS_FILTER], () => fromJS(payload)),
    [a.setGeneSetFilterDataAction]: (state, { payload }) =>
      state.update('filtersData', () => fromJS(payload)),
    [a.updateCreateSetUIFiltersAction]: (state, { payload }) =>
      state.updateIn(['uiFilters', payload.filterType], () => fromJS(payload.value)),
    [a.setSavedSuccessfullyAction]: (state, { payload }) =>
      state
        .update('setSavedSuccessfully', () => true)
        .update('savedSetInfo', () => fromJS(payload)),
    [a.clearSavedSuccessfullyAction]: state =>
      state.update('setSavedSuccessfully', () => null),
    [a.sortCreateSetConceptsAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [a.setCreateSetPublicationSourceAction]: (state, { payload }) =>
      state.update('publicationSource', () => fromJS(payload)),
    [a.selectCreateSetProjectAction]: (state, { payload }) =>
      state.update('selectedProject', () => fromJS(payload)),
    [a.resetCreateSetAction]: () => initialState,
  },
  initialState
);

export default reducer;
