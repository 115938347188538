import { CooccurrenceEnum } from '../../../constantsCommon';

export const geneSetFilterUrlPrefixes = {
  GENE_CLASSIFICATION_FILTER: 'classifications',
  GENE_SUBCELLULAR_LOCATION_FILTER: 'locations',
  GENE_CELL_TYPE_EXPRESSION_FILTER: 'cells',
};

export const geneSetFiltersNames = {
  GENE_TAXONOMY_FILTER: 'GENE_TAXONOMY_FILTER',
  GENE_CLASSIFICATION_FILTER: 'GENE_CLASSIFICATION_FILTER',
  GENE_TISSUE_EXPRESSION_FILTER: 'GENE_TISSUE_EXPRESSION_FILTER',
  GENE_SUBCELLULAR_LOCATION_FILTER: 'GENE_SUBCELLULAR_LOCATION_FILTER',
  GENE_CELL_TYPE_EXPRESSION_FILTER: 'GENE_CELL_TYPE_EXPRESSION_FILTER',
  GENE_CHEMICAL_ANTAGONISTS_FILTER: 'GENE_CHEMICAL_ANTAGONISTS_FILTER',
  GENE_CHEMICAL_AGONIST_FILTER: 'GENE_CHEMICAL_AGONIST_FILTER',
  GENE_TOOL_COMPOUNDS_FILTER: 'GENE_TOOL_COMPOUNDS_FILTER',
  GENE_KNOWN_DRUG_TARGETS_FILTER: 'GENE_KNOWN_DRUG_TARGETS_FILTER',
  GENES_DIRECT_PROTEIN_INTERACTION_FILTER: 'GENES_DIRECT_PROTEIN_INTERACTION_FILTER',
  GENES_TRANSCRIPTION_FACTORSM_FILTER: 'GENES_TRANSCRIPTION_FACTORSM_FILTER',
  GENES_FUNCTIONAL_INHIBITION_FILTER: 'GENES_FUNCTIONAL_INHIBITION_FILTER',
  GENES_FUNCTIONAL_STIMULATION_FILTER: 'GENES_FUNCTIONAL_STIMULATION_FILTER',
  GENES_FUNCTIONALLY_INHIBITED_BY_FILTER: 'GENES_FUNCTIONALLY_INHIBITED_BY_FILTER',
  GENES_FUNCTIONAL_STIMULATION_BY_FILTER: 'GENES_FUNCTIONAL_STIMULATION_BY_FILTER',

  DISEASES_DYSREGULATED_PROTEINS_FILTER: 'DISEASES_DYSREGULATED_PROTEINS_FILTER',
  DISEASES_UPREGULATED_PROTEINS_FILTER: 'DISEASES_UPREGULATED_PROTEINS_FILTER',
  DISEASES_DOWNREGULATED_PROTEINS_FILTER: 'DISEASES_DOWNREGULATED_PROTEINS_FILTER',
  DISEASES_CAUSAL_FILTER: 'DISEASES_CAUSAL_FILTER',
  DISEASES_GENETIC_VARIATION_FILTER: 'DISEASES_GENETIC_VARIATION_FILTER',
  DISEASES_ANIMAL_MODEL_FILTER: 'DISEASES_ANIMAL_MODEL_FILTER',

  GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER: 'GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER',
  GENE2DISEASES_UPREGULATED_PROTEINS_FILTER: 'GENE2DISEASES_UPREGULATED_PROTEINS_FILTER',
  GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER: 'GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER',
  GENE2DISEASES_CAUSAL_FILTER: 'GENE2DISEASES_CAUSAL_FILTER',
  GENE2DISEASES_GENETIC_VARIATION_FILTER: 'GENE2DISEASES_GENETIC_VARIATION_FILTER',
  GENE2DISEASES_ANIMAL_MODEL_FILTER: 'GENE2DISEASES_ANIMAL_MODEL_FILTER',

  PHENOTYPE_CAUSAL_ANNOTATION_FILTER: 'PHENOTYPE_CAUSAL_ANNOTATION_FILTER',
  PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER: 'PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER',
  CANCERS_TUMOR_EXPRESSION_FILTER: 'CANCERS_TUMOR_EXPRESSION_FILTER',
  CANCERS_TUMOR_DYSREGULATION_FILTER: 'CANCERS_TUMOR_DYSREGULATION_FILTER',
  CANCERS_TUMOR_UPREGULATION_FILTER: 'CANCERS_TUMOR_UPREGULATION_FILTER',
  CANCERS_TUMOR_DOWNREGULATION_FILTER: 'CANCERS_TUMOR_DOWNREGULATION_FILTER',
  CANCERS_GENETIC_VARIATION_FILTER: 'CANCERS_GENETIC_VARIATION_FILTER',
  CANCERS_CAUSAL_ANNOTATION_FILTER: 'CANCERS_CAUSAL_ANNOTATION_FILTER',
  CANCERS_ANIMAL_MODEL_FILTER: 'CANCERS_ANIMAL_MODEL_FILTER',
  DRUGS_TARGET_FILTER: 'DRUGS_TARGET_FILTER',
  SMALL_MOLECULES_CHEMICAL_PROBE_FILTER: 'SMALL_MOLECULES_CHEMICAL_PROBE_FILTER',
  SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER: 'SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER',
  SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER: 'SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER',
};

export const publicationsFiltersNames = {
  REFERENCES_FILTER: 'REFERENCES_FILTER',
  PUBLICATION_TYPE_FILTER: 'PUBLICATION_TYPE_FILTER',
  RESEARCH_SOURCE_FILTER: 'RESEARCH_SOURCE_FILTER',
  YEAR_FILTER: 'YEAR_FILTER',
  PUBLICATION_COUNT_FILTER: 'PUBLICATION_COUNT_FILTER',
  SOURCE_DETAILS_FILTER: 'SOURCE_DETAILS_FILTER',
};

export const taxonomyOptions = [
  {
    type: 'homo sapiens',
    value: 'homo sapiens',
    label: 'Human',
  },
  {
    type: 'mus musculus',
    value: 'mus musculus',
    label: 'Mouse',
  },
  {
    type: 'rattus norvegicus',
    value: 'rattus norvegicus',
    label: 'Rat',
  },
  {
    type: 'macaca fascicularis',
    value: 'macaca fascicularis',
    label: 'Cynomolgus monkey',
  },
  {
    type: 'danio rerio',
    value: 'danio rerio',
    label: 'Zebrafish',
  },
  {
    type: 'Nematode caenorhabditis elegans',
    value: 'Nematode caenorhabditis elegans',
    label: 'Nematode',
  },
  {
    type: 'drosophila melanogaster',
    value: 'drosophila melanogaster',
    label: 'Fruit fly',
  },
  {
    type: 'saccharomyces cerevisiae',
    value: 'saccharomyces cerevisiae',
    label: 'Saccharomyces cerevisiae',
  },
  {
    type: 'all concepts',
    value: 'all concepts',
    label: 'All',
  },
];

export const createSetFilterInitialValues = {
  PUBLICATION_FILTERS: {
    publicationSource: 'ALL',
    publicationTypes: null,
    researchTypes: null,
    sources: null,
  },
  GENE_CELL_TYPE_EXPRESSION_FILTER: {
    ids: null,
    lowerExpressionThreshold: 1,
    higherExpressionThreshold: 1000000,
  },
  GENE_TISSUE_EXPRESSION_FILTER: {
    ids: null,
    lowerExpressionThreshold: 1,
    higherExpressionThreshold: 1000000,
  },
  GENE_CLASSIFICATION_FILTER: {
    classificationId: null,
  },
  GENE_SUBCELLULAR_LOCATION_FILTER: {
    ids: null,
  },
  GENE_TAXONOMY_FILTER: {
    taxonomy: null,
  },
  GENE_TOOL_COMPOUNDS_FILTER: false,
  GENE_KNOWN_DRUG_TARGETS_FILTER: false,
  GENES_DIRECT_PROTEIN_INTERACTION_FILTER: false,
  GENES_TRANSCRIPTION_FACTORSM_FILTER: false,
  GENES_FUNCTIONAL_INHIBITION_FILTER: false,
  GENES_FUNCTIONAL_STIMULATION_FILTER: false,
  GENES_FUNCTIONALLY_INHIBITED_BY_FILTER: false,
  GENES_FUNCTIONAL_STIMULATION_BY_FILTER: false,
  DISEASES_DYSREGULATED_PROTEINS_FILTER: false,
  DISEASES_UPREGULATED_PROTEINS_FILTER: false,
  DISEASES_DOWNREGULATED_PROTEINS_FILTER: false,
  DISEASES_GENETIC_VARIATION_FILTER: false,
  DISEASES_CAUSAL_FILTER: false,
  DISEASES_ANIMAL_MODEL_FILTER: false,
  GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER: false,
  GENE2DISEASES_UPREGULATED_PROTEINS_FILTER: false,
  GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER: false,
  GENE2DISEASES_GENETIC_VARIATION_FILTER: false,
  GENE2DISEASES_CAUSAL_FILTER: false,
  GENE2DISEASES_ANIMAL_MODEL_FILTER: false,
  PHENOTYPE_CAUSAL_ANNOTATION_FILTER: false,
  PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER: false,
  CANCERS_TUMOR_EXPRESSION_FILTER: false,
  CANCERS_TUMOR_DYSREGULATION_FILTER: false,
  CANCERS_TUMOR_UPREGULATION_FILTER: false,
  CANCERS_TUMOR_DOWNREGULATION_FILTER: false,
  CANCERS_GENETIC_VARIATION_FILTER: false,
  CANCERS_CAUSAL_ANNOTATION_FILTER: false,
  CANCERS_ANIMAL_MODEL_FILTER: false,
  DRUGS_TARGET_FILTER: false,
  SMALL_MOLECULES_CHEMICAL_PROBE_FILTER: false,
  SKIP_ZERO_PUBLICATIONS: true,
};

export const apiTypes = {
  classificationFullListApi: 'classificationFullListApi',
  filteredFullListApi: 'filteredFullListApi',
  categoryAnalysisApi: 'categoryAnalysisApi',
  filterWithoutStartConcept: 'filterWithoutStartConcept',
  filterWithConcepts: 'filterWithConcepts',
  targetsForCancer: 'targetsForCancer',
  rankTargets: 'ranktargets',
};

export const geneSetFiltersLabels = {
  GENE_TAXONOMY_FILTER: 'Taxonomy',
  GENE_CLASSIFICATION_FILTER: 'Gene classifications',
  GENE_TISSUE_EXPRESSION_FILTER: 'Tissue expression',
  GENE_SUBCELLULAR_LOCATION_FILTER: 'Gene locations',
  GENE_CELL_TYPE_EXPRESSION_FILTER: 'Gene Cell Type Expression',
  GENE_CHEMICAL_ANTAGONISTS_FILTER: 'Chemical antagonists',
  GENE_CHEMICAL_AGONIST_FILTER: 'Chemical agonist',
  GENE_TOOL_COMPOUNDS_FILTER: 'Tool compounds',
  GENE_KNOWN_DRUG_TARGETS_FILTER: 'Known drug targets',
  GENES_DIRECT_PROTEIN_INTERACTION_FILTER: 'Direct Protein interaction',
  GENES_TRANSCRIPTION_FACTORSM_FILTER: 'Transcription factors',
  GENES_FUNCTIONAL_INHIBITION_FILTER: 'Functional inhibition',
  GENES_FUNCTIONAL_STIMULATION_FILTER: 'Functional stimulation',
  GENES_FUNCTIONALLY_INHIBITED_BY_FILTER: 'Functionally inhibited',
  GENES_FUNCTIONAL_STIMULATION_BY_FILTER: 'Functionally stimulated',
  DISEASES_DYSREGULATED_PROTEINS_FILTER: 'Dysregulated proteins',
  DISEASES_UPREGULATED_PROTEINS_FILTER: 'Upregulated proteins',
  DISEASES_DOWNREGULATED_PROTEINS_FILTER: 'Downregulated proteins',
  DISEASES_CAUSAL_FILTER: 'Diseases causal annotation',
  DISEASES_GENETIC_VARIATION_FILTER: 'Diseases Associated Genetic Variation',
  DISEASES_ANIMAL_MODEL_FILTER: 'Animal model for disease',
  GENE2DISEASES_DYSREGULATED_PROTEINS_FILTER: 'Dysregulated proteins',
  GENE2DISEASES_UPREGULATED_PROTEINS_FILTER: 'Upregulated proteins',
  GENE2DISEASES_DOWNREGULATED_PROTEINS_FILTER: 'Downregulated proteins',
  GENE2DISEASES_CAUSAL_FILTER: 'Diseases causal annotation',
  GENE2DISEASES_GENETIC_VARIATION_FILTER: 'Diseases Associated Genetic Variation',
  GENE2DISEASES_ANIMAL_MODEL_FILTER: 'Animal model for disease',
  PHENOTYPE_CAUSAL_ANNOTATION_FILTER: 'Phenotype causal annotation',
  PHENOTYPE_ASSOCIATION_ANNOTATION_FILTER: 'Phenotype Association annotation',
  CANCERS_TUMOR_EXPRESSION_FILTER: 'Tumor Expression',
  CANCERS_TUMOR_DYSREGULATION_FILTER: 'Tumor Dysregulation',
  CANCERS_TUMOR_UPREGULATION_FILTER: 'Upregulation in tumor',
  CANCERS_TUMOR_DOWNREGULATION_FILTER: 'Downregulation in tumor',
  CANCERS_GENETIC_VARIATION_FILTER: 'Cancers Genetic Variation',
  CANCERS_CAUSAL_ANNOTATION_FILTER: 'Cancers causal annotation',
  CANCERS_ANIMAL_MODEL_FILTER: 'Cancers Animal model',
  DRUGS_TARGET_FILTER: 'Drugs target',
  SMALL_MOLECULES_CHEMICAL_PROBE_FILTER: 'Chemical probe for proteins',
  SMALL_MOLECULES_INHIBITS_PROTEINS_FILTER: 'Inhibits proteins',
  SMALL_MOLECULES_STIMULATES_PROTEINS_FILTER: 'Stimulates proteins',
};

export const publicationsFiltersLabels = {
  publicationSource: 'References',
  publicationTypes: 'Publication type',
  researchTypes: 'Experimental systems',
  dateFrom: 'References date from',
  dateTo: 'References date to',
  publicationsCountFrom: 'Number of references from',
  publicationsCountTo: 'Number of references to',
  sources: 'Sources',
};

export const cooccurrenceTypes = {
  ABSTRACT: 'Abstract cooccurrence',
  SENTENCE: 'Sentence cooccurrence',
  TITLE: 'Title cooccurrence',
};

export const cooccurrenceTypesOptions = [
  {
    type: cooccurrenceTypes.ABSTRACT,
    value: CooccurrenceEnum.ABSTRACT,
    label: cooccurrenceTypes.ABSTRACT,
  },
  {
    type: cooccurrenceTypes.SENTENCE,
    value: CooccurrenceEnum.SENTENCE,
    label: cooccurrenceTypes.SENTENCE,
  },
  {
    type: cooccurrenceTypes.TITLE,
    value: CooccurrenceEnum.TITLE,
    label: cooccurrenceTypes.TITLE,
  },
];

export const createSetSortingTypes = {
  NAME: 'name',
  COUNT: 'count',
};
