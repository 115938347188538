import { takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';

import { getBuildHeatmapDataAction, setBuildHeatmapDataAction, throwBuildHeatmapErrorAction } from './reducer';
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';

function* getBuildHeatmapDataWorker(action) {
  try {
    const { id, projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const requestData = isProjectTab ? { setId: id, projectId } : id;
    const { data } = yield call(apiToCall, requestData);
    const ids = data.items.map(concept => concept.id);
    yield put(setBuildHeatmapDataAction({ data, ids }));
  } catch (e) {
    yield put(throwBuildHeatmapErrorAction(e.message));
  }
}

function* newOptionSetSaga() {
  yield takeLatest(getBuildHeatmapDataAction, getBuildHeatmapDataWorker);
}

export default newOptionSetSaga;
