import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import {
  setCreateSetConceptsAction,
  toggleCreateSetLoaderAction,
  throwCreateSetErrorAction,
  setSourceDetailsFilterOptionsAction,
  setCreateSetPublicationSourceAction,
} from '../../actions';
import { getAvailableFiltersSelector } from '../../selectors';
// Enums
import { publicationsFiltersNames } from '../../../enums';

export function* getCreateSetConceptsWorker({ payload: requestData }) {
  try {
    yield put(toggleCreateSetLoaderAction(true));
    const availableFilters = yield select(getAvailableFiltersSelector);
    const { data } = yield call(Api.getConcepts, requestData);
    yield put(setCreateSetConceptsAction(data));
    yield put(setCreateSetPublicationSourceAction(requestData.publicationSource));
    if (availableFilters) {
      const filter = availableFilters[publicationsFiltersNames.SOURCE_DETAILS_FILTER];
      yield put(setSourceDetailsFilterOptionsAction({ ...filter, options: [] }));
    }
  } catch (e) {
    yield put(throwCreateSetErrorAction(e.message));
    yield put(toggleCreateSetLoaderAction(false));
  }
}
