import { createSelector } from 'reselect';

const conceptsData = state => state.getIn(['searchConceptPopupReducer', 'concepts', 'data']);
const conceptsError = state => state.getIn(['searchConceptPopupReducer', 'concepts', 'error']);
const conceptsLoading = state => state.getIn(['searchConceptPopupReducer', 'concepts', 'loading']);
const filters = state => state.getIn(['searchConceptPopupReducer', 'filters']);
const inputValue = state => state.getIn(['searchConceptPopupReducer', 'inputValue']);
const categoriesData = state => state.getIn(['searchConceptPopupReducer', 'categories', 'data']);
const categoriesError = state => state.getIn(['searchConceptPopupReducer', 'categories', 'error']);
const categoriesLoading = state => state.getIn(['searchConceptPopupReducer', 'categories', 'loading']);
const activeCategories = state => state.getIn(['searchConceptPopupReducer', 'activeCategories']);
const semTypesCounts = state => state.getIn(['searchConceptPopupReducer', 'semanticTypesCounts']);
const semCategoriesCounts = state => state.getIn(['searchConceptPopupReducer', 'semanticCategoriesCounts']);
const suggestions = state => state.getIn(['searchConceptPopupReducer', 'suggestions']);
const geneTaxonomies = state => state.getIn(['searchConceptPopupReducer', 'geneTaxonomies']);

export const getSearchConceptsDataSelector = createSelector(
  conceptsData,
  data => data && data.toJS()
);

export const getSearchConceptsErrorSelector = createSelector(
  conceptsError,
  data => data
);

export const getSearchConceptsLoadingSelector = createSelector(
  conceptsLoading,
  data => data
);

export const getSearchConceptFiltersSelector = createSelector(
  filters,
  data => data && data.toJS()
);

export const getSearchConceptsInputValueSelector = createSelector(
  inputValue,
  data => data
);

export const getConceptsSearchCategoriesSelector = createSelector(
  categoriesData,
  data => data && data.toJS()
);

export const getConceptsSearchCategoriesErrorSelector = createSelector(
  categoriesError,
  data => data
);

export const getConceptsSearchCategoriesLoadingSelector = createSelector(
  categoriesLoading,
  data => data
);

export const getActiveCategoriesSelector = createSelector(
  activeCategories,
  data => data
);

export const getConceptsSearchSemCategoriesCountsSelector = createSelector(
  semCategoriesCounts,
  data => data && data.toJS()
);

export const getConceptsSearchSemTypesCountsSelector = createSelector(
  semTypesCounts,
  data => data && data.toJS()
);

export const getSearchConceptsSuggestionsSelector = createSelector(
  suggestions,
  data => data && data.toJS()
);

export const getGeneTaxonomiesSelector = createSelector(
  geneTaxonomies,
  data => data && data.toJS()
);
