export const setTypes = {
  SIMPLE: 'Simple',
  EFFECT: 'Effect',
  COMPLEX: 'Complex',
};

export const setsActionsTypes = {
  COMPARE: 'COMPARE',
  VENN: 'VENN',
  DELETE: 'DELETE',
  INTERSECT: 'INTERSECT',
};

export const iconsTypes = {
  BUILD_HEATMAP: 'BUILD_HEATMAP',
  ANALYZE: 'ANALYZE',
  DOWNLOAD: 'DOWNLOAD',
  DELETE: 'DELETE',
  FILTER: 'FILTER',
  EDIT: 'EDIT',
  SEARCH: 'SEARCH',
};
