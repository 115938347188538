import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

// Components
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import CreateSet from '../../../Sets/CreateSet/CreateSet';
import ExportTable from '../../../common/ExportTable/ExportTable';
// Utils
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { apiTypes } from '../../../Sets/CreateSet/enums';
import { SearchSourceFilterEnum } from '../../../Search/enums';
import { withRouter } from '../../../common/WithRouter/WithRouter';
// Store
import { getPath } from '../../NetworkAnalysis/PathDetails/selectors';
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import {
  getSelectedCategory,
  getSelectedType,
  getShouldUpdateDataFlag,
} from '../../common/SetAnalysisMethodSelection/selectors';
import {
  openGenesDetail as openGenesDetailAction,
} from '../../../common/SetAnalysisGenesDetail/actions';
import { switchShouldUpdateDataFlagAction } from '../../common/SetAnalysisMethodSelection/actions';
import * as SELECTORS from './selectors';
import * as ACTIONS from './reducer';
import {analysisMethodEnum, analysisMethodNameEnum} from './enums';

const propTypes = {
  removeConcept: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Array),
  content: PropTypes.instanceOf(Array),
  findRelatedAnalysis: PropTypes.func,
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  pageNumber: PropTypes.number,
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  createHeaderRendererWithSaveAs: PropTypes.func,
  isPathDetailsChild: PropTypes.bool,
  analysisMethod: PropTypes.string,
  genesDetailAPIKey: PropTypes.string,
  title: PropTypes.string,
  selectedData: PropTypes.instanceOf(Array),
  resetFindRelatedTable: PropTypes.func,
  categoryType: PropTypes.instanceOf(Object),
  selectedCategory: PropTypes.string,
  path: PropTypes.instanceOf(Object),
  openGenesDetail: PropTypes.func,
  tableWidth: PropTypes.number,
  shouldUpdateDataFlag: PropTypes.bool,
  switchShouldUpdateDataFlag: PropTypes.func,
  CSVTitle: PropTypes.string,
  navigate: PropTypes.func,
  passedConcepts: PropTypes.instanceOf(Array),
  passedCategory: PropTypes.string,
  passedCategoryName: PropTypes.string,
};

class FindRelatedResult extends React.Component {
  actions = {
    checkAll: ACTIONS.findRelatedTableCheckAllAction,
    checkItem: ACTIONS.findRelatedTableCheckItemAction,
    invertSelection: ACTIONS.findRelatedTableInvertSelectionAction,
    removeSelected: ACTIONS.findRelatedTableRemoveSelectedAction,
  };
  state = {
    filterSetPopup: {
      show: false,
      config: null,
    },
  };

  componentDidMount() {
    const { analysisMethod } = this.props;
    this.fetchFindRelatedData(analysisMethod);
  }

  componentDidUpdate(prevProps) {
    const {
      analysisMethod,
      categoryType,
      shouldUpdateDataFlag,
      selectedData,
    } = this.props;
    const {
      analysisMethod: prevAnalysisMethod,
      categoryType: prevCategoryType,
      selectedData: prevSelectedData,
    } = prevProps;

    if (analysisMethod !== prevAnalysisMethod
      || categoryType !== prevCategoryType
      || prevSelectedData !== selectedData
      || shouldUpdateDataFlag
    ) {
      this.fetchFindRelatedData(analysisMethod);
    }
  }

  componentWillUnmount() {
    const { resetFindRelatedTable } = this.props;
    resetFindRelatedTable();
  }

  fetchFindRelatedData(analysisMethod, parameters) {
    const {
      findRelatedAnalysis,
      selectedData,
      categoryType,
      genesDetailAPIKey,
      isPathDetailsChild,
      path,
      switchShouldUpdateDataFlag,
      passedConcepts,
      passedCategory,
    } = this.props;
    const type = categoryType ? categoryType.id : null;
    const params = parameters || { size: 20, page: 0 };

    if (passedConcepts) {
      findRelatedAnalysis({
        concepts: passedConcepts.map(c => c.id),
        analysisMethod,
        params,
        type: passedCategory,
      });
    } else {
      let concepts;

      if (isPathDetailsChild && genesDetailAPIKey === 'pathwayTargetCandidates') {
        concepts = path.path.flatMap(c => c.left);
      } else {
        concepts = selectedData.map(concept => concept.id);
      }
  
      findRelatedAnalysis({
        concepts,
        analysisMethod,
        params,
        type,
      });
    }
    switchShouldUpdateDataFlag(false);
  }

  removeConcept = (id) => {
    const { removeConcept } = this.props;
    removeConcept(id);
  };

  showHideAllConcepts = () => {
    const {
      totalPages,
      totalItems,
      analysisMethod,
    } = this.props;
    const params = {
      size: totalPages === 1 ? 20 : totalItems,
      page: 0,
    };

    this.fetchFindRelatedData(analysisMethod, params);
  };

  handlePageClick = (pageNumber) => {
    const { analysisMethod } = this.props;
    const params = {
      size: 20,
      page: pageNumber.selected,
    };

    this.fetchFindRelatedData(analysisMethod, params);
  };

  handlePageSort = () => {
    const {
      analysisMethod,
      pageNumber,
    } = this.props;
    const params = {
      size: 20,
      page: pageNumber,
    };

    this.fetchFindRelatedData(analysisMethod, params);
  };

  openConceptDetails = (candidateGi) => {
    const {
      selectedCategory,
      navigate,
    } = this.props;

    if (selectedCategory === 'Genes & Molecular Sequences') {
      navigate(`${RELATIVE_PATH}/gene-details/${candidateGi}`);
    } else {
      navigate(`${RELATIVE_PATH}/concept-details/${candidateGi}`);
    }
  };

  genesCell = ({ id, name }, rowIndex, selectedCategory) => {
    const link = selectedCategory === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;

    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  openGenesDetail = (concept) => {
    const {
      openGenesDetail,
      genesDetailAPIKey,
    } = this.props;

    openGenesDetail({
      concept,
      genesDetailAPIKey,
    });
  };

  getRowClassName = ({ index }) => {
    const { content } = this.props;
    const rowData = content[index];
    return rowData && rowData.nonSignificant && 'analysis-result-non-significant-concept';
  };

  getColumnPercentWidth = (percent) => {
    const { tableWidth } = this.props;
    const width = tableWidth || 1300;
    return (percent * (width - 100)) / 100;
  };

  openFilteringPopup = (data) => {
    const filterSetPopupConfig = {
      apiType: apiTypes.filteredFullListApi,
      conceptIds: [data.candidateGi],
      conceptNames: [data.candidateName],
      categoryName: 'Genes',
    };
    this.setState({
      filterSetPopup: {
        show: true,
        config: filterSetPopupConfig,
        initialFilters: {
          PUBLICATION_FILTERS: {
            publicationSource: SearchSourceFilterEnum.DATABASE,
          },
        },
      },
    });
  };

  closeFilterSetPopup = () => {
    this.setState({
      filterSetPopup: {
        show: false,
        config: null,
      },
    });
  };

  render() {
    const {
      title,
      content,
      loading,
      error,
      totalPages,
      pageNumber,
      CSVTitle,
      selectedItems,
      sorting: { sortBy, sortDirection },
      createHeaderRendererWithSaveAs,
      tableWidth,
      isPathDetailsChild,
      analysisMethod,
      passedCategoryName,
    } = this.props;
    const {
      filterSetPopup,
    } = this.state;

    const tableSettings = {
      width: tableWidth || 1300,
      headerHeight: 50,
      rowHeight: 50,
      sortBy,
      sortDirection,
    };

    const categoryLabel = analysisMethod === analysisMethodEnum.CATEGORY ? passedCategoryName : analysisMethodNameEnum[analysisMethod];

    const columns = [
      {
        label: `${categoryLabel} Term`,
        dataKey: 'candidateName',
        width: this.getColumnPercentWidth(45),
        exportCSV: true,
        ...(!!createHeaderRendererWithSaveAs && { headerRenderer: createHeaderRendererWithSaveAs(content) }),
        cellRenderer: ({ rowData, rowIndex }) => (
          rowData ? this.genesCell({ id: rowData.candidateGi, name: rowData.candidateName }, rowIndex) : null
        ),
      },
      {
        label: 'Background-Related',
        dataKey: 'nbrsNumbers',
        dataKeySub: 'geneName',
        width: this.getColumnPercentWidth(19),
        exportCSV: true,
        className: 'table-wrap__cell',
        cellRenderer: ({ rowData }) => {
          const classes = classNames({
            link: rowData.nbrsNumbers > 0,
          });
          return (
            <span
              role="presentation"
              className={classes}
              onClick={() => {
                if (rowData.nbrsNumbers > 0) {
                  this.openFilteringPopup(rowData);
                }
              }}
            >
              {rowData.nbrsNumbers}
            </span>
          );
        },
      },
      {
        label: 'Input-Related',
        dataKey: 'geneNumbers',
        dataKeySub: 'geneName',
        width: this.getColumnPercentWidth(18),
        exportCSV: true,
        cellRenderer: ({ rowData }) => (
          <span
            role="presentation"
            title="The number of genes from your input set that are related to this biological term."
            className="link text-center cell-row"
            onClick={() => {
              this.openGenesDetail({
                id: rowData.candidateGi,
                connectedConcepts: rowData.connectedConcepts,
                name: rowData.candidateName,
              });
            }}
          >
            {rowData.geneNumbers}
          </span>
        ),
      },
      {
        label: 'P-Value',
        dataKey: 'pValue',
        width: this.getColumnPercentWidth(22),
        exportCSV: true,
        className: 'table-wrap__cell',
      },
      {
        label: 'Status',
        dataKey: 'candidateType',
        width: 180,
        exportCSV: true,
        headerRenderer: ({ label }) => (
          <div >
            <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle text-center"
              title="Indicates whether the term is enriched (overrepresented) or depleted (underrepresented) in your input set, compared to what would be expected.">
              {label}
            </span>
          </div>
        ),
      },
      {
        dataKey: 'id',
        disableSort: true,
        width: 50,
        cellRenderer: ({ rowData }) => (
          <div className="text-center">
            <button
              type="button"
              onClick={() => { this.removeConcept(rowData.id); }}
              className="btn-icon btn-icon-danger"
              title="Remove"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="setAnalysisPathwayResult">
        <div className="analysis-title mt-20 title2">{title}</div>
        <div className="path-details-separator" />
        {
          !loading &&
          <div className="table-wrap">
            <div
              className="controls-block table-wrap__controls"
              style={{ width: tableWidth || 1300 }}
            >
              {
                totalPages > 1 &&
                <div className="table-wrap__pagination">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakClassName="break-me"
                    pageCount={totalPages}
                    forcePage={pageNumber}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              }
              <div className="table-wrap__to-csv">
                <ExportTable
                  content={content}
                  columns={columns}
                  fileName={CSVTitle}
                  showExportAll={false}
                  showExportPage={true}
                  showExportSelected={true}
                  selectedItems={selectedItems}
                />
                <button
                  disabled={!totalPages}
                  className="button button-primary ml-15"
                  onClick={() => this.showHideAllConcepts()}
                >
                  { totalPages === 1 ? <span>Show pages</span> : <span>Show all</span> }
                </button>
              </div>
            </div>
            <SimpleTable
              data={content}
              columns={columns}
              settings={tableSettings}
              dynamicHeight={true}
              showOnHeatMap={!isPathDetailsChild}
              manageable={true}
              actions={this.actions}
              rowClassName={this.getRowClassName}
              sortAction={ACTIONS.findRelatedTableSortAction}
              serverSortAction={this.handlePageSort}
            />
          </div>
        }
        {
          filterSetPopup.show &&
          <ModalComponent
            isOpen={filterSetPopup.show}
            closeCb={this.closeFilterSetPopup}
            modalClassName="modal_no-paddings"
          >
            <CreateSet
              config={filterSetPopup.config}
              initialFilters={filterSetPopup.initialFilters}
              closePopup={this.closeFilterSetPopup}
            />
          </ModalComponent>
        }
        <Loader isLoading={loading && !error} />
        <Error
          show={!!error}
          error={error}
          customClassName="text-center error-text"
        />
      </div>
    );
  }
}

FindRelatedResult.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    content: SELECTORS.getContentSelector(state),
    pageNumber: SELECTORS.getPageNumber(state),
    totalPages: SELECTORS.getTotalPages(state),
    totalItems: SELECTORS.getTotalItems(state),
    selectedItems: SELECTORS.getSelectedItems(state),
    sorting: SELECTORS.getTableSorting(state),
    loading: SELECTORS.getLoadingKey(state),
    error: SELECTORS.getError(state),
    selectedData: selectedDataForAnalysis(state),
    selectedCategory: getSelectedCategory(state),
    categoryType: getSelectedType(state),
    path: getPath(state),
    shouldUpdateDataFlag: getShouldUpdateDataFlag(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeConcept(id) {
      dispatch(ACTIONS.findRelatedTableRemoveConceptAction(id));
    },
    findRelatedAnalysis(data) {
      dispatch(ACTIONS.findRelatedAnalysisRequestedAction(data));
    },
    resetFindRelatedTable() {
      dispatch(ACTIONS.findRelatedTableResetAction());
    },
    openGenesDetail(data) {
      dispatch(openGenesDetailAction(data));
    },
    switchShouldUpdateDataFlag(data) {
      dispatch(switchShouldUpdateDataFlagAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(FindRelatedResult));
