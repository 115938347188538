import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
// Icons
import { BiCheck } from 'react-icons/bi';
// Components
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import ExportTable from '../../common/ExportTable/ExportTable';
// Store
import * as ACTIONS from './store/reducer';
// Utils
import { getParentBlockWidth } from '../../Utils/Utils';
import { helpText } from './helpText';
// Constants
import { PAGE_SIZE } from './constants';

const propTypes = {
  geneName: PropTypes.string,
  dataPerPage: PropTypes.instanceOf(Array),
  filteredData: PropTypes.instanceOf(Array),
  sorting: PropTypes.instanceOf(Object),
  sort: PropTypes.instanceOf(Object),
  tableWidth: PropTypes.number,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
};

const OrthologsTable = (props) => {
  const {
    sort,
    dataPerPage,
    filteredData,
    geneName,
    sorting: {
      sortBy,
      sortDirection,
    },
    activePage,
    totalPages,
    changePage,
    tableWidth,
  } = props;

  const simpleTableRef = useRef(null);

  useEffect(() => {
    if (simpleTableRef && simpleTableRef.current) {
      simpleTableRef.current.recomputeRowHeights();
    }
  }, [dataPerPage]);

  const parentClass = 'gene-details-section-body';

  const getColumnPercentWidth = (percent) => {
    const screenWidth = tableWidth || getParentBlockWidth(parentClass);
    return (percent * screenWidth) / 100;
  };

  const handlePageClick = (page) => {
    changePage(page.selected);
  };

  function renderTableHeader(label, dataKey, headerSortBy = sortBy, headerSortDirection = sortDirection) {
    const ascClasses = classNames({
      'fa fa-sort-asc': true,
      active: headerSortBy === dataKey && headerSortDirection === 'ASC',
    });
    const descClasses = classNames({
      'fa fa-sort-desc': true,
      active: headerSortBy === dataKey && headerSortDirection === 'DESC',
    });

    return (
      <div className="header-section">
        <span
          className="ReactVirtualized__Table__headerTruncatedText ReactVirtualized__Table__sortableHeaderColumn vertical-align-middle"
          title={label}
        >
          {label}
        </span>
        <ReactTooltip
          id={dataKey}
          place="top"
          type="light"
          event="mouseover"
          eventOff="mouseout"
          className="orthologs__tooltip"
        >
          { helpText[dataKey] }
        </ReactTooltip>
        <button
          className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
          data-tip={true}
          data-for={dataKey}
        />
        <span className="sorting-section">
          <i
            className={ascClasses}
            onClick={() => sort({ sortBy: dataKey, sortDirection: 'ASC' })}
          />
          <i
            className={descClasses}
            onClick={() => sort({ sortBy: dataKey, sortDirection: 'DESC' })}
          />
        </span>
      </div>
    );
  }

  const getRowHeight = ({ index }) => {
    const { humanEnsemblTranscriptId } = dataPerPage[index];
    const padding = 20;
    const lineHeight = 30;
    const stringRows = humanEnsemblTranscriptId.split('|').length;
    const height = stringRows * lineHeight;

    return height + padding;
  };

  const getColumns = () => [
    {
      label: 'Uniprot ID',
      dataKey: 'uniprotId',
      className: 'table-wrap__cell table-wrap__cell_left',
      width: getColumnPercentWidth(8),
      exportCSV: true,
      disableSort: true,
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Uniprot Accession',
      labelForCSV: 'Human Uniprot Accession',
      dataKey: 'humanUniprotAccession',
      className: 'table-wrap__cell',
      width: getColumnPercentWidth(6),
      exportCSV: true,
      disableSort: true,
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Ensembl Transcript ID',
      dataKey: 'humanEnsemblTranscriptId',
      exportCSV: true,
      disableSort: true,
      width: getColumnPercentWidth(10),
      className: 'table-wrap__cell',
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => (
        <div>
          {
            rowData.humanEnsemblTranscriptId.split('|').map((item, index, array) =>
              (
                <span key={index}>
                  <span>{item}</span>
                  {
                    index !== (array.length - 1) && <span> | </span>
                  }
                </span>
              ))
          }
        </div>
      ),
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Species',
      dataKey: 'species',
      exportCSV: true,
      disableSort: true,
      width: getColumnPercentWidth(15),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Ensembl Transcript ID',
      dataKey: 'orthologEnsemblTranscriptId',
      exportCSV: true,
      disableSort: true,
      width: getColumnPercentWidth(10),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      cellRenderer: ({ rowData }) => (rowData.orthologEnsemblTranscriptId !== null ? rowData.orthologEnsemblTranscriptId : '-'),
      csvRenderer: orthologEnsemblTranscriptId => (orthologEnsemblTranscriptId !== null ? orthologEnsemblTranscriptId : '-'),
    },
    {
      label: 'Uniprot Accession',
      labelForCSV: 'Ortholog Uniprot Accession',
      dataKey: 'orthologUniprotAccession',
      exportCSV: true,
      disableSort: true,
      width: getColumnPercentWidth(6),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => (rowData.orthologUniprotAccession !== null ?
        <a
          href={`https://www.uniprot.org/uniprot/${rowData.orthologUniprotAccession}`}
          target="blank"
          className="link"
        >
          {rowData.orthologUniprotAccession}
        </a>
        : '-'),
      csvRenderer: orthologUniprotAccession => (orthologUniprotAccession !== null ? orthologUniprotAccession : '-'),
    },
    {
      label: 'OMA ID',
      dataKey: 'omaId',
      exportCSV: true,
      disableSort: true,
      width: getColumnPercentWidth(7),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => (rowData.omaId !== null ?
        <a
          href={`https://omabrowser.org/oma/search/?type=all&query=${rowData.omaId}`}
          target="blank"
          className="link"
        >
          {rowData.omaId}
        </a>
        : '-'),
      csvRenderer: omaId => (omaId !== null ? omaId : '-'),
    },
    {
      label: 'Identity',
      dataKey: 'identity',
      disableSort: true,
      exportCSV: true,
      width: getColumnPercentWidth(7),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Similarity',
      dataKey: 'similarity',
      disableSort: true,
      exportCSV: true,
      width: getColumnPercentWidth(7),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Identity EC',
      dataKey: 'identityEC',
      disableSort: true,
      exportCSV: true,
      width: getColumnPercentWidth(7),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'Similarity EC',
      dataKey: 'similarityEC',
      disableSort: true,
      exportCSV: true,
      width: getColumnPercentWidth(7),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
    },
    {
      label: 'OMA',
      disableSort: true,
      dataKey: 'predictedByOma',
      exportCSV: true,
      width: getColumnPercentWidth(5),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      cellRenderer: rowData => rowData.cellData && <BiCheck size={24} />,
    },
    {
      label: 'ENSEMBL',
      disableSort: true,
      dataKey: 'predictedByEnsembl',
      exportCSV: true,
      width: getColumnPercentWidth(5),
      className: 'table-wrap__cell',
      headerRenderer: d => renderTableHeader(d.label, d.dataKey, d.sortBy, d.sortDirection),
      cellRenderer: rowData => rowData.cellData && <BiCheck size={24} />,
    },
  ];

  const tableSettings = {
    width: tableWidth || getParentBlockWidth(parentClass),
    headerHeight: 50,
    rowClassName: 'table-wrap__row',
    autoHeight: true,
    rowHeight: getRowHeight,
    sortBy,
    sortDirection,
  };

  return (
    <>
      <div className="table-wrap">
        {
          <div className="controls-block-2items">
            {
              filteredData.length > PAGE_SIZE &&
              <div className="paginationContainer">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  pageCount={totalPages}
                  forcePage={activePage}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
            }
            {
              filteredData && filteredData.length > 0 &&
              <ExportTable
                content={filteredData}
                columns={getColumns()}
                fileName={`${geneName}-orthologs`}
              />
            }
          </div>
        }
        <div className="orthologs__table">
          <div
            className="orthologs__table-header"
            style={{ width: tableWidth }}
          >
            <div
              className="orthologs__table-header-cell"
              style={{ width: getColumnPercentWidth(24) + 10 }}
            >
             HUMAN PROTEIN
            </div>
            <div
              className="orthologs__table-header-cell"
              style={{ width: getColumnPercentWidth(15) }}
            >
             SPECIES
            </div>
            <div
              className="orthologs__table-header-cell"
              style={{ width: getColumnPercentWidth(23) }}
            >
             ORTHOLOG CROSSREFERENCES
            </div>
            <div
              className="orthologs__table-header-cell"
              style={{ width: getColumnPercentWidth(28) }}
            >
             IDENTITY/SIMILARITY
            </div>
            <div
              className="orthologs__table-header-cell"
              style={{ width: getColumnPercentWidth(10) }}
            >
             SOURCE
            </div>
          </div>
          <SimpleTable
            autoSize={true}
            settings={tableSettings}
            innerRef={simpleTableRef}
            data={dataPerPage}
            columns={getColumns()}
            dynamicHeight={true}
            sortAction={ACTIONS.sortGeneDetailsOrthologsAction}
          />
        </div>
      </div>
    </>
  );
};

OrthologsTable.propTypes = propTypes;

export default OrthologsTable;
