export const navigation = {
  // Public
  login: 'login',
  cerebrumLogin: 'cerebrum/login',
  forgotPassword: 'forgot-password/:token',
  activate: 'activate/:activationCode/:login',
  activateAccount: 'activate-account/:token',
  // Private
  portal: 'portal',
  research: 'research',
  projects: 'projects',
  createSetPage: 'create-set-page',
  sets: 'sets',
  addSet: 'sets/add/:projectId',
  editSet: 'sets/edit/:projectId/:setId',
  complexSet: 'complex-set/:projectId/:setId',
  analytics: 'analytics',
  setAnalysis: 'analytics/:projectId/:analyticsId',
  workflows: 'workflows',
  conceptDetails: 'concept-details/:conceptId',
  geneDetails: 'gene-details/:conceptId',
  geneDisease: 'gene-disease/:geneId/:diseaseId',
  publicationDetails: 'publication-details/:publicationId',
  setResult: 'set-result-page',
  setsMerge: 'sets-merge-page',
  heatmap: 'heatmap-page',
  buildHeatmap: 'build-heatmap-page',
  pathDetails: 'path-details',
  references: 'references',
  relationMapManagement: 'relation-map',
  relationMap: 'relation-map/:projectId/:mapId',
  searchWithSets: 'search-with-sets',
  findRelated: 'find-related',
  rankSelection: 'rank-selection/:conceptId/:setId/:projectId',
  conceptToConceptIndirectChart: 'association-score/:concept1/:concept2'
};
