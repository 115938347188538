import { call, put, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import * as a from '../store/actions';

function* getSearchConceptDataWorker({ payload }) {
  try {
    if (payload.phrase && payload.phrase.trim() && payload.phrase.trim().length > 1) {
      const { data } = yield call(Api.exactConceptSearch, payload);
      yield put(a.setSearchConceptDataAction({
        ...data,
        geneTaxonomyCounts: Object.keys(data.geneTaxonomyCounts)
          .map(key => ({
            name: key,
            count: data.geneTaxonomyCounts[key],
          })),
      }));
    } else {
      yield put(a.resetSearchConceptDataAction());
    }
  } catch (error) {
    yield put(a.throwSearchConceptDataErrorAction(error.message));
  }
}

function* getConceptSearchCategoriesWorker() {
  try {
    const { data } = yield call(Api.getMajorSemanticCategories);
    yield put(a.setConceptSearchCategoriesAction(data));
  } catch (error) {
    yield put(a.throwConceptSearchCategoriesErrorAction(error.message));
  }
}

function* addConceptSaga() {
  yield takeLatest(a.getSearchConceptDataAction, getSearchConceptDataWorker);
  yield takeLatest(a.getConceptSearchCategoriesAction, getConceptSearchCategoriesWorker);
}

export default addConceptSaga;
