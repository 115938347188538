import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Utils
import { apiTypes, geneSetFiltersNames, publicationsFiltersNames } from '../../../enums';
import { generateAvailableFiltersStructure } from '../../../utils';
import { SearchSourceFilterEnum } from '../../../../../Search/enums';
// Store
import { getCategorySearchDto } from '../../../../../Search/store/selectors';
import {
  getCreateSetConceptsAction,
  setCreateSetAllConceptsAction,
  toggleCreateSetLoaderAction,
  throwCreateSetErrorAction,
  setAvailableFiltersAction,
} from '../../actions';
import {getSelectedConceptSelector, getSelectedSetInfoSelector} from '../../../../../RankSelection/store/selectors';

export function* getCreateSetAllConceptsWorker({ payload }) {
  function* getAvailableFilters(startConcepts, category) {
    try {
      const availableFiltersResponse = yield call(Api.getAvailableFilters, {
        params: {
          startConcepts,
          category,
        },
      });
      return availableFiltersResponse.data;
    } catch (error) {
      console.log(error);
    }
  }

  function* getRelatedConcepts(conceptsLeft, conceptsRight, publicationSource, skipZeroPublications = true) {
    try {
      const getConceptsResponse = yield call(Api.getConcepts, {
        data: {
          conceptsLeft,
          conceptsRight,
          filter: { publicationSource, skipZeroPublications },
        },
      });
      return getConceptsResponse.data;
    } catch (error) {
      console.log(error);
    }
  }

  function* filterWithHomoSapiens(conceptIds, relatedConcepts, publicationSource, skipZeroPublications = true) {
    try {
      yield put(getCreateSetConceptsAction({
        data: {
          conceptsLeft: conceptIds,
          conceptsRight: relatedConcepts,
          filter: { publicationSource, skipZeroPublications },
        },
        filters: [{
          type: geneSetFiltersNames.GENE_TAXONOMY_FILTER,
          taxonomy: 'homo sapiens',
        }],
      }));
    } catch (error) {
      console.log(error);
    }
  }

  try {
    yield put(toggleCreateSetLoaderAction(true));
    const {
      conceptIds,
      apiType,
      semanticType,
      category,
      concepts,
      categoryName,
      initialFilters,
    } = payload;

    const publicationSource = initialFilters && initialFilters.PUBLICATION_FILTERS &&
      initialFilters.PUBLICATION_FILTERS.publicationSource ?
      initialFilters.PUBLICATION_FILTERS.publicationSource :
      SearchSourceFilterEnum.ALL;

    let relatedConcepts;
    let availableFilters;

    switch (apiType) {
      case apiTypes.categoryAnalysisApi: {
        const { data } = yield call(Api.getFullSetByCategory, {
          id: conceptIds[0],
          params: { semanticType },
        });
        availableFilters = yield getAvailableFilters(conceptIds[0], semanticType === 28 ? 'Genes' : null);
        if (availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)) {
          const { data: conceptsData } = yield call(Api.getConceptsByIdsArr, data.map(item => item.nodeB));
          relatedConcepts = conceptsData;
          yield* filterWithHomoSapiens(conceptIds, data.map(item => item.nodeB), publicationSource);
        } else {
          relatedConcepts = yield getRelatedConcepts(conceptIds, data.map(item => item.nodeB), publicationSource);
        }
        break;
      }
      case apiTypes.classificationFullListApi: {
        const { data } = yield call(Api.geneClassSearchFullListResult, conceptIds.join());
        ({ relatedConcepts, availableFilters } = data);

        if (availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)) {
          yield* filterWithHomoSapiens(conceptIds, relatedConcepts.map(item => item.id), publicationSource);
        }
        break;
      }
      case apiTypes.filteredFullListApi: {
        const categorySearchDto = yield select(getCategorySearchDto);
        const { semanticTypeId } = categorySearchDto;
        const { data } = yield call(Api.categorySearchFullListResult, {
          post: {
            categorySearchDto: categoryName && !semanticTypeId ?
              { categoryName } :
              categorySearchDto,
            filter: { publicationSource },
            conceptGis: conceptIds,
          },
        });
        ({ relatedConcepts, availableFilters } = data);

        if (availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)) {
          yield* filterWithHomoSapiens(conceptIds, relatedConcepts.map(item => item.id), publicationSource);
        }
        break;
      }
      case apiTypes.targetsForCancer: {
        const { data } = yield call(Api.categorySearchFullListResult, {
          post: {
            categorySearchDto: { categoryName },
            filter: { publicationSource },
            conceptGis: conceptIds,
          },
        });
        ({ relatedConcepts, availableFilters } = data);

        if (availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)) {
          yield* filterWithHomoSapiens(conceptIds, relatedConcepts.map(item => item.id), publicationSource);
        }
        break;
      }
      case apiTypes.filterWithoutStartConcept: {
        availableFilters = yield getAvailableFilters([], category);
        if (availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)) {
          relatedConcepts = concepts;
          yield* filterWithHomoSapiens([], concepts.map(concept => concept.id), publicationSource, initialFilters ? initialFilters.SKIP_ZERO_PUBLICATIONS : true);
        } else {
          relatedConcepts = yield getRelatedConcepts([],
            concepts.map(concept => concept.id), publicationSource, initialFilters ? initialFilters.SKIP_ZERO_PUBLICATIONS : true);
        }
        break;
      }
      case apiTypes.filterWithConcepts: {
        availableFilters = yield getAvailableFilters(conceptIds[0], category);
        if (availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)) {
          relatedConcepts = yield getRelatedConcepts(conceptIds, concepts, publicationSource);
          yield* filterWithHomoSapiens(conceptIds, concepts, publicationSource);
        } else {
          relatedConcepts = yield getRelatedConcepts(conceptIds, concepts, publicationSource);
        }
        break;
      }
      case apiTypes.rankTargets: {
        const setInfo = yield select(getSelectedSetInfoSelector);
        const disease = yield select(getSelectedConceptSelector);
        const { data } = yield call(Api.getCreateSetData, {
          post: {
            conceptId: disease.id,
            setId: setInfo.setId,
            projectId: setInfo.projectId === 'personal' ? null : setInfo.projectId,
            noPublicationsFilter: false,
          },
        });
        ({ relatedConcepts, availableFilters } = data);
        break;
      }
      default:
        break;
    }

    if (availableFilters && (publicationSource === SearchSourceFilterEnum.ABSTRACT || publicationSource === SearchSourceFilterEnum.DATABASE)) {
      availableFilters.push(publicationsFiltersNames.SOURCE_DETAILS_FILTER);
    }

    const updatedAvailableFilters = generateAvailableFiltersStructure(availableFilters);
    yield put(setCreateSetAllConceptsAction(relatedConcepts));
    yield put(setAvailableFiltersAction(updatedAvailableFilters));

    if (!availableFilters.includes(geneSetFiltersNames.GENE_TAXONOMY_FILTER)
      || apiType === apiTypes.rankTargets) {
      yield put(toggleCreateSetLoaderAction(false));
    }
  } catch (e) {
    yield put(throwCreateSetErrorAction(e.message));
    yield put(toggleCreateSetLoaderAction(false));
  }
}
