import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getBuildHeatmapDataAction = createAction('@@build-heatmap/GET_DATA');
export const setBuildHeatmapDataAction = createAction('@@build-heatmap/SET_DATA');
export const buildHeatmapAddConceptAction = createAction('@@build-heatmap/ADD_CONCEPT');
export const buildHeatmapUpdateConceptsAction = createAction('@@build-heatmap/DELETE_CONCEPT');
export const throwBuildHeatmapErrorAction = createAction('@@build-heatmap/THROW_ERROR');
export const resetBuildHeatmapDataAction = createAction('@@build-heatmap/RESET_DATA');
export const swapBuildHeatmapAxisAction = createAction('@@build-heatmap/SWAP_DATA');

const initialState = fromJS({
  set: null,
  loading: false,
  error: null,
  heatmapConcepts: {
    conceptIds: [],
    connectedConceptIds: [],
  },
  axisSwaped: false,
});


const reducer = handleActions(
  {
    [getBuildHeatmapDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setBuildHeatmapDataAction]: (state, { payload }) =>
      state
        .update('set', () => fromJS(payload.data))
        .update('loading', () => false)
        .update('error', () => null)
        .updateIn(['heatmapConcepts', 'connectedConceptIds'], () => fromJS(payload.ids)),
    [throwBuildHeatmapErrorAction]: (state, { payload }) =>
      state
        .update('set', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [buildHeatmapAddConceptAction]: (state, { payload }) => {
      const value = state.get('axisSwaped') ? 'connectedConceptIds' : 'conceptIds';
      return state
        .updateIn(['heatmapConcepts', value],
          values => values.push(fromJS(payload.id))
        );
    },
    [buildHeatmapUpdateConceptsAction]: (state, { payload }) =>
      state
        .update('heatmapConcepts', () => fromJS(payload)),
    [swapBuildHeatmapAxisAction]: state =>
      state
        .update('heatmapConcepts', heatmapConcepts => fromJS({
          conceptIds: heatmapConcepts.get('connectedConceptIds'),
          connectedConceptIds: heatmapConcepts.get('conceptIds'),
        }))
        .update('axisSwaped', axisSwaped => !axisSwaped),
    [resetBuildHeatmapDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
