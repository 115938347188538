import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

// Components
import Sets from '../../../../Sets/SetsList/Sets';
import Button from '../../../../common/Buttons/Button/Button';
import CreateSet from '../../../../Sets/CreateSet/CreateSet';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import CustomRelationModal from '../CustomRelationModal/CustomRelationModal';
import RelatedConceptsSelect from '../RelatedConceptsSelect/RelatedConceptsSelect';
import IntermediateConceptsModal from '../IntermediateConceptsModal/IntermediateConceptsModal';
import ConceptSearchForm from '../../../../Concept/ConceptSearchForm/ConceptSearchForm';
import ConceptSearchModal from '../../../../Concept/ConceptSearchModal/ConceptSearchModal';
import SaveAsSetModal from '../../../../Sets/SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';

// Constants
import { CreateSetModalInitialValues } from '../../constants';
import { apiTypes } from '../../../../Sets/CreateSet/enums';
// Store
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  mergeLinks: PropTypes.func,
  getSetData: PropTypes.func,
  addNewConcepts: PropTypes.func,
  conceptsLimit: PropTypes.number,
  selectedConcepts: PropTypes.instanceOf(Array),
  customRelationConcepts: PropTypes.instanceOf(Array),
  relatedConceptsOptions: PropTypes.instanceOf(Object),
  getRelatedConceptsOptions: PropTypes.func,
};

const RelationMapHeader = (props) => {
  const {
    mergeLinks,
    getSetData,
    addNewConcepts,
    conceptsLimit,
    selectedConcepts,
    relatedConceptsOptions,
    customRelationConcepts,
    getRelatedConceptsOptions,
  } = props;
  const conceptsLimitMessage =
    `You can choose a set with no more than ${conceptsLimit} concepts. Maximum amount of concepts on map is 400.`;

  const [saveAsSetModal, setSaveAsSetModal] = useState(false);
  const [showAddSetModal, setShowAddSetModal] = useState(false);
  const [customRelationModal, setCustomRelationModal] = useState(false);
  const [intermediateConceptsModal, setIntermediateConceptsModal] = useState(false);
  const [createSetModal, setCreateSetModal] = useState(CreateSetModalInitialValues);
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);
  const [selectedRelatedConcept, setSelectedRelatedConcept] = useState(null);

  const { selectedConceptsIds, selectedConceptsNames } = useMemo(() =>
    selectedConcepts.reduce(
      (d, c) => {
        d.selectedConceptsIds.push(c.id);
        d.selectedConceptsNames.push(c.name);
        return d;
      }, { selectedConceptsIds: [], selectedConceptsNames: [] }
    ), [selectedConcepts]
  );

  function selectSetHandler({ projectId, selectedSets }) {
    getSetData({ projectId, setId: selectedSets[0].id });
  }

  function selectRelatedConceptHandler(option) {
    setSelectedRelatedConcept(option);
    setCreateSetModal({
      show: true,
      config: {
        conceptIds: selectedConceptsIds,
        conceptNames: selectedConceptsNames,
        categoryName: option.value,
        apiType: apiTypes.filteredFullListApi,
      },
    });
  }

  function clearRelatedConceptHandler() {
    setSelectedRelatedConcept(null);
    setCreateSetModal(CreateSetModalInitialValues);
  }

  function addConceptsHandler(concepts) {
    addNewConcepts({ concepts });
  }

  function addConceptsIdsHandler(concept) {
    addNewConcepts({
      concepts: [concept.id],
    });
  }

  function getRelatedConceptsOptionsHandler() {
    getRelatedConceptsOptions(selectedConceptsIds[0]);
  }

  return (
    <>
      <div className="relation-map-page__header relation-map-header">
        <div
          className="relation-map-add-concept"
          data-tip={true}
          data-for="addConceptTooltip"
        >
          <ConceptSearchForm onSubmitCallback={() => setShowConceptSearchModal(true)} />
        </div>
        <div data-tip={true} data-for="addConceptTooltip">
          <Button
            text="Add set"
            onClick={() => setShowAddSetModal(true)}
            disabled={conceptsLimit <= 0}
          />
        </div>
        <div data-tip={true} data-for="addConceptTooltip">
          <RelatedConceptsSelect
            value={selectedRelatedConcept}
            onSelect={selectRelatedConceptHandler}
            options={relatedConceptsOptions.options}
            isLoading={relatedConceptsOptions.loading}
            placeholder="Add related concept"
            disabled={conceptsLimit <= 0 || selectedConceptsIds.length !== 1}
            fetchOptions={getRelatedConceptsOptionsHandler}
          />
        </div>
        <div data-tip={true} data-for="addConceptTooltip">
          <Button
            text="Add intermediate concept"
            disabled={conceptsLimit <= 0 || selectedConceptsIds.length < 2 || selectedConceptsIds.length > 3}
            onClick={() => setIntermediateConceptsModal(true)}
          />
        </div>
        <Button
          text="Add custom relation"
          disabled={!customRelationConcepts.length}
          onClick={() => setCustomRelationModal(true)}
        />
        <Button
          text="Save as Set"
          disabled={selectedConceptsIds.length === 0}
          onClick={() => setSaveAsSetModal(true)}
        />
      </div>
      {
        conceptsLimit <= 0 &&
        <ReactTooltip
          id="addConceptTooltip"
          place="bottom"
          type="dark"
        >
          Maximum amount of concepts on map is 400
        </ReactTooltip>
      }
      {
        intermediateConceptsModal &&
        <IntermediateConceptsModal
          isOpen={intermediateConceptsModal}
          conceptsIds={selectedConceptsIds}
          conceptsNames={selectedConceptsNames}
          conceptsLimit={conceptsLimit}
          conceptsLimitMessage={conceptsLimitMessage}
          getConcepts={addConceptsHandler}
          closeCb={() => setIntermediateConceptsModal(false)}
        />
      }
      {
        createSetModal.show &&
        <ModalComponent
          isOpen={createSetModal.show}
          closeCb={clearRelatedConceptHandler}
          modalClassName="modal_no-paddings"
        >
          <CreateSet
            config={createSetModal.config}
            getConcepts={addConceptsHandler}
            closePopup={clearRelatedConceptHandler}
            conceptsLimit={conceptsLimit}
            conceptsLimitMessage={conceptsLimitMessage}
          />
        </ModalComponent>
      }
      {
        showAddSetModal &&
        <ModalComponent
          isOpen={showAddSetModal}
          closeCb={() => setShowAddSetModal(false)}
        >
          <Sets
            selectFunction={selectSetHandler}
            closeFunction={() => setShowAddSetModal(false)}
            conceptsLimit={conceptsLimit}
            conceptsLimitMessage={conceptsLimitMessage}
          />
        </ModalComponent>
      }
      <SaveAsSetModal
        isOpen={saveAsSetModal}
        closeCb={() => setSaveAsSetModal(false)}
        idsForShortConceptDetails={selectedConceptsIds}
      />
      {
        customRelationModal &&
        <CustomRelationModal
          isOpen={customRelationModal}
          mergeLinks={mergeLinks}
          concepts={customRelationConcepts}
          closeCb={() => setCustomRelationModal(false)}
        />
      }
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={addConceptsIdsHandler}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
      />
    </>
  );
};

RelationMapHeader.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    relatedConceptsOptions: SELECTORS.getRelationMapRelatedConceptsOptionsSelector(state),
    selectedConcepts: SELECTORS.getRelationMapSelectedConceptsSelector(state),
    conceptsLimit: SELECTORS.getRelationMapConceptsLimitSelector(state),
    customRelationConcepts: SELECTORS.getRelationMapConceptsForCustomRelationSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewConcepts(data) {
      dispatch(ACTIONS.addNewConceptsToRelationMapAction(data));
    },
    getSetData(data) {
      dispatch(ACTIONS.getRelationMapSetDataAction(data));
    },
    mergeLinks(data) {
      dispatch(ACTIONS.mergeRelationMapLinksAction(data));
    },
    getRelatedConceptsOptions(data) {
      dispatch(ACTIONS.getRelationMapRelatedConceptsOptionsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationMapHeader);
