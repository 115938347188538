import {apiTypes} from '../CreateSet/enums';

export function getCreateSetParams(location) {
  const urlParams = new URLSearchParams(location);

  return {
    action: urlParams.get('action'),
    startConceptId: urlParams.get('startConceptId'),
    startConceptName: urlParams.get('startConceptName'),
  };
}

export function getCreateSetCategory(apiType) {
  switch (apiType) {
    case apiTypes.targetsForCancer: return 'Genes';
    default: return 'Genes';
  }
}
