import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalComponent from '../../../ModalComponent/ModalComponent';
import ConceptsSearchSidebar from '../../../Concept/ConceptSearchModal/Components/ConceptsSearchSidebar/ConceptsSearchSidebar';
import ConceptsSearchContent from '../../../Concept/ConceptSearchModal/Components/ConceptsSearchContent/ConceptsSearchContent';
// Store
import {setSelectedConceptAction} from '../../store/actions';
import {getSelectedConceptSelector} from '../../store/selectors';
import {
  getSearchConceptDataAction,
  resetSearchConceptAction,
  setSearchConceptInputValueAction
} from '../../../Concept/ConceptSearchModal/store/actions';
import {
  getActiveCategoriesSelector,
  getSearchConceptFiltersSelector,
  getSearchConceptsInputValueSelector
} from '../../../Concept/ConceptSearchModal/store/selectors';
// Styles
import './styles.scss';
import RankTargetsConfig from './RankTargetsConfig';

const propTypes = {
  filters: PropTypes.instanceOf(Object),
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  getConcepts: PropTypes.func,
  onSubmit: PropTypes.func,
  resetOnClose: PropTypes.bool,
  reset: PropTypes.func,
  formName: PropTypes.string,
  activeCategories: PropTypes.array,
  setSelectedConcept: PropTypes.func,
  selectedConcept: PropTypes.instanceOf(Object),
  setConceptInputValue: PropTypes.func,
  hideOptions: PropTypes.bool,
  currentConfig: PropTypes.object,
};

const RankSelectionParamsModal = (props) => {
  const {
    filters,
    inputValue,
    isOpen,
    closeCb,
    getConcepts,
    resetOnClose,
    reset,
    formName,
    activeCategories,
    setSelectedConcept,
    selectedConcept,
    setConceptInputValue,
    hideOptions,
    currentConfig,
  } = props;

  useEffect(() => {
    reset();
    setConceptInputValue(selectedConcept.searchPhrase || selectedConcept.data);
  }, []);

  useEffect(() => {
    if (resetOnClose && !isOpen) {
      reset();
    }
  }, [resetOnClose, isOpen, reset]);

  useEffect(() => {
    getConcepts({
      phrase: inputValue,
      ...filters,
    });
  }, [inputValue, filters, getConcepts]);

  function onSubmit(c) {
    setSelectedConcept({id: c.id, name: c.name, searchPhrase: inputValue});
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="modal_no-paddings"
    >
      <div className="rank-selection-concepts-search">
        <ConceptsSearchSidebar
          filters={filters}
          inputValue={inputValue}
          activeCategories={activeCategories}
          threePanels={true}
        />
        <ConceptsSearchContent
          inputValue={inputValue || selectedConcept.data}
          closeCb={closeCb}
          onSubmit={(c) => {onSubmit(c);}}
          formName={formName}
          activeCategories={activeCategories}
          threePanels={true}
          selectedConceptId={selectedConcept.id}
        />
        <RankTargetsConfig
          closeCb={closeCb}
          hideOptions={hideOptions}
          currentConfig={currentConfig}
        />
      </div>
    </ModalComponent>
  );
};

RankSelectionParamsModal.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    filters: getSearchConceptFiltersSelector(state),
    inputValue: getSearchConceptsInputValueSelector(state),
    activeCategories: getActiveCategoriesSelector(state),
    selectedConcept: getSelectedConceptSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConcepts(data) {
      dispatch(getSearchConceptDataAction(data));
    },
    reset() {
      dispatch(resetSearchConceptAction());
    },
    setSelectedConcept(data) {
      dispatch(setSelectedConceptAction(data));
    },
    setConceptInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankSelectionParamsModal);
