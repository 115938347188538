import { call, select, put } from 'redux-saga/effects';

// Api
import Api from '../../../../../../Api/Api';
// Store
import {
  getCreateSetNameSelector,
  getCreateSetTagsSelector,
  getCreateSetConceptsSelector,
  getCreateSetDescriptionSelector,
  getFiltersDataSelector,
  getCreateSelectedProjectSelector,
} from '../../selectors';
import {
  throwSaveSetErrorAction,
  clearSaveSetErrorAction,
  setSavedSuccessfullyAction,
} from '../../actions';
import { getSetManagementDataAction } from '../../../../SetsManagement/reducer';
import { showFreeAccountLimitDialogAction } from '../../../../../common/FreeAccountLimitationDialog/store/reducer';
// Utils
import { apiTypes } from '../../../enums';
import { getSaveAsSetRequestPayload } from '../../../utils';
import { getProjectIdForRequest } from '../../../../../Projects/ProjectsTabs/utils';

export function* saveCreatedSetWorker({ payload }) {
  try {
    yield put(clearSaveSetErrorAction());
    const { filter, filters } = yield select(getFiltersDataSelector);
    const project = yield select(getCreateSelectedProjectSelector);
    const projectId = project && getProjectIdForRequest(project.id);
    const setName = yield select(getCreateSetNameSelector);

    const requestData = getSaveAsSetRequestPayload({
      name: setName,
      description: yield select(getCreateSetDescriptionSelector),
      tags: yield select(getCreateSetTagsSelector),
      items: yield select(getCreateSetConceptsSelector),
      projectId,
      filter,
      filters,
    });

    let savedSetData = null;
    if (projectId) {
      savedSetData = yield call(Api.addProjectSet, requestData);
    } else {
      savedSetData = yield call(Api.editSet, requestData);
    }

    if (payload === apiTypes.filterWithoutStartConcept) {
      yield put(getSetManagementDataAction());
    }

    yield put(setSavedSuccessfullyAction({ setId: savedSetData.data, projectId, setName }));
  } catch (e) {
    if (e.response && e.response.status === 402) {
      yield put(showFreeAccountLimitDialogAction());
    } else if (e.response) {
      yield put(throwSaveSetErrorAction(e.response.data.message));
    }
  }
}
