/* First case */
export const SAVE_SET_FOR_SET_RESULT = 'SAVE_SET_FOR_SET_RESULT';
/* Second case */
export const SAVE_CONCEPT_AND_CATEGORY = 'SAVE_CONCEPT_AND_CATEGORY';
/* Third case */
export const SAVE_PAIR_OF_CONCEPTS = 'SAVE_PAIR_OF_CONCEPTS';

export const RESULT_SET_REQUESTED = 'RESULT_SET_REQUESTED';
export const RESULT_SET_SUCCEEDED = 'RESULT_SET_SUCCEEDED';
export const RESULT_SET_FAILED = 'RESULT_SET_FAILED';

export const GO_TO_SET_RESULT_PAGE = 'GO_TO_SET_RESULT_PAGE';
export const SET_RESULT_RESET = 'SET_RESULT_RESET';

export const SET_RESULT_PAGE_TABLE = 'SET_RESULT_PAGE_TABLE_';

export const LOADING = 'LOADING';

export const SET_A_OPEN_MENU = 'SET_A_OPEN_MENU';
export const SET_A_CLOSE_MENU = 'SET_A_CLOSE_MENU';

export const SET_B_OPEN_MENU = 'SET_B_OPEN_MENU';
export const SET_B_CLOSE_MENU = 'SET_B_CLOSE_MENU';

export const SET_C_OPEN_MENU = 'SET_C_OPEN_MENU';
export const SET_C_CLOSE_MENU = 'SET_C_CLOSE_MENU';

export const SHORT_CONCEPTS_DETAILS_REQUESTED = 'SHORT_CONCEPTS_DETAILS_REQUESTED';
export const SHORT_CONCEPTS_DETAILS_SUCCEEDED = 'SHORT_CONCEPTS_DETAILS_SUCCEEDED';
export const SHORT_CONCEPTS_DETAILS_FAILED = 'SHORT_CONCEPTS_DETAILS_FAILED';

export const FULL_SET_BY_CATEGORY_REQUESTED = 'FULL_SET_BY_CATEGORY_REQUESTED';
export const FULL_SET_BY_CATEGORY_SUCCEEDED = 'FULL_SET_BY_CATEGORY_SUCCEEDED';
export const FULL_SET_BY_CATEGORY_FAILED = 'FULL_SET_BY_CATEGORY_FAILED';

export const RESET_SET_RESULT_PRIMARY_SETTINGS = 'RESET_SET_RESULT_PRIMARY_SETTINGS';

export const SET_NEW_FULL_SET = 'SET_NEW_FULL_SET';

export const SET_CONFIRM_ADD_CONCEPT_POPUP_OPEN_KEY = 'SET_CONFIRM_ADD_CONCEPT_POPUP_OPEN_KEY';

export const SET_PREVIOUS_SET_ID = 'SET_PREVIOUS_SET_ID';
export const SET_CURRENT_SET_ID = 'SET_CURRENT_SET_ID';

export const EXPORT_ALL_TO_CSV = 'EXPORT_ALL_TO_CSV';
export const EXPORT_ALL_TO_CSV_RESULT_SUCCESS = 'EXPORT_ALL_TO_CSV_RESULT_SUCCESS';

export const SET_RESULT_PAGE_SAVE_COLUMN_AS_SET = 'SET_RESULT_PAGE_SAVE_COLUMN_AS_SET';
