import React from 'react';
import PropTypes from 'prop-types';

// Components
import SaveAsSet from '../../SaveAsSet';
import ModalComponent from '../../../../ModalComponent/ModalComponent';

const propTypes = {
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  projectId: PropTypes.string,
  idsForShortConceptDetails: PropTypes.instanceOf(Array),
  getShortConceptDetailsFunction: PropTypes.func,
  getShortConceptDetailsFunctionParams: PropTypes.instanceOf(Object),
  withFileUpload: PropTypes.bool,
  onSavingSet: PropTypes.func,
  uploadOnlySimple: PropTypes.bool,
  uploadOnlyByDefaultCategory: PropTypes.bool,
  defaultValues: PropTypes.instanceOf(Object),
};

const SaveAsSetModal = (props) => {
  const {
    isOpen,
    closeCb,
    projectId,
    idsForShortConceptDetails,
    getShortConceptDetailsFunction,
    getShortConceptDetailsFunctionParams,
    withFileUpload = false,
    onSavingSet,
    uploadOnlySimple,
    defaultValues,
    uploadOnlyByDefaultCategory,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
    >
      <SaveAsSet
        isPopup={true}
        projectId={projectId}
        saveAsSetModalClose={closeCb}
        idsForShortConceptDetails={idsForShortConceptDetails}
        getShortConceptDetailsFunction={getShortConceptDetailsFunction}
        getShortConceptDetailsFunctionParams={getShortConceptDetailsFunctionParams}
        withFileUpload={withFileUpload}
        onSavingSet={onSavingSet}
        defaultValues={defaultValues}
        uploadOnlySimple={uploadOnlySimple}
        uploadOnlyByDefaultCategory={uploadOnlyByDefaultCategory}
      />
    </ModalComponent>
  );
};

SaveAsSetModal.propTypes = propTypes;

export default React.memo(SaveAsSetModal);
