import { format } from 'date-fns';
import { matchPath } from 'react-router';

// Constants
import { RELATIVE_PATH, conceptTypesByIds, DATE_FORMAT } from '../../constantsCommon';

export const { html2canvas } = window;
export const $ = window.jQuery;
export const AVERAGE_SYMBOL_LENGTH = 9;

export const checkIfAnyChecked = data => data.some(item => item.selected);

export const sort = (list, sorting) => {
  if (!list) return list;

  const sortByValue = sorting.sortPath || [sorting.sortBy];

  const extractValue = (item, sortValueMapper, sortBy) => (
    sortValueMapper ? sortValueMapper(item) : item.getIn(sortBy)
  );

  const comparator = (a, b) => {
    const itemA = extractValue(a, sorting.sortValueMapper, sortByValue);
    const itemB = extractValue(b, sorting.sortValueMapper, sortByValue);

    if (typeof itemA === 'string' && typeof itemB === 'string') {
      return itemA.localeCompare(itemB);
    } else if (
      (typeof itemA === 'number' && typeof itemB === 'number') ||
      (typeof itemA === 'boolean' && typeof itemB === 'boolean')) {
      return itemA - itemB;
    } else if (itemA == null) {//dont change to (!itemA) -- it causes problems with 0 values
      return sorting.sortDirection === 'ASC' ? 1 : -1;
    } else if (itemB == null) {
      return sorting.sortDirection === 'ASC' ? -1 : 1;
    }
    return 0;
  };

  const reverseComp = (a, b) => -comparator(a, b);

  return list.sort(sorting.sortDirection === 'DESC' ? reverseComp : comparator);
};

export const checkAmbiguity = data => data.some(item => item.mappedGenes && item.mappedGenes.length > 1);

export const checkUnresolved = data => data.some(item => item.mappedGenes && item.mappedGenes.length === 0);

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

export const convertToRGB = (hex) => {
  const rgb = hexToRgb(hex);
  return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
};

export const capitalizeFirstLetter = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

export const convertTableToCSVText = (list, cols, url) => {
  let rows = '';
  rows += `"${cols.map(col => col.labelForCSV || col.label).join('","')}"\n`;

  list.forEach((item) => {
    const row = [];
    cols.forEach((col) => {
      if (col.dataPath) {
        let rowData = item;
        col.dataPath.forEach((pathItem) => {
          rowData = rowData[pathItem];
        });
        row.push(rowData);
      } else if (col.dataKey && item[col.dataKey] instanceof Array) {
        if (col.csvRenderer) {
          row.push(item[col.dataKey].map(i => col.csvRenderer(i)).join(', '));
        } else {
          row.push(item[col.dataKey].map(i => (col.dataKeySub ? i[col.dataKeySub] : i)).join('; '));
        }
      } else if (col.dataKey && item[col.dataKey] instanceof Object) {
        if (col.csvRenderer) {
          row.push(col.csvRenderer(item));
        } else {
          row.push(item[col.dataKey].name);
        }
      } else {
        let value = '';
        // TODO: export with pubmedIds
        if (col.dataKey === 'pubmedIDs') {
          value = item[col.dataKey].map(id => url + id);
        } else if (col.csvRenderer) {
          row.push(col.csvRenderer(item[col.dataKey]));
        } else {
          row.push(value || item[col.dataKey]);
        }
      }
    });

    rows += `"${row.join('","').replace(/\n+/gi, '; ')
      .replace(/(; )+/gi, '; ')}"\n`;
  });

  return rows;
};

export const exportToFile = (target, text, fileName, formatType = 'csv') => {
  const clickEvent = new MouseEvent('click', {
    'view': window,
    'bubbles': true,
    'cancelable': false,
  });

  /* Solution that works with Microsoft Edge */
  const blob = new Blob([text], { type: ` type: "text/${formatType};charset=UTF-8"` });
  const url = window.URL.createObjectURL(blob);
  target.setAttribute('href', url);

  target.setAttribute('download', `${fileName || 'export'}.${formatType}`);

  target.dispatchEvent(clickEvent);
};

export const exportToCSV = (link, columns, content, fileName) => {
  const result = convertTableToCSVText(content, columns.filter(column => column.exportCSV));
  exportToFile(link, result, fileName || 'table-export');
};

export const scrollToBottom = (scrollableElementSelector = 'body') => {
  const element = document.querySelector(scrollableElementSelector);
  setTimeout(() => {
    element.scrollTop = element.scrollHeight;
  }, 0);
};

export const scrollToTop = (scrollableElementSelector) => {
  const element = document.querySelector(scrollableElementSelector);
  setTimeout(() => {
    element.scrollTop = 0;
  }, 0);
};

export const scrollIntoView = (scrollableElementSelector = 'body') => {
  const element = document.querySelector(scrollableElementSelector);
  if (element) {
    setTimeout(() => {
      element.scrollIntoView(true);
    }, 0);
  }
};

export const manageableScrollInToView = (scrollableElementSelector = 'body', headerSelector, getMainHeader) => {
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
  const element = document.querySelector(scrollableElementSelector);
  const header = document.querySelector(headerSelector);
  const mainHeader = document.querySelector('.main-page-header');
  const mainHeaderHeight = getMainHeader && mainHeader ? mainHeader.offsetHeight : 0;
  const elementTop = element ? element.offsetTop - 60 : 0;
  const headerSelectorHeight = header ? header.offsetHeight + mainHeaderHeight : 0;
  const top = elementTop - headerSelectorHeight;

  if (isSmoothScrollSupported) {
    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    });
  } else {
    window.scrollTo(0, top);
  }
};

export const getFullTypeByID = id => conceptTypesByIds[id];

export const anyKeyTrue = (obj) => {
  let bool = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj[key]) {
      bool = true;
    }
  }
  return bool;
};

export const getKeysByBool = (obj, bool) => {
  const arr = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj[key] === bool) {
      arr.push(key);
    }
  }
  return arr;
};

export const setContainsGenes = (data) => {
  if (!data) return false;
  return data.some((concept) => {
    const types = concept.type || concept.semtypes;
    if (!types) {
      return false;
    }

    return Array.isArray(types) ?
      types.some(type => type.id === 'T028') :
      concept.type.id === 'T028';
  });
};

export const setContainsProteins = (data) => {
  if (!data) return false;
  return data.some((concept) => {
    const types = concept.type || concept.semtypes;
    if (!types) {
      return false;
    }

    return Array.isArray(types) ?
      types.some(type => type.id === 'T116') :
      types.id === 'T116';
  });
};

export const getCaret = (el) => {
  let r; let re; let
    rc;

  if (el.selectionStart) {
    return el.selectionStart;
  } else if (document.selection) {
    el.focus();

    r = document.selection.createRange();
    if (r === null) {
      return 0;
    }

    re = el.createTextRange();
    rc = re.duplicate();
    re.moveToBookmark(r.getBookmark());
    rc.setEndPoint('EndToStart', re);

    return rc.text.length;
  }
  return 0;
};

export const setCaretPosition = (el, pos) => {
  if (el !== null) {
    setTimeout(() => {
      if (el.createTextRange) {
        const range = el.createTextRange();
        range.move('character', pos);
        range.select();
      } else if (el.selectionStart) {
        el.focus();
        el.setSelectionRange(pos, pos);
      } else {
        el.focus();
      }
    }, 0);
  }
};

export const getScreenWidth = () => window.screen.width;

export const getParentBlockWidth = (parentClass) => {
  const element = document.querySelector(`.${parentClass}`);
  if (element) {
    return element.offsetWidth;
  }
  return 0;
};

export const isEmptyObject = (obj = {}) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const insertHTML = (markup = '') => ({ __html: markup });

export const generateNumber = () => {
  const MAX = 1000000;
  const MIN = 9999999;
  return Math.floor(Math.random() * (MAX - MIN)) + MIN;
};

export const exportToPNG = (el, name, isCircosChart = false, is2DProtein = false, isCCLE = false, isExpressionTPM = false) => {
  let stringifiedSVG = new XMLSerializer().serializeToString(el);
  if (isCircosChart) {
    stringifiedSVG = stringifiedSVG.replace(/width="850" height="850"/, 'width="1870" height="1870"');
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      stringifiedSVG = stringifiedSVG.replace(/class="all" transform="translate\(&#xA; {10}425,&#xA; {10}425&#xA; {8}\)/, 'class="all" transform="translate(935,935)');
    } else {
      stringifiedSVG = stringifiedSVG.replace(/class="all" transform="translate\(&#10; {10}425,&#10; {10}425&#10; {8}\)/, 'class="all" transform="translate(935,935)');
    }
  }
  if (is2DProtein) {
    const svgHeightString = stringifiedSVG.match(/height="[0-9]{1,}\.{0,1}[0-9]{0,}pt"/)[0];
    const svgHeight = svgHeightString.match(/[0-9]{1,}\.{0,1}[0-9]{0,}/)[0];
    const svgWidthString = stringifiedSVG.match(/width="[0-9]{1,}\.{0,1}[0-9]{0,}pt"/)[0];
    const svgWidth = svgWidthString.match(/[0-9]{1,}\.{0,1}[0-9]{0,}/)[0];
    stringifiedSVG = stringifiedSVG.replace(/height="[0-9]{1,}\.{0,1}[0-9]{0,}pt" width="[0-9]{1,}\.{0,1}[0-9]{0,}pt"/, `height="${svgHeight * 4}pt" width="${svgWidth * 4}pt"`);
  }

  stringifiedSVG = stringifiedSVG.replaceAll('font-size: 16px;', 'font: 20px sans-serif;');

  if (!isCircosChart && !is2DProtein && !isExpressionTPM) {
    stringifiedSVG = stringifiedSVG.replace('class="beeswarm-area" width="1400" height="700">', 'class="beeswarm-area" width="1400" height="780">'); // for beeswarm
    stringifiedSVG = stringifiedSVG.replace('class="bar-chart-diagram" width="1285" height="570">', 'class="bar-chart-diagram" width="1320" height="700">');
    stringifiedSVG = stringifiedSVG.replace('transform="translate(20, 50)"', 'transform="translate(40, 50)"');
  }
  if (isCCLE) {
    stringifiedSVG = stringifiedSVG.replace(/width="2020" height="1020"/, 'width="2100" height="1075"');
  }
  if (isExpressionTPM) {
    stringifiedSVG = stringifiedSVG.replace('width="1285" height="770"', 'width="1385" height="900"');
  }

  const image = new Image();
  image.src = `data:image/svg+xml;base64,${window.btoa(stringifiedSVG.replace(/[\u00A0-\u2666]/g, function(c) {
    return '&#' + c.charCodeAt(0) + ';';
  }))}`;
  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, image.width, image.height);

    const a = document.createElement('a');
    document.body.appendChild(a); // Firefox requires the link to be in the body
    a.download = name || 'map.png';
    a.href = canvas.toDataURL('image/png');
    a.click();
    document.body.removeChild(a);
  };
};

export const exportToSVG = (container, name, isCircosChart = false, is2DProtein = false, isCCLE = false, isExpressionTPM = false) => {
  let text = container.innerHTML.replace('<div>', '').replace('</div>', '');
  text = text.replace('&lt;', '<').replace('&gt;', '>')
    .replace(/&nbsp;/g, '');
  text = text.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  text = text.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
  // eslint-disable-next-line no-multi-str, no-useless-escape
  text = text.replace(/text.plaintext {[^\/}]*}/mg, 'text.plaintext {\n\
      font-family:Helvetica;\n\
      font-size: 11px;\n\
    }');

  if (isCircosChart) {
    text = text.replace(/width="850" height="850"/, 'width="1870" height="1870"');
    text = text.replace(/class="all" transform="translate\(\n {10}425,\n {10}425\n {8}\)/, 'class="all" transform="translate(935,935)');
  }

  if (is2DProtein) {
    text = text.replace(/height="[0-9]{1,}\.{0,1}[0-9]{0,}pt" width="[0-9]{1,}\.{0,1}[0-9]{0,}pt"/, 'height="663.147pt" width="1386.057pt"');
  }

  if (isCCLE) {
    text = text.replace(/width="2020" height="1020"/, 'width="2100" height="1075"');
  }

  if (!isCircosChart && !is2DProtein && !isExpressionTPM) {
    text = text.replace('class="beeswarm-area" width="1400" height="700">', 'class="beeswarm-area" width="1400" height="780">'); // for beeswarm
    text = text.replace('class="bar-chart-diagram" width="1285" height="570">', 'class="beeswarm-area" width="1320" height="700">');
    text = text.replace('transform="translate(20, 50)"', 'transform="translate(40, 50)"');
  }

  if (isExpressionTPM) {
    text = text.replace('width="1285" height="770"', 'width="1385" height="900"');
  }

  text = text.replaceAll('font-size: 16px;', 'font: 20px sans-serif;');

  const svgBlob = new Blob([text], { 'type': 'image/svg+xml' });
  const url = URL.createObjectURL(svgBlob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = name || 'map.svg';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const filterPaginatedData = (array, page, pageSize) => {
  const totalLength = array.length;
  const start = page * pageSize;
  const end = (page * pageSize) + pageSize;
  const result = array.slice(start, end > totalLength ? totalLength : end);
  return result;
};

export function getLocationParams(search) {
  if (isEmptyObject(search)) {
    return {};
  }
  const params = new URLSearchParams(search);
  const name = params.get('name');
  const exactValue = params.get('exactValue');
  const plainValue = params.get('plainValue');
  const database = params.get('database');
  const page = params.get('page');
  const size = params.get('size');
  const token = params.get('token');
  const runAfter = params.get('runAfter');
  const publicationSource = params.get('publicationSource');
  const publicationTypes = params.getAll('publicationTypes');
  const researchTypes = params.getAll('researchTypes');
  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');
  const cooccurrenceType = params.get('cooccurrenceType');
  const geneId = params.get('geneId');
  const geneName = params.get('geneName');
  const tumorType = params.get('tumorType');
  const algorithm = params.get('algorithm');

  return {
    name,
    exactValue,
    plainValue,
    database,
    page,
    size,
    token,
    runAfter,
    publicationSource,
    publicationTypes,
    researchTypes,
    dateFrom,
    dateTo,
    cooccurrenceType,
    geneId,
    geneName,
    tumorType,
    algorithm,
  };
}

export function checkIsReturnToThePage(locationHistory) {
  if (!locationHistory.length) return false;
  return locationHistory[0] === locationHistory[2];
}

export function checkIsReturnToThePageAfterAutoSave(locationHistory, pageName) {
  if (locationHistory.length < 2) return false;
  return locationHistory[locationHistory.length - 1].includes(pageName) && locationHistory[locationHistory.length - 2].includes(pageName);
}

export function formatDate(date, formatString) {
  const dateValue = date ? new Date(date) : new Date();
  const formatValue = formatString || DATE_FORMAT;
  return format(dateValue, formatValue);
}

export function proposingCopyName(sets, name, prefix) {
  let newSetName = name;
  let i = 0;

  do {
    i += 1;
    newSetName = `${name} (${prefix} ${i})`;
    // eslint-disable-next-line no-loop-func
  } while (sets.find(set => set.name === newSetName));

  return newSetName;
}

export function getUniqConcepts(sets, selectedIds) {
  let noGenes = false;
  const checkedInitialSetByDefault = sets.length === 1 && selectedIds.length === 0;
  const checkedSets = {};
  const uniqConcepts = {};
  const uniqConceptsIds = [];

  function updateUniqConceptSelected(uniqConcept) {
    if (selectedIds.length > 0) {
      if (selectedIds.includes(uniqConcept.id)) {
        Object.assign(uniqConcept, { selected: true });
      } else {
        Object.assign(uniqConcept, { selected: false });
      }
    } else if (sets.length > 1 && uniqConcept.total === sets.length) {
      Object.assign(uniqConcept, { selected: true });
    } else {
      Object.assign(uniqConcept, { selected: checkedInitialSetByDefault });
    }
  }

  sets.forEach((set) => {
    if (set.active) {
      checkedSets[set.name] = checkedInitialSetByDefault;
    }
  });

  sets.forEach((set) => {
    if (set.active) {
      set.originalData.forEach((concept) => {
        if (!uniqConceptsIds.includes(concept.id)) {
          uniqConceptsIds.push(concept.id);
          const uniqConcept = {
            id: concept.id,
            concept: concept.name,
            type: concept.type || concept.semtypes || concept.types || concept.semanticTypes,
            ...checkedSets,
            [set.name]: true,
            total: 1,
          };
          updateUniqConceptSelected(uniqConcept);
          uniqConcepts[concept.id] = uniqConcept;
        } else {
          const uniqConcept = uniqConcepts[concept.id];
          uniqConcept[set.name] = true;
          uniqConcept.total += 1;
          updateUniqConceptSelected(uniqConcept);
        }
      });
    }

    noGenes = set.originalData.every((concept) => {
      if (concept.semtypes) {
        return concept.semtypes.every(item => item.id !== 'T028');
      }
      return false;
    });
  });

  return { checkedSets, dataSelection: Object.values(uniqConcepts), noGenes };
}

export const toggleSortingDirection = direction => (direction === 'ASC' ? 'DESC' : 'ASC');

export function openSetResultInNewTab(newFullSet) {
  localStorage.setItem('setResultPageData', JSON.stringify(newFullSet));
  window.open(`${RELATIVE_PATH}/set-result-page`, '_blank');
}

export function kFormatter(num) {
  return Math.abs(num) > 999 ?
    `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` :
    Math.sign(num) * Math.abs(num);
}

export function calculatePercent(percent, number) {
  return ((number / percent) * 100).toFixed(2);
}

export function getPathNameWithoutParams(pathName = '') {
  const splitPathName = pathName.split('/');
  if (splitPathName.length > 2) {
    return `/${splitPathName[1]}`;
  }
  return pathName;
}

export function roundNumber(numToRound) {
  if (numToRound > 0) return Math.ceil(numToRound);
  return Math.floor(numToRound);
}

export function normalizeDataById(array) {
  return array.reduce((data, item) => {
    if (data[item.id]) return data;
    return {
      ...data,
      [item.id]: item,
    };
  }, {});
}

export function trimText(name, number) {
  return name.length > number ? `${name.slice(0, number)}...` : `${name}`;
}

export function checkGenmabAccount(username) {
  return new RegExp('@genmab.com$').test(username);
}

export function initScript(url) {
  const script = document.createElement('script');

  script.src = url;
  script.async = false;
  script.type = 'text/javascript';
  document.body.appendChild(script);
}

export function getPercentWidth(width, percent) {
  return (percent * width) / 100;
}

export const truncate = (string, length) => string.length > length ? `${string.substring(0, length)}...` : string;

export function detectBrowser() {
  if (navigator.userAgent.indexOf('Chrome') !== -1 ) {
    return 'Google Chrome';
  }
  else if (navigator.userAgent.indexOf('Firefox') !== -1 ) {
    return 'Mozilla Firefox';
  }
  else if (navigator.userAgent.indexOf('MSIE') !== -1 ) {
    return 'Internet Exploder';
  }
  else if (navigator.userAgent.indexOf('Edge') !== -1 ) {
    return 'Edge';
  }
  else if (navigator.userAgent.indexOf('Safari') !== -1 ) {
    return 'Safari';
  }
  else if (navigator.userAgent.indexOf('Opera') !== -1 ) {
    return 'Opera';
  }
  else {
    return 'Unknown browser';
  }
}

export function getUserLocation() {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      () => resolve(''),
    );
  });
}

function getMatchPath(pathName, location) {
  let path = '';
  let changedPath = '';
  switch (pathName) {
    case '/complex-set': {
      path = '/complex-set/:projectId/:setId';
      changedPath = '/complex-set/{id}';
      break;
    }
    case '/analytics': {
      path = '/analytics/:projectId/:analyticsId';
      changedPath = '/analytics/{id}';
      break;
    }
    case '/gene-details': {
      path = '/gene-details/:conceptId';
      changedPath = '/gene-details/{id}';
      break;
    }
    case '/concept-details': {
      path = '/concept-details/:conceptId';
      changedPath = '/concept-details/{id}';
      break;
    }
    case '/publication-details': {
      path = '/publication-details/:publicationId';
      changedPath = '/publication-details/{id}';
      break;
    }
    case '/gene-disease': {
      path = 'gene-disease/:geneId/:diseaseId';
      changedPath = '/gene-disease/{id}';
      break;
    }
    case '/relation-map': {
      path = '/relation-map/:projectId/:mapId';
      changedPath = '/relation-map/{id}';
      break;
    }
    case '/sets': {
      if (location.includes('/sets/add/')) {
        path = '/sets/add/:projectId';
        changedPath = '/sets/add/{id}';
      } else if (location.includes('/sets/edit/')) {
        path = '/sets/edit/:projectId/:setId';
        changedPath = '/sets/edit/{id}';
      }
      break;
    }
    default: {
      path = pathName;
      changedPath = pathName;
      break;
    }
  }

  return {
    path,
    changedPath,
  };
}

export function checkHubspotTracking(pathname) {
  window._hsq = window._hsq || [];
  const { _hsq } = window;

  const pathNameWithoutParams = getPathNameWithoutParams(pathname);
  const matchedPath = getMatchPath(pathNameWithoutParams, pathname);

  const match = matchPath({ path: matchedPath.path }, pathname);

  const formattedPathName = match ? matchedPath.changedPath : pathNameWithoutParams;
  _hsq.push(['setPath', formattedPathName]);
  _hsq.push(['trackPageView']);
}
