import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import Loader from '../../../../common/Loader/Loader';
import BoxPlotChart from '../../../../graphics/BoxPlotChart/BoxPlotChart';
import StackedBoxPlot from '../../../../graphics/StackedBoxPlot/StackedBoxPlot';
import NoData from '../../../../common/NoData/NoData';
import Error from '../../../../common/Error/Error';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
// Constants
import { samplesChartDataExport } from './constants';
import { graphTypes } from '../../constants';

const propTypes = {
  type: PropTypes.string,
  scale: PropTypes.string,
  tumorLabel: PropTypes.string,
  switchOpenModal: PropTypes.func,
  existedSamplesData: PropTypes.instanceOf(Array),
  samplesData: PropTypes.instanceOf(Array),
  getSamplesData: PropTypes.func,
  clearSamplesData: PropTypes.func,
  tissueGroupName: PropTypes.string,
  tissueCellLineName: PropTypes.string,
  geneId: PropTypes.string,
  transform: PropTypes.string,
  loading: PropTypes.bool,
  geneName: PropTypes.string,
  error: PropTypes.string,
  relatedId: PropTypes.number,
  isSamplesDataExist: PropTypes.bool,
  yAxisText: PropTypes.string,
  sampleMeasure: PropTypes.instanceOf(Object),
  sampleName: PropTypes.instanceOf(Object),
  csvName: PropTypes.string,
  sampleGroupName: PropTypes.string,
  onSampleClick: PropTypes.func,
};

const SamplesChart = (props) => {
  const {
    error,
    type,
    scale,
    geneId,
    loading,
    geneName,
    transform,
    relatedId,
    tumorLabel,
    tissueGroupName,
    tissueCellLineName,
    switchOpenModal,
    existedSamplesData,
    samplesData,
    getSamplesData,
    clearSamplesData,
    yAxisText,
    sampleMeasure,
    sampleName,
    csvName,
    sampleGroupName,
    onSampleClick,
    isSamplesDataExist,
  } = props;

  const graphData = isSamplesDataExist ? existedSamplesData : samplesData;
  const isStackedBoxPlot = type === graphTypes.CPTAC;

  useEffect(() => {
    if (!isSamplesDataExist) {
      getSamplesData({
        type,
        scale,
        geneId,
        tissueGroupName,
        relatedId,
        tumorLabel,
      });
    }
  }, []);

  useEffect(() => () => {
    clearSamplesData();
  }, []);

  const expressionName = type === graphTypes.CCLE || type === graphTypes.CCLE_PROTEOMICS || type === graphTypes.SANGER_CELL_MODEL ?
    `Expression for ${tissueCellLineName}` :
    `Expression in ${tissueGroupName} cell lines for ${tissueCellLineName}`;

  const transformData = (data) => {
    if (transform === 'log') {
      return data.map((item) => {
        if (item.min === undefined && item.max === undefined) {
          return {
            ...item,
            median: +Math.log2(item.median + 1).toFixed(2),
            quartileFirst: +Math.log2(item.quartileFirst + 1).toFixed(2),
            quartileThird: +Math.log2(item.quartileThird + 1).toFixed(2),
          };
        }
        return {
          ...item,
          min: +Math.log2(item.min + 1).toFixed(2),
          median: +Math.log2(item.median + 1).toFixed(2),
          max: +Math.log2(item.max + 1).toFixed(2),
          quartileFirst: +Math.log2(item.quartileFirst + 1).toFixed(2),
          quartileThird: +Math.log2(item.quartileThird + 1).toFixed(2),
        };
      });
    }
    return data;
  };

  const tissueData = useMemo(() => {
    return transformData(graphData);
  }, [graphData]);

  const dataForCsvDownloading = useMemo(() => {
    if (type === graphTypes.CPTAC) return;
    const { key : sampleMeasureKey } = sampleMeasure;
    return tissueData.map(el => ({
      ...el,
      tissue: tissueCellLineName,
      [sampleMeasureKey]: el[sampleMeasureKey].toFixed(2),
    }));
  }, [type, tissueData, sampleMeasure, tissueCellLineName]);

  const csvColumns = useMemo(() => {
    return [
      {
        label: 'TISSUE',
        dataKey: 'tissue',
      },
      {
        label: [sampleName.label],
        dataKey: [sampleName.key],
      },
      {
        label: [sampleMeasure.label],
        dataKey: [sampleMeasure.key],
      },
    ];
  }, [sampleName, sampleMeasure]);

  const handleClickCallback = (id) => {
    onSampleClick({
      isModalOpen: true,
      selectedCluster: id,
    });
  };

  return (
    <>
      {
        !loading &&
        !isStackedBoxPlot &&
        graphData?.length > 0 &&
        <div className="cell-line-diagram">
          <BoxPlotChart
            id="boxplot-chart-beat-aml--cell"
            tissueData={transformData(graphData)}
            sampleMeasureKey={sampleMeasure.key}
            sampleName={sampleName.key}
            classMode="cell-line-boxplot"
            switchOpenModal={switchOpenModal}
            tissueCellLineName={tissueCellLineName}
            expressionName={expressionName}
            boxPlotTransform={transform}
            chartTitle={`${geneName} expression in ${tissueCellLineName}`}
            showTitle={true}
            downloadCsv={samplesChartDataExport[type]}
            type={type}
            csvName={csvName}
            yAxisText={yAxisText}
            sampleGroupName={sampleGroupName}
            dataForCsvDownloading={dataForCsvDownloading}
            csvColumns={csvColumns}
            axisClickCallback={handleClickCallback}
          />
        </div>
      }
      {
        graphData?.length > 0 &&
        type === graphTypes.CPTAC &&
        <StackedBoxPlot
          type={type}
          data={graphData}
          groupsKey="idx"
          geneName={geneName}
          showLegend={true}
          id="stacked-box-plot"
          expressionName={tissueCellLineName}
          chartTitle={`${geneName} expression in ${tissueCellLineName}`}
          subgroups={['Normal/Healthy Tissue', 'Tumor Tissue']}
        />
      }
      <NoData show={!error && !graphData.length} />
      <Error show={!!error} error={error} />
      <Loader isLoading={loading} />
    </>
  );
};

SamplesChart.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    samplesData: SELECTORS.getSamplesData(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSamplesData(data) {
      dispatch(ACTIONS.getSamplesDataAction(data));
    },
    clearSamplesData(data) {
      dispatch(ACTIONS.clearSamplesDataAction(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SamplesChart);
