import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import SaveAsSetForm from './Components/SaveAsSetForm/SaveAsSetForm';
import SaveAsSetContent from './Components/SaveAsSetContent/SaveAsSetContent';
import ConceptSearchModal from '../../Concept/ConceptSearchModal/ConceptSearchModal';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
// Store
import * as s from './store/selectors';
import * as a from './store/actions';
import { getUploadedFileIsComlpexSelector } from '../../common/UploadFile/store/selectors';
// Utils
import { formatConceptsToSave } from './utils';
import { SAVE_AS_SET_FORM } from './constants';
// Styles
import './styles.scss';

const propTypes = {
  concepts: PropTypes.instanceOf(Array),
  getConcepts: PropTypes.func,
  updateConcepts: PropTypes.func,
  save: PropTypes.func,
  reset: PropTypes.func,
  isPopup: PropTypes.bool,
  projectId: PropTypes.string,
  disabled: PropTypes.bool,
  idsForShortConceptDetails: PropTypes.instanceOf(Array),
  getShortConceptDetailsFunction: PropTypes.func,
  getShortConceptDetailsFunctionParams: PropTypes.instanceOf(Object),
  saveAsSetModalClose: PropTypes.func,
  withFileUpload: PropTypes.bool,
  isComplexSet: PropTypes.bool,
  onSavingSet: PropTypes.func,
  uploadOnlySimple: PropTypes.bool,
  defaultValues: PropTypes.instanceOf(Object),
  uploadOnlyByDefaultCategory: PropTypes.bool,
};

const SaveAsSet = (props) => {
  const {
    concepts,
    getConcepts,
    updateConcepts,
    save,
    reset,
    projectId,
    isPopup,
    disabled,
    idsForShortConceptDetails,
    getShortConceptDetailsFunction,
    getShortConceptDetailsFunctionParams,
    saveAsSetModalClose,
    withFileUpload = true,
    isComplexSet,
    onSavingSet,
    uploadOnlySimple,
    defaultValues,
    uploadOnlyByDefaultCategory,
  } = props;
  const { projectId: paramsProjectId } = useParams();
  const [showConceptSearchModal, setShowConceptSearchModal] = useState(false);
  const navigate = useNavigate();

  const project = useMemo(
    () => projectId || paramsProjectId || '',
    [projectId, paramsProjectId]
  );

  const handleSaveSet = useCallback((d) => {
    save({ formData: d.toJS(), isPopup, navigate, onSavingSet });
    if (saveAsSetModalClose) {
      saveAsSetModalClose();
    }
  }, [save, isPopup, navigate, saveAsSetModalClose]);

  const addConcept = useCallback((c) => {
    if (concepts) {
      const concept = formatConceptsToSave(c, concepts.length);
      updateConcepts([...concepts, concept]);
    } else {
      const concept = formatConceptsToSave(c, 0);
      updateConcepts([concept]);
    }
  }, [concepts, updateConcepts]);

  useEffect(() => {
    if (isPopup) {
      if (getShortConceptDetailsFunction) {
        getShortConceptDetailsFunction(getShortConceptDetailsFunctionParams);
      } else if (idsForShortConceptDetails) {
        getConcepts(idsForShortConceptDetails);
      }
    }
    return reset;
  }, []);

  const addSetClassName = classNames({
    'save-as-set': true,
    'save-as-set_page': !isPopup,
    'save-as-set_popup': isPopup,
  });

  return (
    <div className={addSetClassName}>
      <div className="save-as-set__title title2">
        Save as set
      </div>
      <SaveAsSetForm
        defaultValues={defaultValues}
        uploadOnlyByDefaultCategory={uploadOnlyByDefaultCategory}
        uploadOnlySimple={uploadOnlySimple}
        isComplexSet={isComplexSet}
        navigate={navigate}
        onSubmit={handleSaveSet}
        projectId={project}
        setShowConceptSearchModal={setShowConceptSearchModal}
        saveButtonDisabled={disabled}
        withFileUpload={withFileUpload}
      />
      <SaveAsSetContent
        withReset={true}
        disabled={isComplexSet}
        uploadOnlySimple={uploadOnlySimple}
        withFileUpload={withFileUpload}
      />
      <FreeAccountLimitationDialog entity="set" />
      <ConceptSearchModal
        isOpen={showConceptSearchModal}
        closeCb={() => setShowConceptSearchModal(false)}
        onSubmit={addConcept}
        onSubmitBtnText="Select concepts"
        resetOnClose={true}
        formName={SAVE_AS_SET_FORM}
      />
    </div>
  );
};

SaveAsSet.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concepts: s.getSaveAsSetConceptsSelector(state),
    disabled: s.getSaveAsSetDisabledSelector(state),
    isComplexSet: getUploadedFileIsComlpexSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConcepts(data) {
      dispatch(a.getSaveAsSetConceptsAction(data));
    },
    updateConcepts(data) {
      dispatch(a.updateSaveAsSetConceptsAction(data));
    },
    save(data) {
      dispatch(a.saveAsSetRequestedAction(data));
    },
    reset() {
      dispatch(a.resetSaveAsSetDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveAsSet);
