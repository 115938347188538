import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, reset } from 'redux-form/immutable';

// Components
import ConceptSearchField from './ConceptSearchField';
// Constants
import { THEME } from '../../../constantsCommon';
import { CONCEPT_SEARCH_FORM, CONCEPT_SEARCH_FORM_FIELD } from './constants';
// Store
import {setActiveCategoriesAction, setSearchConceptInputValueAction} from '../ConceptSearchModal/store/actions';
// Styles
import './index.scss';

const propTypes = {
  inputValue: PropTypes.string,
  submitting: PropTypes.bool,
  theme: PropTypes.oneOf([THEME.LIGHT, THEME.GREY, THEME.DARK]),
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  setSearchConceptInputValue: PropTypes.func,
  setActiveCategories: PropTypes.func,
  setPortalAction: PropTypes.func,
};

const ConceptSearchForm = (props) => {
  const {
    inputValue,
    submitting,
    theme,
    handleSubmit,
    onSubmitCallback,
    setSearchConceptInputValue,
    setActiveCategories,
    setPortalAction,
  } = props;

  function handleOnSubmit(formValues) {
    const { searchValue } = formValues.toJS();
    setSearchConceptInputValue(searchValue);
    setActiveCategories(null);
    if (setPortalAction) setPortalAction(null);
    onSubmitCallback();
  }

  const formClassName = classNames({
    'concept-search-form': true,
    'concept-search-form_grey': theme === THEME.GREY,
  });

  return (
    <form className={formClassName} onSubmit={handleSubmit(handleOnSubmit)}>
      <ConceptSearchField
        reset={props.reset}
        inputValue={inputValue}
        submitting={submitting}
        onSubmit={handleSubmit(handleOnSubmit)}
      />
    </form>
  );
};

ConceptSearchForm.propTypes = propTypes;

const conceptSearchFormValuesSelector = formValueSelector(CONCEPT_SEARCH_FORM);

function validate(values) {
  const errors = {};
  if (
    !values.get(CONCEPT_SEARCH_FORM_FIELD) ||
    values.get(CONCEPT_SEARCH_FORM_FIELD).trim() === ''
  ) {
    errors.searchValue = 'Enter search condition';
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    inputValue: conceptSearchFormValuesSelector(state, CONCEPT_SEARCH_FORM_FIELD),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearchConceptInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
    setActiveCategories(data) {
      dispatch(setActiveCategoriesAction(data));
    },
  };
}

const onSubmitSuccess = (result, dispatch) =>
  dispatch(reset(CONCEPT_SEARCH_FORM));

const composition = compose(
  reduxForm({
    form: CONCEPT_SEARCH_FORM,
    validate,
    onSubmitSuccess,
    touchOnBlur: false,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(ConceptSearchForm);
