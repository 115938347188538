import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Icons
import {
  AiOutlineExclamationCircle,
  AiFillExclamationCircle,
} from 'react-icons/ai';
// Components
import CreateSetInfo from './Components/CreateSetInfo/CreateSetInfo';
import CreateSetFilter from './Components/CreateSetFilter/CreateSetFilter';
import CreateSetContent from './Components/CreateSetContent/CreateSetContent';
import FreeAccountLimitationDialog from '../../common/FreeAccountLimitationDialog/FreeAccountLimitationDialog';
// Store
import {
  getCreateSetConceptIdsSelector, getCreateSetConceptsSelector,
  getCreateSetLoadingSelector,
  getSaveCreatedSetInputsErrorSelector, getSavedSetInfoSelector
} from './store/selectors';
import { getProjectsOptionsAction } from '../../Projects/ProjectsTabs/store/reducer';
import {
  saveCreatedSetAction,
  resetCreateSetAction,
  getCreateSetAllConceptsAction,
} from './store/actions';
import {
  getSavedSearchTerm,
  getSearchConceptsSelector,
} from '../../Search/store/selectors';
// Constants
import { createSetFilterInitialValues, apiTypes } from './enums';
import {RELATIVE_PATH} from '../../../constantsCommon';
// Styles
import './styles.scss';
import {withRouter} from '../../common/WithRouter/WithRouter';
import {setSelectedSetInfoAction} from '../../FindRelated/Components/EnrichmentAnalysisParams/store/actions';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
import EnrichmentAnalysisParamsModal
  from '../../FindRelated/Components/EnrichmentAnalysisParams/EnrichmentAnalysisParamsModal';

const propTypes = {
  config: PropTypes.instanceOf(Object),
  searchConcepts: PropTypes.instanceOf(Array),
  resetCreateSet: PropTypes.func,
  closePopup: PropTypes.func,
  getCreateSetAllConcepts: PropTypes.func,
  searchTerm: PropTypes.string,
  getConcepts: PropTypes.func,
  conceptsLimit: PropTypes.number,
  conceptsLimitMessage: PropTypes.string,
  initialFilters: PropTypes.instanceOf(Object),
  getProjectsOptions: PropTypes.func,
  saveCreatedSet: PropTypes.func,
  saveCreatedSetInputError: PropTypes.bool,
  pageStyles: PropTypes.bool,
  successMessage: PropTypes.func,
  conceptIds: PropTypes.instanceOf(Array),
  concepts: PropTypes.instanceOf(Array),
  genesOnly: PropTypes.bool,
  loading: PropTypes.bool,
  savedSetInfo: PropTypes.instanceOf(Object),
  navigate: PropTypes.func,
  setSelectedSetInfo: PropTypes.func,
};

const CreateSet = (props) => {
  const {
    config,
    searchTerm,
    closePopup,
    getConcepts,
    conceptsLimit,
    initialFilters,
    resetCreateSet,
    saveCreatedSet,
    searchConcepts,
    getProjectsOptions,
    conceptsLimitMessage,
    getCreateSetAllConcepts,
    saveCreatedSetInputError,
    pageStyles,
    conceptIds,
    concepts,
    genesOnly,
    loading,
    savedSetInfo,
    setSelectedSetInfo,
  } = props;

  const [warning, setWarning] = useState(false);
  const [isOpenSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [isGotoEnrichment, setGotoEnrichment] = useState(false);
  const [isShowEnrichment, setShowEnrichment] = useState(false);

  function renderLimitBlock() {
    if (!conceptsLimit || !conceptsLimitMessage) {
      return null;
    }

    const limitClass = classNames({
      'create-set__limit': true,
      'create-set__limit_error': warning,
    });

    return (
      <div className={limitClass}>
        {
          warning ?
            <AiFillExclamationCircle size={20} color="red" /> :
            <AiOutlineExclamationCircle size={20} color="#4b3f63" />
        }
        <span className="create-set__limit-text">
          {conceptsLimitMessage}
        </span>
      </div>
    );
  }

  function renderSaveBlock() {
    if (getConcepts) return null;

    const saveBtnClass = classNames({
      'button button-primary': true,
      'button-primary--disabled': !saveCreatedSetInputError,
    });

    return (
      <div className="create-set__save">
        <button
          className={saveBtnClass}
          onClick={() => {
            saveCreatedSet(config.apiType);
            if (closePopup) closePopup();
          }}
        >
          Save
        </button>
      </div>
    );
  }

  const handleGetCreateSetAllConcepts = useCallback(() => {
    getCreateSetAllConcepts({
      conceptIds: config.conceptIds,
      apiType: config.apiType,
      semanticType: config.semanticType,
      category: config.category,
      concepts: config.concepts,
      startConcepts: config.startConcepts,
      categoryName: config.categoryName,
      initialFilters,
    });
  }, [config, initialFilters]);

  const setTitle = useMemo(() => {
    const names = config.conceptNames || searchConcepts.map(c => c.name);

    switch (config.apiType) {
      case apiTypes.categoryAnalysisApi: {
        return `All ${config.recommendationName.toLowerCase()} for ${config.conceptDetailsName}`;
      }
      case apiTypes.classificationFullListApi: {
        return `All genes classified as ${config.recommendationName.toLowerCase()}`;
      }
      case apiTypes.filteredFullListApi: {
        return `Related ${config.categoryName.toLowerCase()} to ${names.length ?
          names.join(' AND ') : searchTerm}`;
      }
      case apiTypes.filterWithoutStartConcept: {
        return config.setName;
      }
      case apiTypes.filterWithConcepts: {
        return `Interactions to ${config.geneName}`;
      }
      case apiTypes.targetsForCancer: {
        return `Marker/Target candidates for ${config.diseaseName}`;
      }
      case apiTypes.rankTargets: {
        return `Rank Targets for ${config.diseaseName} (${config.setName})`;
      }
      default:
        return '';
    }
  }, [config, searchConcepts, searchTerm]);

  const conceptNames = useMemo(() => {
    switch (config.apiType) {
      case apiTypes.categoryAnalysisApi: {
        return [config.conceptDetailsName];
      }
      case apiTypes.classificationFullListApi: {
        return [config.recommendationName];
      }
      case apiTypes.filterWithConcepts:
      case apiTypes.filteredFullListApi:
      case apiTypes.filterWithoutStartConcept: {
        return config.conceptNames || searchConcepts.map(c => c.name);
      }
      case apiTypes.targetsForCancer: {
        return [config.diseaseName];//todo check
      }
      default:
        return [];
    }
  }, [config, searchConcepts]);

  const onOpenAsRelationMapClick = () => {
    localStorage.setItem('uniqConcepts', JSON.stringify(conceptIds));
    window.open(`${RELATIVE_PATH}/relation-map/personal/new`, '_blank');
  };

  const onOpenEnrichmentAnalysisClick = () => {
    if (!savedSetInfo.setId) {
      setGotoEnrichment(true);
      saveCreatedSet(config.apiType);
    } else {
      setSelectedSetInfo(savedSetInfo);
      setShowEnrichment(true);
    }
  };

  useEffect(() => {
    if (savedSetInfo.setId && isGotoEnrichment) {
      setGotoEnrichment(false);
      onOpenEnrichmentAnalysisClick();
    }
  }, [savedSetInfo]);

  useEffect(() => {
    getProjectsOptions();
    handleGetCreateSetAllConcepts();
    return resetCreateSet;
  }, []);

  const mainDivClass = classNames({
    'create-set': true,
    'create-set_popup': !pageStyles,
    'create-set_page': pageStyles,
  });
  const sidebarClass = classNames({
    'create-set__sidebar': !pageStyles,
    'create-set__sidebar-page': pageStyles,
  });

  return (
    <div className={mainDivClass}>
      <div className={sidebarClass}>
        <CreateSetFilter
          startDescription={setTitle}
          conceptIds={config.conceptIds}
          categoryName={config.categoryName}
          initialValues={{ ...createSetFilterInitialValues, ...initialFilters }}
          getCreateSetAllConcepts={handleGetCreateSetAllConcepts}
        />
      </div>
      <div className="create-set__content">
        <div className="create-set__header">
          <span className="create-set__title title2">
            {setTitle}
          </span>
          <button
            className={concepts && (concepts.length === 0 || concepts.length > 400) || loading ? 'create-set__relation-map-disabled-icon' : 'create-set__relation-map-icon'}
            disabled={concepts && (concepts.length === 0 || concepts.length > 400) || loading}
            title={concepts && (concepts.length === 0 || concepts.length > 400) ? conceptsLimitMessage : 'Open concepts in relation map'}
            onClick={() => { onOpenAsRelationMapClick(); }}>
          </button>
          <button
            className="create-set__rank-icon"
            // disabled={concepts && (concepts.length === 0 || concepts.length > 400) || loading}
            title="Enrichment analysis of targets"
            onClick={() => { savedSetInfo.setId ? onOpenEnrichmentAnalysisClick() : setOpenSaveConfirmation(true); }}>
          </button>
        </div>
        {renderLimitBlock()}
        <CreateSetContent
          getConcepts={getConcepts}
          withCheckboxes={!!getConcepts}
          conceptIds={config.conceptIds}
          conceptNames={conceptNames}
          setTitle={setTitle}
          closePopup={closePopup}
          conceptsLimit={conceptsLimit}
          setWarning={() => { setWarning(true); }}
          filterWithoutStartConcept={config.apiType === 'filterWithoutStartConcept'}
          genesOnly={genesOnly}
        />
        {
          !getConcepts &&
          <CreateSetInfo
            apiType={config.apiType}
            setTitle={setTitle}
            categoryName={config.categoryName}
            initialFilters={initialFilters}
          />
        }
        {renderSaveBlock()}
        <FreeAccountLimitationDialog entity="set" />
        <ConfirmationDialog
          onConfirm={() => { onOpenEnrichmentAnalysisClick(); }}
          onCancel={() => { setOpenSaveConfirmation(false); }}
          isOpen={isOpenSaveConfirmation}
          closeCb={() => { setOpenSaveConfirmation(false); }}
          confirmBtnText="OK"
          text="Your current results will be saved"
        />
        <EnrichmentAnalysisParamsModal
          isOpen={isShowEnrichment}
          closeCb={() => {setShowEnrichment(false);}}
        />
      </div>
    </div>
  );
};

CreateSet.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    searchTerm: getSavedSearchTerm(state),
    searchConcepts: getSearchConceptsSelector(state),
    saveCreatedSetInputError: getSaveCreatedSetInputsErrorSelector(state),
    conceptIds: getCreateSetConceptIdsSelector(state),
    concepts: getCreateSetConceptsSelector(state),
    loading: getCreateSetLoadingSelector(state),
    savedSetInfo: getSavedSetInfoSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveCreatedSet(data) {
      dispatch(saveCreatedSetAction(data));
    },
    resetCreateSet(data) {
      dispatch(resetCreateSetAction(data));
    },
    getCreateSetAllConcepts(data) {
      dispatch(getCreateSetAllConceptsAction(data));
    },
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    setSelectedSetInfo(data) {
      dispatch(setSelectedSetInfoAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSet));
