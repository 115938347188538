import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Utils
import { apiTypes } from '../../../Sets/CreateSet/enums';

const propTypes = {
  recommendations: PropTypes.instanceOf(Array),
  openCreateSetPopup: PropTypes.func,
};

const GeneClassesSection = (props) => {
  const { recommendations, openCreateSetPopup } = props;

  const handleOpenCreateSetPopup = useCallback(({ id, name }) => {
    openCreateSetPopup({
      conceptIds: [id],
      apiType: apiTypes.classificationFullListApi,
      recommendationName: name,
    });
  }, [openCreateSetPopup]);

  return (
    recommendations.map((item, i) => (
      <div className="search-page__block" key={i}>
        <span className="search-page__text">
          {`${item.recommendationPhrase} `}
        </span>
        {
          item.classes.map((geneClass, geneIndex, array) => (
            geneIndex !== array.length - 1 ?
              (
                <span className="search-page__text" key={geneIndex}>
                  <span
                    className="search-page__link"
                    onClick={() => { handleOpenCreateSetPopup(geneClass); }}
                  >
                    {geneClass.name}
                  </span>{', '}
                </span>
              ) :
              (
                <span
                  key={geneIndex}
                  className="search-page__link"
                  onClick={() => { handleOpenCreateSetPopup(geneClass); }}
                >
                  {geneClass.name}
                </span>
              )
          ))
        }
      </div>
    ))
  );
};

GeneClassesSection.propTypes = propTypes;

export default React.memo(GeneClassesSection);
