import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Error from '../../../../common/Error/Error';
import Loader from '../../../../common/Loader/Loader';
import Accordion from '../../../../common/Accordion/Accordion';
import SemanticType from '../../../../common/SemanticType/SemanticType';
import SemanticCategory from '../../../../common/SemanticCategory/SemanticCategory';
// Store
import * as ACTIONS from '../../store/actions';
import * as SELECTORS from '../../store/selectors';
import { getCerebrumSemanticCategoriesSelector } from '../../../../Header/selectors';
// Constants
import { conceptTypesIdsEnum } from '../../../../../constantsCommon';
// Styles
import './styles.scss';
import classNames from 'classnames';

const propTypes = {
  categories: PropTypes.instanceOf(Array),
  mainCategories: PropTypes.instanceOf(Array),
  getMainCategories: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  setFilters: PropTypes.func,
  resetFilters: PropTypes.func,
  inputValue: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  semanticCategoriesCounts: PropTypes.instanceOf(Object),
  semanticTypesCounts: PropTypes.instanceOf(Object),
  geneTaxonomies: PropTypes.instanceOf(Array),
  activeCategories: PropTypes.instanceOf(Array),
  threePanels: PropTypes.bool,
};

const ConceptsSearchSidebar = (props) => {
  const {
    categories,
    mainCategories,
    getMainCategories,
    filters,
    setFilters,
    resetFilters,
    inputValue,
    error,
    loading,
    semanticCategoriesCounts,
    semanticTypesCounts,
    geneTaxonomies,
    activeCategories,
    threePanels,
  } = props;

  const noInputValue = useMemo(() => !inputValue, [inputValue]);

  const allSemTypesCount = useMemo(() => (
    Object.values(semanticTypesCounts).reduce((acc, curr) => {
      const d = { ...acc, ...curr };
      return d;
    }, {})
  ), [semanticTypesCounts]);

  const toggleSemanticType = useCallback((id, checked) => {
    if (checked) {
      setFilters({ semanticType: null, taxonomy: null });
    } else {
      setFilters({
        semanticType: id,
        semanticCategory: null,
        ...(id !== conceptTypesIdsEnum.GENE && { taxonomy: null } )
      });
    }
  }, [setFilters]);

  const toggleTaxonomy = useCallback((name) => {
    if (!!filters && filters.taxonomy === name) {
      setFilters({ taxonomy: null });
    } else {
      setFilters({
        taxonomy: name,
        semanticCategory: null,
        semanticType: conceptTypesIdsEnum.GENE,
      });
    }
  }, [setFilters, filters]);

  const toggleSemanticCategory = useCallback((name, checked) => {
    setFilters({
      semanticCategory: checked ? null : name,
      taxonomy: null,
      semanticType: null,
    });
  }, [setFilters]);

  useEffect(() => {
    getMainCategories();
    resetFilters();
    if (activeCategories) {
      setFilters({
        semanticCategory: null,
        taxonomy: null,
        semanticType: activeCategories[0],
      });
    }
  }, []);

  const taxonomiesProps = {
    checkedTaxonomy: filters.taxonomy,
    toggleTaxonomy,
    geneTaxonomies,
  };

  const twoOrThrePanelsClass = classNames({
    'concepts-search-sidebar': true,
    'concepts-search-sidebar__3blocks': threePanels,
  });

  return (
    <div className={twoOrThrePanelsClass}>
      <div className="concepts-search-sidebar__title title4">
        I am looking for a
      </div>
      {
        mainCategories &&
        <div className="concepts-search-sidebar__content">
          <div className="concepts-search-sidebar__main">
            {
              mainCategories.map(c => (
                <SemanticType
                  key={c.semanticTypeId}
                  id={c.semanticTypeId}
                  name={c.categoryName}
                  onChange={toggleSemanticType}
                  count={allSemTypesCount[c.semanticTypeId]}
                  checked={filters.semanticType === c.semanticTypeId}
                  disabled={(!noInputValue && !allSemTypesCount[c.semanticTypeId]) || (activeCategories && !activeCategories.includes(c.semanticTypeId))}
                  {...(c.semanticTypeId === conceptTypesIdsEnum.GENE ? taxonomiesProps : {})}
                />
              ))
            }
          </div>
          <Accordion
            title={`Other categories (${Object.values(semanticCategoriesCounts).reduce((a, b) => a + b, 0)})`}
            customClassName="concepts-search-sidebar__accordion"
          >
            <div className="concepts-search-sidebar__other">
              {
                categories.map(c => (
                  <SemanticCategory
                    key={c.name}
                    {...c}
                    onChange={toggleSemanticCategory}
                    onSTchange={toggleSemanticType}
                    count={semanticCategoriesCounts[c.name]}
                    checked={filters.semanticCategory === c.name}
                    disabled={!noInputValue && !semanticCategoriesCounts[c.name] || (activeCategories && !activeCategories.includes(c.semanticTypeId))}
                    allSemTypesCount={allSemTypesCount}
                    selectedSemanticType={filters.semanticType}
                    taxonomiesProps={taxonomiesProps}
                    noInputValue={noInputValue}
                  />
                ))
              }
            </div>
          </Accordion>
        </div>
      }
      <Loader isLoading={loading} />
      <Error show={!!error} error={error} />
    </div>
  );
};

ConceptsSearchSidebar.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    categories: getCerebrumSemanticCategoriesSelector(state),
    mainCategories: SELECTORS.getConceptsSearchCategoriesSelector(state),
    loading: SELECTORS.getConceptsSearchCategoriesLoadingSelector(state),
    error: SELECTORS.getConceptsSearchCategoriesErrorSelector(state),
    semanticCategoriesCounts: SELECTORS.getConceptsSearchSemCategoriesCountsSelector(state),
    semanticTypesCounts: SELECTORS.getConceptsSearchSemTypesCountsSelector(state),
    geneTaxonomies: SELECTORS.getGeneTaxonomiesSelector(state),
    activeCategories: SELECTORS.getActiveCategoriesSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters(data) {
      dispatch(ACTIONS.setSearchConceptFiltersAction(data));
    },
    resetFilters() {
      dispatch(ACTIONS.resetSearchConceptFiltersAction());
    },
    getMainCategories() {
      dispatch(ACTIONS.getConceptSearchCategoriesAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConceptsSearchSidebar);
