import { all } from 'redux-saga/effects';

import restorePassword from './components/auth/RestorePassword/saga';
import changePassword from './components/auth/ChangePassword/saga';
import setNewPassword from './components/auth/SetNewPassword/store/saga';
import activateCreatedUserSaga from './components/auth/ActivateCreatedUser/store/saga';
import authSaga from './components/auth/saga';
import TwoFactorAuthentication from './components/auth/TwoFactorAuthentication/store/saga';
import headerSaga from './components/Header/saga';
import sets from './components/Sets/SetsList/saga/watchers';
import setsManagement from './components/Sets/SetsManagement/saga/watchers';
import editSet from './components/Sets/EditSet/store/saga';
import setAnalysisMethodSelection from './components/Analytics/common/SetAnalysisMethodSelection/saga';
import findRelatedAnalysisResultWatcher from './components/Analytics/FindRelated/FindRelatedResult/saga';
import findRelatedProteinOrRegulationAnalysisWatcher from './components/Analytics/FindRelated/FindRelatedProteinOrRegulationResult/saga';
import networkAnalysis from './components/Analytics/NetworkAnalysis/SetAnalysisNetworkAnalysisResult/saga';
import validateScoringFormula from './components/Analytics/RankSelection/RankingMarkerSettings/saga';
import rankingAnalysis from './components/Analytics/RankSelection/SetAnalysisRankingResult/saga';
import searchSaga from './components/Search/store/saga';
import conceptDetailsSaga from './components/Concept/ConceptDetails/saga';
import publicationDetailsSaga from './components/PublicationDetails/saga/watchers';
import genesDetail from './components/common/SetAnalysisGenesDetail/saga';
import barChart from './components/graphics/BarChart/store/saga';
import relationMapManagement from './components/RelationMap/RelationMapManagement/store/saga';
import resultSet from './components/SetResult/SetResultPage/saga';
import setAnalysisManagementSaga from './components/Analytics/SetAnalysisManagement/saga';
import setAnalysisAnalytics from './components/Analytics/common/SetAnalysis/saga';
import fetchTaxonomiesSaga from './components/Sets/Taxonomies/saga';
import setResultPageAddConcepts from './components/SetResult/SetResultPageAddConcepts/saga';
import activationSaga from './components/auth/UserActivation/saga';
import geneDiseaseSaga from './components/GeneDisease/GeneDiseasePage/saga';
import geneDiseaseAnnotationSaga from './components/GeneDisease/GeneDiseaseAnnotationSection/saga';
import differentialExpressionSaga from './components/GeneDisease/GeneDiseaseDifferentialExpressionSection/saga';
import geneDiseaseGeneMutationSaga from './components/GeneDisease/GeneDiseaseGeneMutationSection/saga';
import geneDiseaseAnimalModelSaga from './components/GeneDisease/GeneDiseaseAnimalModelSection/saga';
import geneDiseaseMetabolitesSaga from './components/GeneDisease/GeneDiseaseMetabolitesSection/saga';
import geneDiseasePhenotypeSaga from './components/GeneDisease/GeneDiseasePhenotypeSection/saga';
import geneDiseasePathwaySaga from './components/GeneDisease/GeneDiseasePathwaySection/saga';
import geneDiseaseCellMolecularSaga from './components/GeneDisease/GeneDiseaseCellMollecularDysfunction/saga';
import geneDiseaseOrganTissueSaga from './components/GeneDisease/GeneDiseaseOrganTissueFunction/saga';
import geneDiseaseConclusionSaga from './components/GeneDisease/GeneDiseaseConclusion/saga';
import complexSetViewSaga from './components/Sets/ComplexSetView/saga';
import pathDetails from './components/Analytics/NetworkAnalysis/PathDetails/saga';
import pathDetailsSmallMolecules from './components/Analytics/NetworkAnalysis/PathDetails/SmallMoleculesSection/saga';
import publicationDetailsTriplesSaga from './components/PublicationDetails/Components/PublicationDetailsTriples/saga';
import addTagSaga from './components/common/AddTag/store/saga';
import watchPublicationsList from './components/common/PublicationsList/store/saga';
import locationSaga from './location/locationSaga';
import shortConceptCardSaga from './components/Concept/ShortConceptCard/saga';
import searchCategoryTabsSaga from './components/Search/Components/SearchCategoryTab/store/saga';
import conceptCardsSaga from './components/Concept/ConceptCards/store/saga';
import createSetSaga from './components/Sets/CreateSet/store/saga/watchers';
import duplicateSetSaga from './components/Sets/ComplexSetSettingsModal/saga';
import addConceptSaga from './components/common/AddConcept/saga';
import setVennDiagramSaga from './components/Sets/SetVennDiagram/saga/watcher';
import compareSetsSaga from './components/Sets/CompareSetsPage/store/saga';
import heatmapSaga from './components/graphics/Heatmap/store/saga';
import newOptionSetSaga from './components/Sets/BuildHeatmap/store/saga';
import projectsManagementSaga from './components/Projects/ProjectsManagement/store/saga';
import projectsTabsSaga from './components/Projects/ProjectsTabs/store/saga';
import publicationsManagementSaga from './components/Publications/PublicationsManagement/store/saga';
import selectProjectModalSaga from './components/Modals/SelectProjectModal/store/saga';
import selectGeneSaga from './components/common/SelectGene/store/saga';
import selectDiseaseSaga from './components/common/SelectDisease/store/saga';
import searchConceptSaga from './components/Concept/ConceptSearchModal/store/saga';
import publicationsFilterGraphSaga from './components/graphics/PublicationsFilterGraph/store/saga';
// Gene Details
import geneDetailSaga from './components/GeneDetails/saga';
import geneDetailProteinStructureSaga from './components/GeneDetails/ProteinStructure/redux/saga/watchers';
import geneDetailsExpressionChapterSaga from './components/GeneDetails/GeneExpressionChapter/saga/watchers';
import proteinLocationChapterSaga from './components/GeneDetails/ProteinLocationChapter/saga';
import geneDetailsLiteratureSearchSaga from './components/GeneDetails/LiteratureSearchChapter/saga';
import geneDetailsFunctionalCharacterizationChapterSaga from './components/GeneDetails/FunctionalCharacterizationChapter/redux/saga/saga';
import geneDetailPathwaysSaga from './components/GeneDetails/PathwaysChapter/store/saga/watchers';
import geneDetailSimilarProteinsSaga from './components/GeneDetails/SimilarProteinsChapter/redux/saga/watchers';
import geneDetailsOrthologsSaga from './components/GeneDetails/OrthologsChapter/store/saga';
import geneDetailPhenotypesSaga from './components/GeneDetails/PhenotypesChapter/redux/saga/watchers';
import geneDetailTherapeuticCandidatesSaga from './components/GeneDetails/TherapeuticCandidatesChapter/redux/saga/watchers';
import geneDetailPatentsSaga from './components/GeneDetails/Patents/redux/saga/watchers';
import geneDetailsConditionsOfDysregulationChapterSaga from './components/GeneDetails/ConditionsOfDysregulationChapter/redux/saga/saga';
import geneDetailsClinicalTrialsChapterSaga from './components/GeneDetails/ClinicalTrialsChapter/redux/saga/saga';
import geneDetailsDisordersChapterSaga from './components/GeneDetails/DisordersChapter/redux/saga/watchers';
import samplesDataSaga from './components/GeneDetails/GeneExpressionChapter/Charts/SamplesChart/store/saga';
import cellTypeClusterSaga from './components/GeneDetails/GeneExpressionChapter/Charts/HpaCharts/components/CellTypeClusterModal/store/saga';
import antibodiesSaga from './components/GeneDetails/AntibodiesChapter/store/saga';
import geneDetailsExternalLinksChapterSaga from './components/GeneDetails/ExternalLinksChapter/store/saga';
import geneDetailsInternalLinksChapterSaga from './components/GeneDetails/InternalLinksChapter/store/saga';
// Relation Map
import relationMapProject from './components/RelationMap/RelationMapPage/store/saga';
import intermediateConceptsSaga from './components/RelationMap/RelationMapPage/Components/IntermediateConceptsModal/store/saga';
// Sets
import saveAsSetSaga from './components/Sets/SaveAsSet/store/saga';
import unresolvedConceptsSaga from './components/Sets/SaveAsSet/Components/UnresolvedConceptsTable/store/saga';
import searchWithSetsPageSaga from './components/Sets/SearchWithSetsPage/store/saga';
import findRelatedSaga from './components/FindRelated/store/saga';
import rankSelectionSaga from './components/RankSelection/store/saga';
import rankSelectionResultSaga from './components/RankSelection/Components/RankSelectionResult/saga';
// Workflows
import workflowsSaga from './components/Workflows/store/saga/watchers';
import bispecificAntibodySaga from './components/Workflows/Algorithms/BispecificAntibody/store/saga/watchers';
import singleTargetForCancerSaga from './components/Workflows/Algorithms/SingleTargetForCancer/saga/watchers';
import singleCellStudySaga from './components/Workflows/Algorithms/SingleCellStudyViewer/store/saga';
import cellLineSelectionSaga from './components/Workflows/Algorithms/CellLineSelection/store/saga';
// Indication Diseases Finder
import indicationDiseasesFinderSaga from './components/Workflows/Algorithms/IndicationFinder/Components/ResultDiseaseBlock/store/saga';
// Upload
import uploadFileSaga from './components/common/UploadFile/store/saga';
//Association score
import conceptToConceptIndirectSaga from './components/common/ConceptToConceptIndirectChart/store/saga';

function* rootSaga() {
  try {
    yield all([
      restorePassword(),
      authSaga(),
      changePassword(),
      activateCreatedUserSaga(),
      TwoFactorAuthentication(),
      headerSaga(),
      setNewPassword(),
      findRelatedAnalysisResultWatcher(),
      findRelatedProteinOrRegulationAnalysisWatcher(),
      rankingAnalysis(),
      networkAnalysis(),
      setAnalysisMethodSelection(),
      validateScoringFormula(),
      searchSaga(),
      conceptDetailsSaga(),
      publicationDetailsSaga(),
      genesDetail(),
      barChart(),
      resultSet(),
      setResultPageAddConcepts(),
      setAnalysisManagementSaga(),
      setAnalysisAnalytics(),
      fetchTaxonomiesSaga(),
      activationSaga(),
      differentialExpressionSaga(),
      complexSetViewSaga(),
      pathDetails(),
      pathDetailsSmallMolecules(),
      publicationDetailsTriplesSaga(),
      addTagSaga(),
      watchPublicationsList(),
      locationSaga(),
      shortConceptCardSaga(),
      searchCategoryTabsSaga(),
      conceptCardsSaga(),
      addConceptSaga(),
      setVennDiagramSaga(),
      heatmapSaga(),
      newOptionSetSaga(),
      projectsManagementSaga(),
      projectsTabsSaga(),
      publicationsManagementSaga(),
      selectProjectModalSaga(),
      selectGeneSaga(),
      selectDiseaseSaga(),
      publicationsFilterGraphSaga(),
      searchConceptSaga(),
      geneDiseaseGeneMutationSaga(),
      // Gene Disease
      geneDiseaseSaga(),
      geneDiseaseAnnotationSaga(),
      geneDiseaseAnimalModelSaga(),
      geneDiseaseMetabolitesSaga(),
      geneDiseasePhenotypeSaga(),
      geneDiseasePathwaySaga(),
      geneDiseaseCellMolecularSaga(),
      geneDiseaseOrganTissueSaga(),
      geneDiseaseConclusionSaga(),
      // Gene Details
      geneDetailSaga(),
      geneDetailProteinStructureSaga(),
      geneDetailsExpressionChapterSaga(),
      proteinLocationChapterSaga(),
      geneDetailsFunctionalCharacterizationChapterSaga(),
      geneDetailPathwaysSaga(),
      geneDetailSimilarProteinsSaga(),
      geneDetailsOrthologsSaga(),
      geneDetailPhenotypesSaga(),
      geneDetailsConditionsOfDysregulationChapterSaga(),
      geneDetailTherapeuticCandidatesSaga(),
      geneDetailPatentsSaga(),
      geneDetailsClinicalTrialsChapterSaga(),
      geneDetailsDisordersChapterSaga(),
      geneDetailsLiteratureSearchSaga(),
      geneDetailsExternalLinksChapterSaga(),
      samplesDataSaga(),
      cellTypeClusterSaga(),
      antibodiesSaga(),
      geneDetailsInternalLinksChapterSaga(),
      // Relation Map
      relationMapManagement(),
      relationMapProject(),
      intermediateConceptsSaga(),
      // Sets
      sets(),
      setsManagement(),
      editSet(),
      createSetSaga(),
      duplicateSetSaga(),
      compareSetsSaga(),
      saveAsSetSaga(),
      unresolvedConceptsSaga(),
      searchWithSetsPageSaga(),
      findRelatedSaga(),
      rankSelectionSaga(),
      rankSelectionResultSaga(),
      // Workflows
      workflowsSaga(),
      bispecificAntibodySaga(),
      singleTargetForCancerSaga(),
      singleCellStudySaga(),
      cellLineSelectionSaga(),
      // Indication Diseases Finder
      indicationDiseasesFinderSaga(),
      // Upload
      uploadFileSaga(),
      //Association score
      conceptToConceptIndirectSaga(),
    ]);
  } catch (error) {
    console.log(error);
  }
}

export default rootSaga;
