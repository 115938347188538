import {
  SORT,
  CHECK_ALL,
  CHECK_ITEM,
  INVERT_SELECTION,
  REMOVE_SELECTED,
} from '../../common/SimpleTable/constants';

import {
  RESULT_SET_REQUESTED,
  SAVE_SET_FOR_SET_RESULT,
  SET_RESULT_PAGE_TABLE,
  SAVE_PAIR_OF_CONCEPTS,
  SAVE_CONCEPT_AND_CATEGORY,
  SET_RESULT_RESET,
  LOADING,
  SET_A_OPEN_MENU,
  SET_A_CLOSE_MENU,
  SET_B_OPEN_MENU,
  SET_B_CLOSE_MENU,
  SET_C_OPEN_MENU,
  SET_C_CLOSE_MENU,
  SHORT_CONCEPTS_DETAILS_REQUESTED,
  RESET_SET_RESULT_PRIMARY_SETTINGS,
  SET_NEW_FULL_SET,
  SET_CONFIRM_ADD_CONCEPT_POPUP_OPEN_KEY,
  GO_TO_SET_RESULT_PAGE,
  SET_CURRENT_SET_ID,
  SET_PREVIOUS_SET_ID,
  EXPORT_ALL_TO_CSV,
  SET_RESULT_PAGE_SAVE_COLUMN_AS_SET,
} from './constants';

export const saveColumnAsSetAction = data => (
  {
    type: SET_RESULT_PAGE_SAVE_COLUMN_AS_SET,
    data,
  }
);

export const exportAllToCSVAction = data => (
  {
    type: EXPORT_ALL_TO_CSV,
    data,
  }
);

export const setPreviousSetIdForSetResultAction = data => (
  {
    type: SET_PREVIOUS_SET_ID,
    data,
  }
);

export const setCurrentSetIdForSetResultAction = data => (
  {
    type: SET_CURRENT_SET_ID,
    data,
  }
);

export const goToSetResultSetAction = data => (
  {
    type: GO_TO_SET_RESULT_PAGE,
    data,
  }
);

export const initSetResult = data => (
  {
    type: RESULT_SET_REQUESTED,
    data,
  }
);

export const saveSetForSetResult = data => (
  {
    type: SAVE_SET_FOR_SET_RESULT,
    data,
  }
);

export const savePairOfConcepts = data => (
  {
    type: SAVE_PAIR_OF_CONCEPTS,
    data,
  }
);

export const saveConceptAndCategory = data => (
  {
    type: SAVE_CONCEPT_AND_CATEGORY,
    data,
  }
);

export const checkAll = data => (
  {
    type: SET_RESULT_PAGE_TABLE + CHECK_ALL,
    data,
  }
);

export const checkItem = data => (
  {
    type: SET_RESULT_PAGE_TABLE + CHECK_ITEM,
    data,
  }
);

export const invertSelection = () => (
  {
    type: SET_RESULT_PAGE_TABLE + INVERT_SELECTION,
  }
);

export const removeSelected = () => (
  {
    type: SET_RESULT_PAGE_TABLE + REMOVE_SELECTED,
  }
);

export const sort = data => (
  {
    type: SET_RESULT_PAGE_TABLE + SORT,
    data,
  }
);

export const loading = data => (
  {
    type: SET_RESULT_PAGE_TABLE + LOADING,
    data,
  }
);

export const setResultReset = () => (
  {
    type: SET_RESULT_RESET,
  }
);

export const setAOpenMenu = () => (
  {
    type: SET_A_OPEN_MENU,
  }
);

export const setACloseMenu = () => (
  {
    type: SET_A_CLOSE_MENU,
  }
);

export const setBOpenMenu = () => (
  {
    type: SET_B_OPEN_MENU,
  }
);

export const setBCloseMenu = () => (
  {
    type: SET_B_CLOSE_MENU,
  }
);

export const setCOpenMenu = () => (
  {
    type: SET_C_OPEN_MENU,
  }
);

export const setCCloseMenu = () => (
  {
    type: SET_C_CLOSE_MENU,
  }
);

export const getShortConceptsDetails = data => (
  {
    type: SHORT_CONCEPTS_DETAILS_REQUESTED,
    data,
  }
);

export const resetSetResultPrimarySettings = () => (
  {
    type: RESET_SET_RESULT_PRIMARY_SETTINGS,
  }
);

export const setNewFullSet = data => (
  {
    type: SET_NEW_FULL_SET,
    data,
  }
);

export const toggleConfirmAddLinkedConceptPopup = data => (
  {
    type: SET_CONFIRM_ADD_CONCEPT_POPUP_OPEN_KEY,
    data,
  }
);
