import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// Components
import Error from '../../../common/Error/Error';
import Loader from '../../../common/Loader/Loader';
import CreateSet from '../../../Sets/CreateSet/CreateSet';
import ConceptDetails from '../../../Concept/ConceptDetails/ConceptDetails';
import ModalComponent from '../../../ModalComponent/ModalComponent';
import PublicationsList from '../../../common/PublicationsList/PublicationsList';
import SimpleTable from '../../../common/SimpleTable/SimpleTable';
import ShortConceptCardCell from '../../../Concept/ShortConceptCard/ShortConceptCardCell';
import RelationDetailsModal
  from '../../../RelationMap/RelationMapPage/Components/RelationDetailsModal/RelationDetailsModal';
import ExportTable from '../../../common/ExportTable/ExportTable';
// Utils
import {
  convertTableToCSVText,
  exportToFile,
  getScreenWidth,
  openSetResultInNewTab,
} from '../../../Utils/Utils';
import { RELATIVE_PATH } from '../../../../constantsCommon';
import { getColumnName } from './SetAnalysisRankingResultTableColumns';
// Store
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import { getLiteratureSearchTerms } from '../LiteratureSearchTerms/selectors';
import { setAnalysisSets } from '../../common/SetAnalysis/selectors';
import { getSelectedRankingConcept } from '../../common/SetAnalysisMethodSelection/selectors';
import { getGeneVariationTerm } from '../GeneVariationSettings/selectors';
import { openBarChartAction } from '../../../graphics/BarChart/store/actions';
import { setResultReset } from '../../../SetResult/SetResultPage/actions';
import {
  currentPage,
  tableSorting,
  getTotalPages,
  getTotalItems,
  getSelectedItems,
  getLoadingKey,
  getPageNumber,
  getError,
  getEffectOperation,
} from './selectors';
import {
  sort,
  checkAll,
  checkItem,
  invertSelection,
  removeSelected,
  removeConcept,
  requestRanking,
  reset,
  loading,
  setEffectOperationAction,
} from './actions';
import { effectOperation } from './constants';
// Styles
import './SetAnalysisRankingResult.css';

const propTypes = {
  removeConcept: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Array),
  page: PropTypes.instanceOf(Array),
  changePage: PropTypes.func,
  conceptsToAnalyse: PropTypes.instanceOf(Array),
  totalPages: PropTypes.number,
  totalItems: PropTypes.number,
  pageNumber: PropTypes.number,
  sorting: PropTypes.instanceOf(Object),
  loadingKey: PropTypes.bool,
  error: PropTypes.string,
  createHeaderRendererWithSaveAs: PropTypes.func,
  literatureSearchTerms: PropTypes.instanceOf(Array),
  openBarChart: PropTypes.func,
  sets: PropTypes.instanceOf(Array),
  geneVariationTerm: PropTypes.instanceOf(Object),
  effectOperationValue: PropTypes.string,
  setEffectOperation: PropTypes.func,
  columnSort: PropTypes.func,
};

class SetAnalysisRankingResult extends React.Component {
  actions = {
    checkAll,
    checkItem,
    invertSelection,
    removeSelected,
  };
  state = {
    showPublicationsPopup: false,
    conceptDetails: {
      show: false,
      id: null,
    },
    filterSetPopup: {
      show: false,
      config: null,
    },
    relationDetailsPopup: {
      show: false,
      config: null,
    },
    indicationsPopup: {
      show: false,
      data: {},
    },
  };
  conceptsNames = [];
  conceptsIds = [];
  simpleTableRef = React.createRef(null);

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  openPublicationsPopup = () => {
    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.setState({ showPublicationsPopup: false });
  };

  removeConcept = (id) => {
    this.props.removeConcept(id);
  };

  exportToCSV = (selected, columns) => {
    const dataForExport = selected ? this.props.selectedItems : this.props.page;
    const result = convertTableToCSVText(dataForExport, columns.filter(column => (
      column.exportCSV
    )));
    exportToFile(this.downloadLink, result, 'table-export');
  };

  showConceptDetails = (id) => {
    this.setState({
      conceptDetails: {
        show: true,
        id,
      },
    });
  };

  openFilteringPopup = (element, data) => {
    const conceptsIds = data.geneticInteractionsSetResultsData.map(el => el.nodeA);
    const category = element.semtypes[0].id === 'T028' ? 'Genes' : '';
    const filterSetPopupConfig = {
      apiType: 'filterWithConcepts',
      conceptIds: [data.geneGi],
      concepts: conceptsIds,
      geneName: data.geneName,
      category,
      categoryName: category,
    };
    this.setState({
      filterSetPopup: {
        show: true,
        config: filterSetPopupConfig,
      },
    });
  };

  closeFilterSetPopup = () => {
    this.setState({
      filterSetPopup: {
        show: false,
        config: null,
      },
    });
  };

  closeConseptDetails = () => {
    this.setState({
      conceptDetails: {
        show: false,
        id: null,
      },
    });
  };

  openRelationDetailsPopup = (data) => {
    const { geneVariationTerm } = this.props;
    const { geneGi, geneName } = data;
    const { id, name } = geneVariationTerm;

    this.setState({
      relationDetailsPopup: {
        show: true,
        config: {
          conceptIds: [geneGi, id],
          conceptNames: [geneName, name],
          hideRelationsDetailsInfo: true,
        },
      },
    });
  };

  closeRelationDetailsPopup = () => {
    this.setState({
      relationDetailsPopup: {
        show: false,
        config: null,
      },
    });
  };

  openIndicationsPopup = (data) => {
    this.setState({
      indicationsPopup: {
        show: true,
        data,
      },
    });
  };

  closeIndicationsPopup = () => {
    this.setState({
      indicationsPopup: {
        show: false,
        data: {},
      },
    });
  };

  /* Pagination switcher */
  showHideAllConcepts = () => {
    this.props.changePage({
      concepts: this.props.conceptsToAnalyse.map(concept => (
        concept.id
      )),
      params: {
        size: this.props.totalPages === 1 ? 20 : this.props.totalItems,
        page: 0,
      },
    });
  };

  handlePageClick = (pageNumber) => {
    this.props.changePage({
      concepts: this.props.conceptsToAnalyse.map(concept => (
        concept.id
      )),
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  getRowHeight = ({ index }) => {
    let itemsCount = 0;
    try {
      if (this.props.page[index].classifications) {
        itemsCount = this.props.page[index].classifications.length;
      }
    } catch (e) {
      console.log(e);
    }
    if (itemsCount > 2) {
      return itemsCount * 20 + 10; //eslint-disable-line
    }
    return 50;
  };

  goToConceptDetails = (id, semanticCategory) => {
    if (semanticCategory === 'Genes & Molecular Sequences') {
      window.open(`${RELATIVE_PATH}/gene-details/${id}`, '_blank');
    } else {
      this.showConceptDetails(id);
    }
  };

  openBarChartDiagram = (data) => {
    const { openBarChart } = this.props;
    openBarChart(data);
  };

  genesCell = ({ id, name }, rowIndex, semanticCategory) => {
    const link = semanticCategory === 'Genes & Molecular Sequences' ?
      `${RELATIVE_PATH}/gene-details/${id}` :
      `${RELATIVE_PATH}/concept-details/${id}`;
    const uniqueKey = `tooltip-${id}-${rowIndex}`;
    return (
      <ShortConceptCardCell
        id={id}
        link={link}
        uniqueKey={uniqueKey}
        name={name}
      />
    );
  };

  addEffectSetScoresColumns = (columns, sets, page) => {
    const effectColumns = [];
    sets.forEach((set, index) => {
      if ((set.type === 'EFFECT' || set.setType === 'EFFECT')
        && page.some(element => element[`set${index + 1}`])) {
        const columnSetting = {
          label: set.name,
          dataKey: `set${index + 1}`,
          exportCSV: true,
          className: 'flexColumn',
          width: 100,
          cellRenderer: ({ rowData }) => (
            rowData[`set${index + 1}`]
          ),
        };

        effectColumns.push(`set${index + 1}`);
        columns.push(columnSetting);
      }
    });

    if (effectColumns.length > 1) {
      const columnSetting = {
        label: 'Operation',
        dataKey: 'effectAggregation',
        exportCSV: true,
        className: 'flexColumn',
        width: 200,
        disableSort: true,
        headerRenderer: ({ dataKey, sortBy, sortDirection }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            active: sortBy === dataKey && sortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            active: sortBy === dataKey && sortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <Dropdown
                onChange={e => this.props.setEffectOperation(this.props.page, e.value)}
                value={this.props.effectOperationValue}
                options={[effectOperation.Sum, effectOperation.Min, effectOperation.Max,
                  effectOperation.Average, effectOperation.Median]}
              />
              <span className="sorting-section">
                {
                  sortDirection === 'DESC' ?
                    <i
                      role="presentation"
                      className={ascClasses}
                      onClick={() => {
                        this.props.columnSort({ sortBy: dataKey, sortDirection: 'ASC' });
                      }}
                    />
                    :
                    <i
                      role="presentation"
                      className={descClasses}
                      onClick={() => {
                        this.props.columnSort({ sortBy: dataKey, sortDirection: 'DESC' });
                      }}
                    />
                }
              </span>
            </div>
          );
        },
      };

      columns.push(columnSetting);
    }
  };

  render() {
    const {
      page,
      totalPages,
      pageNumber,
      sorting: { sortBy, sortDirection },
      selectedItems,
      loadingKey,
      error,
      sets,
      createHeaderRendererWithSaveAs,
    } = this.props;
    const {
      showPublicationsPopup,
      conceptDetails,
      filterSetPopup,
      relationDetailsPopup,
      indicationsPopup,
    } = this.state;

    let originalDataArray = [];
    sets.forEach(set => originalDataArray.push(...set.originalData));
    originalDataArray = originalDataArray.filter(el => originalDataArray.find(e => e.id !== el.id));
    const element = originalDataArray[0];
    const columns = [];
    let associationScoreTerms = [];
    const publicationSearchTerms = [];
    let totalTableLength = 50; // Default first column
    const screenWidth = getScreenWidth();

    const indicationsPopupContent = () => {
      const { data } = indicationsPopup;
      const indicationsKeys = Object.keys(data);
      return indicationsKeys.map((key, i) => (
        <span
          className="indications-popup__item"
          key={i}
        >
          {key}
        </span>
      ));
    };

    // Process Indirect association score columns
    if (page[0] && page[0].associationScore) {
      associationScoreTerms = Object.keys(page[0].associationScore);
      page.map((item) => {
        Object.assign(item, item.associationScore);
        return item;
      });
    }

    // Process lit search columns
    if (page[0] && page[0].literatureSearchData) {
      page[0].literatureSearchData.forEach((litSearchItem) => {
        publicationSearchTerms.push(`litSearchItem.${litSearchItem.synonym.name}`);
      });
      page.map((item) => {
        item.literatureSearchData.forEach((litSearchItem) => {
          // eslint-disable-next-line no-param-reassign
          item[`litSearchItem.${litSearchItem.synonym.name}`] = litSearchItem;
        });
        return item;
      });
    }


    let columnsNames = Object.keys(page[0] || {});

    const columnsToRemove = ['geneName', 'classifications', 'associationScore', 'tripleIds', 'compoundScoreSetResultsData',
      'geneticInteractionsSetResultsData', 'involvementOtherDiseasesSetResultsData',
      'associationScoreSetResultsData', 'semanticCategory', 'literatureSearchData', 'differentialExpressionExperimentId',
      'targetValidation', 'count', 'mostFrequentIndications', 'rankingTrialStage',
      'set1', 'set2', 'set3', 'set4', 'set5', 'set6', 'effectAggregation'];
    columnsNames = columnsNames.filter(columnName => !columnsToRemove.includes(columnName));

    columnsNames.unshift('classifications');
    columnsNames.unshift('geneName');

    if (page[0] && page[0].targetValidation) {
      columnsNames.push(...['rankingTrialStage', 'count', 'mostFrequentIndications']);
    }

    this.addEffectSetScoresColumns(columns, sets, page);

    columnsNames.forEach((columnName) => {
      // skip this data into table view
      if (columnName !== 'id' && columnName !== 'geneGi' && columnName !== 'selected') {
        const columnWidth = columnName === 'geneName' ? //eslint-disable-line
          300 : (columnName === 'classifications') ? //eslint-disable-line
            375 : 200; //eslint-disable-line

        totalTableLength += columnWidth;

        const columnHeader = columnName.startsWith('litSearchItem.') ? columnName.substring(14) : columnName;

        const columnSetting = {
          label: getColumnName(columnHeader, associationScoreTerms.indexOf(columnName) !== -1),
          dataKey: columnName,
          width: columnWidth,
          disableSort: false,
          exportCSV: true,
        };

        if (associationScoreTerms.indexOf(columnName) !== -1) {
          Object.assign(columnSetting, {
            className: 'flexColumn',
            width: 100,
            sortPath: ['associationScore', `${columnName}`],
            cellRenderer: ({ rowData, dataKey }) => {
              const classes = classNames({
                link: rowData[columnName] > 0,
              });
              return (
                <span
                  role="presentation"
                  onClick={() => {
                    if (rowData[columnName] > 0) {
                      openSetResultInNewTab(rowData.associationScoreSetResultsData[dataKey]);
                    }
                  }}
                  className={classes}
                >
                  {rowData[columnName]}
                </span>
              );
            },
          });
        }
        if (publicationSearchTerms.indexOf(columnName) !== -1) {
          Object.assign(columnSetting, {
            csvRenderer: rowData => rowData[columnName].literatureSearch,
            className: 'flexColumn',
            width: 100,
            sortBy: columnName,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData[columnName].literatureSearch > 0,
              });
              return (
                <span
                  role="presentation"
                  onClick={() => {
                    if (rowData[columnName].literatureSearch > 0) {
                      this.conceptsNames = [
                        rowData.geneName,
                        rowData[columnName].synonym.name,
                      ];
                      this.conceptsIds =
                        [
                          rowData.geneGi,
                          rowData[columnName].synonym.id,
                        ];
                      this.openPublicationsPopup();
                    }
                  }}
                  className={classes}
                >
                  {rowData[columnName].literatureSearch}
                </span>
              );
            },
          });
        }
        if (columnName === 'classifications') {
          Object.assign(columnSetting, {
            className: 'flexColumn',
            width: 450,
            sortValueMapper: (rowData) => {
              const items = rowData.get(columnName) || [];
              return items.toArray().length
                ? items.toArray()[0]
                : '';
            },
            cellRenderer: ({ rowData }) => {
              const items = rowData[columnName] || [];
              return items.map((item, index) => (
                <div
                  title={item}
                  className="text-center cell-row"
                  key={index}
                >
                  {item}
                </div>
              ));
            },
          });
        }
        if (columnName === 'geneName') {
          Object.assign(columnSetting, {
            headerRenderer: createHeaderRendererWithSaveAs ? createHeaderRendererWithSaveAs(page) : undefined,
            cellRenderer: ({ rowData, rowIndex }) => (
              rowData ?
                this.genesCell({ id: rowData.geneGi, name: rowData.geneName }, rowIndex, rowData.semanticCategory) :
                null
            ),
          });
        }
        if (columnName === 'geneticInteractions') {
          Object.assign(columnSetting, {
            width: 100,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData[columnName] > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData[columnName] > 0) {
                      this.openFilteringPopup(element, rowData);
                    }
                  }}
                >
                  {rowData[columnName]}
                </span>
              );
            },
          });
        }
        if (columnName === 'geneVariation') {
          Object.assign(columnSetting, {
            width: 100,
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData[columnName] > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData[columnName] > 0) {
                      this.openRelationDetailsPopup(rowData);
                    }
                  }}
                >
                  {rowData[columnName]}
                </span>
              );
            },
          });
        }
        if (columnName === 'differentialExpression') {
          Object.assign(columnSetting, {
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.differentialExpression !== 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.differentialExpression !== 0) {
                      this.goToConceptDetails(rowData.differentialExpressionExperimentId);
                    }
                  }}
                >
                  {rowData.differentialExpression}
                </span>
              );
            },
          });
        }
        if (columnName === 'literatureSearch') {
          Object.assign(columnSetting, {
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.literatureSearch > 0,
              });
              const { literatureSearchTerms } = this.props;
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.literatureSearch > 0) {
                      this.conceptsNames = [
                        rowData.geneName,
                        ...literatureSearchTerms.map(item => item.name),
                      ];
                      this.conceptsIds =
                        [
                          rowData.geneGi,
                          ...literatureSearchTerms.map(item => item.id),
                        ];
                      this.openPublicationsPopup();
                    }
                  }}
                >
                  {rowData.literatureSearch}
                </span>
              );
            },
          });
        }
        if (columnName === 'involvementOtherDiseases') {
          Object.assign(columnSetting, {
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.involvementOtherDiseases > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.involvementOtherDiseases > 0) {
                      openSetResultInNewTab(rowData.involvementOtherDiseasesSetResultsData);
                    }
                  }}
                >
                  {rowData.involvementOtherDiseases}
                </span>
              );
            },
          });
        }
        if (columnName === 'compoundScore') {
          Object.assign(columnSetting, {
            cellRenderer: ({ rowData }) => {
              const classes = classNames({
                link: rowData.compoundScore > 0,
              });
              return (
                <span
                  role="presentation"
                  className={classes}
                  onClick={() => {
                    if (rowData.compoundScore > 0) {
                      openSetResultInNewTab(rowData.compoundScoreSetResultsData);
                    }
                  }}
                >
                  {rowData.compoundScore}
                </span>
              );
            },
          });
        }
        if (columnName === 'expressionSpecificity') {
          Object.assign(columnSetting, {
            cellRenderer: ({ rowData }) => (
              <span
                role="presentation"
                className="link"
                onClick={() => { this.openBarChartDiagram(rowData); }}
              >
                {rowData.expressionSpecificity}
              </span>
            ),
          });
        }
        if (columnName === 'druggableStructure') {
          Object.assign(columnSetting, {
            cellRenderer: ({ rowData }) => (
              rowData && rowData.druggableStructure > 0 ?
                'yes' :
                'no'
            ),
          });
        }
        if (columnName === 'rankingTrialStage') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Max clinical phase',
            width: 200,
            cellRenderer: ({ rowData }) => (
              <div
                title={rowData[columnName]}
                className="text-center cell-row"
              >
                {rowData[columnName]}
              </div>
            ),
          });
        }
        if (columnName === 'count') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Number of trial in max phase',
            width: 150,
            cellRenderer: ({ rowData }) => (
              <div
                title={rowData[columnName]}
                className="text-center cell-row"
              >
                {rowData[columnName]}
              </div>
            ),
          });
        }
        if (columnName === 'mostFrequentIndications') {
          Object.assign(columnSetting, {
            dataKey: columnName,
            className: 'flexColumn',
            label: 'Most frequent indication',
            width: 300,
            cellRenderer: ({ rowData }) => {
              const indications = rowData[columnName];
              const indicationsKeys = Object.keys(indications);
              const indicationsCount = indicationsKeys.length;
              let cell;
              if (indicationsCount <= 5) {
                cell = (indicationsKeys.map((key, i) => (
                  <div
                    key={i}
                    className="text-center cell-row"
                  >
                    {`${key} : ${indications[key]}`}
                  </div>
                )));
              } else {
                cell = (
                  <div
                    className="text-center link"
                    onClick={() => { this.openIndicationsPopup(indications); }}
                  >
                    {indicationsCount}
                  </div>
                );
              }
              return cell;
            },
          });
        }
        columns.push(columnSetting);
      }
    });

    columns.push({
      dataKey: 'id',
      disableSort: true,
      width: 75,
      cellRenderer: ({ rowData }) => (
        <div className="text-center">
          <button
            type="button"
            onClick={() => { this.removeConcept(rowData.id); }}
            className="btn-icon btn-icon-danger"
            title="Remove"
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      ),
    });
    // last column width
    totalTableLength += 75;

    const containerStyle = {
      width: screenWidth > totalTableLength ? totalTableLength : screenWidth - 40,
    };

    let tableSettings = {
      width: totalTableLength,
      headerHeight: 60,
      rowHeight: this.getRowHeight,
      sortBy,
      sortDirection,
    };

    if (page.length < 10) {
      tableSettings = Object.assign({}, tableSettings, { autoHeight: true });
    }

    return (
      <div className="setAnalysisRankingResult">
        <div className="analysis-title mt-20 title2">Ranking analysis</div>
        {
          !loadingKey &&
          <div>
            <div
              style={containerStyle}
              className="flex-grid justify-content-center ranking-result-top-container"
            >
              <div className="col-1" />
              <div className="col-1">
                <div className="paginationContainer">
                  {
                    totalPages > 1 &&
                    <ReactPaginate
                      previousLabel="previous"
                      nextLabel="next"
                      breakClassName="break-me"
                      pageCount={totalPages}
                      forcePage={pageNumber}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  }
                </div>
              </div>
              <div className="col-1 table-additional-controls">
                <ExportTable
                  content={page}
                  columns={columns}
                  showExportAll={false}
                  showExportPage={true}
                  showExportSelected={true}
                  selectedItems={selectedItems}
                  fileName="ranking_analysis"
                />
                <button
                  disabled={!totalPages}
                  className="button button-primary ml-15"
                  onClick={() => this.showHideAllConcepts()}
                >
                  {totalPages === 1 ? <span>Show pages</span> : <span>Show all</span>}
                </button>
              </div>
            </div>
            <SimpleTable
              innerRef={this.simpleTableRef}
              settings={tableSettings}
              data={page}
              manageable={true}
              actions={this.actions}
              sortAction={sort}
              columns={columns}
              dynamicHeight={true}
            />
          </div>
        }
        <Loader isLoading={loadingKey && !error} />
        <Error show={!!error} error={error} />
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
            />
          </ModalComponent>
        }
        {
          conceptDetails.show &&
          <ModalComponent
            isOpen={conceptDetails.show}
            closeCb={this.closeConseptDetails}
          >
            <ConceptDetails
              conceptId={conceptDetails.id}
              isPopup={true}
            />
          </ModalComponent>
        }
        {
          filterSetPopup.show &&
          <ModalComponent
            isOpen={filterSetPopup.show}
            closeCb={this.closeFilterSetPopup}
            modalClassName="modal_no-paddings"
          >
            <CreateSet
              config={filterSetPopup.config}
              closePopup={this.closeFilterSetPopup}
            />
          </ModalComponent>
        }
        {
          relationDetailsPopup.show &&
          <RelationDetailsModal
            isOpen={relationDetailsPopup.show}
            closeCb={this.closeRelationDetailsPopup}
            {...relationDetailsPopup.config}
          />
        }
        {
          indicationsPopup.show &&
          <ModalComponent
            isOpen={indicationsPopup.show}
            closeCb={this.closeIndicationsPopup}
          >
            <div className="indications-popup">
              <div className="indications-popup__title">Most frequent indication</div>
              <div className="indications-popup__wrap">
                {indicationsPopupContent()}
              </div>
            </div>
          </ModalComponent>
        }
      </div>
    );
  }
}

SetAnalysisRankingResult.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    page: currentPage(state),
    pageNumber: getPageNumber(state),
    totalPages: getTotalPages(state),
    totalItems: getTotalItems(state),
    selectedItems: getSelectedItems(state),
    sorting: tableSorting(state),
    conceptsToAnalyse: selectedDataForAnalysis(state),
    loadingKey: getLoadingKey(state),
    error: getError(state),
    diseaseConcept: getSelectedRankingConcept(state),
    literatureSearchTerms: getLiteratureSearchTerms(state),
    sets: setAnalysisSets(state),
    geneVariationTerm: getGeneVariationTerm(state),
    effectOperationValue: getEffectOperation(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeConcept(id) {
      dispatch(removeConcept(id));
    },
    changePage(data) {
      dispatch(requestRanking(data));
    },
    reset() {
      dispatch(reset());
    },
    loading() {
      dispatch(loading());
    },
    openBarChart(data) {
      dispatch(openBarChartAction(data));
    },
    setResultReset() {
      dispatch(setResultReset());
    },
    setEffectOperation(page, operation) {
      dispatch(setEffectOperationAction({ page, operation }));
    },
    columnSort(data) {
      dispatch(sort(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAnalysisRankingResult);
