import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, AutoSizer } from 'react-virtualized';
import classNames from 'classnames';

// Icons
import { FiDownload } from 'react-icons/fi';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
// Components
import Error from '../../../../common/Error/Error';
import Loader from '../../../../common/Loader/Loader';
import NoData from '../../../../common/NoData/NoData';
import Checkbox from '../../../../common/Inputs/Checkbox/Checkbox';
import ButtonCircle from '../../../../common/Buttons/ButtonCircle/ButtonCircle';
import ModalComponent from '../../../../ModalComponent/ModalComponent';
import PublicationsList from '../../../../common/PublicationsList/PublicationsList';
// Utils
import { createSetSortingTypes } from '../../enums';
import { convertTableToCSVText, exportToFile, toggleSortingDirection } from '../../../../Utils/Utils';
// Store
import {
  getCreateSetConceptsSelector,
  getCreateSetLoadingSelector,
  getCreateSetErrorSelector,
  getCreateSetSortingSelector,
  getCreateSetPubFiltersDataSelector,
  getCreateSetFilterCooccurrenceValue,
  getCreateSetPublicationSourceValueSelector,
} from '../../store/selectors';
import { setCreateSetConceptsAction, sortCreateSetConceptsAction } from '../../store/actions';
// Styles
import './styles.scss';
import {RELATIVE_PATH} from '../../../../../constantsCommon';

const propTypes = {
  concepts: PropTypes.instanceOf(Array),
  conceptIds: PropTypes.instanceOf(Array),
  conceptNames: PropTypes.instanceOf(Array),
  pubFilters: PropTypes.instanceOf(Object),
  sorting: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  closePopup: PropTypes.func,
  setTitle: PropTypes.string,
  setWarning: PropTypes.func,
  getConcepts: PropTypes.func,
  sortConcepts: PropTypes.func,
  withCheckboxes: PropTypes.bool,
  conceptsLimit: PropTypes.number,
  setCreateSetConcepts: PropTypes.func,
  filterWithoutStartConcept: PropTypes.bool,
  createSetPublicationSourceValue: PropTypes.string,
  genesOnly: PropTypes.bool,
};

class CreateSetContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPublicationsPopup: false,
    };
    this.conceptsNames = [];
    this.conceptsIds = [];
  }

  updateList = () => {
    if (this.conceptsList) {
      this.conceptsList.forceUpdateGrid();
    }
  };

  toggleConcept = (index) => {
    const { concepts, setCreateSetConcepts } = this.props;
    concepts[index].selected = !concepts[index].selected;
    setCreateSetConcepts(concepts);
    this.updateList();
  };

  toggleAllConcepts = (allSelected) => {
    const { concepts, setCreateSetConcepts } = this.props;
    const sConcepts = concepts.map(c => ({
      ...c,
      selected: !allSelected,
    }));
    setCreateSetConcepts(sConcepts);
    this.updateList();
  };

  sortConcepts = (sortBy) => {
    const { sorting, sortConcepts } = this.props;
    sortConcepts({
      sortBy,
      sortDirection: toggleSortingDirection(sorting.sortDirection),
    });
    this.updateList();
  };

  getSelectedConcepts = () => {
    const {
      concepts,
      closePopup,
      setWarning,
      getConcepts,
      conceptsLimit,
    } = this.props;
    const selectedConceptsIds = concepts.reduce((result, c) => {
      if (c.selected) {
        result.push(c.id);
      }
      return result;
    }, []);

    if (selectedConceptsIds.length > conceptsLimit) {
      setWarning();
    } else {
      getConcepts(selectedConceptsIds);
      if (closePopup)
        closePopup();
    }
  };

  openGeneDetails = (id) => {
    window.open(`${RELATIVE_PATH}/gene-details/${id}`, '_blank');
  };

  rowRenderer = ({ index, style }) => {
    const { concepts, withCheckboxes, genesOnly } = this.props;
    const concept = concepts[index];

    return (
      <div
        key={concept.id}
        style={style}
        className="create-set-concepts__item"
      >
        <div className="create-set-concepts__item-wrap">
          {
            withCheckboxes &&
            <Checkbox
              id={`${concept.id}`}
              checked={concept.selected}
              onChange={() => {
                this.toggleConcept(index);
              }}
            />
          }
          {
            genesOnly ?
              <span
                className="create-set-concepts__name create-set-concepts__link"
                onClick={() => { this.openGeneDetails(concept.id); }}
              >
                {concept.name}
              </span>
              :
              <span className="create-set-concepts__name">
                {concept.name}
              </span>
          }
          <span
            className="create-set-concepts__count"
            onClick={() => {
              if (concept.count > 0) {
                this.showPublicationsPopup(concept.name, concept.id);
              }
            }}
          >
            {concept.count}
          </span>
          {
            this.props.conceptIds && this.props.conceptIds.length > 1 &&
            <span
              className="create-set-concepts__item-relation-map"
              onClick={() => {
                if (concept.count > 0) {
                  this.showPublicationsPopup(concept.name, concept.id);
                }
              }}
            >
            </span>
          }
        </div>
      </div>
    );
  };

  showPublicationsPopup = (conceptName, conceptId) => {
    const {
      conceptIds,
      conceptNames,
      filterWithoutStartConcept,
    } = this.props;

    if (filterWithoutStartConcept) {
      this.conceptsIds.push(conceptId);
      this.conceptsNames.push(conceptName);
    } else {
      this.conceptsIds = [...conceptIds, conceptId];
      this.conceptsNames = [...conceptNames, conceptName];
    }

    this.setState({ showPublicationsPopup: true });
  };

  closePublicationsPopup = () => {
    this.conceptsIds = [];
    this.conceptsNames = [];
    this.setState({ showPublicationsPopup: false });
  };

  exportToCsv = () => {
    const { concepts, setTitle } = this.props;
    const columns = [
      {
        label: 'Name',
        dataKey: 'name',
      },
      {
        label: 'References',
        dataKey: 'count',
      },
    ];

    if (concepts && concepts.length) {
      const result = convertTableToCSVText(concepts, columns);
      exportToFile(this.downloadLink, result, setTitle);
    }
  };

  render() {
    const {
      error,
      loading,
      concepts,
      pubFilters,
      withCheckboxes,
      createSetPublicationSourceValue,
      sorting: { sortBy, sortDirection },
    } = this.props;

    const { showPublicationsPopup } = this.state;

    let conceptsCount = null;
    let allSelected = false;
    let selectBtnClass = 'button button-primary button-primary--disabled';

    if (!loading && concepts && concepts.length > 0) {
      conceptsCount = `Results: ${concepts.length}`;

      if (withCheckboxes) {
        allSelected = concepts.every(c => c.selected === true);
        selectBtnClass = classNames(
          'button button-primary',
          { 'button-primary--disabled': !concepts.find(c => c.selected === true) },
        );
      }
    }

    return (
      <div className="create-set-content">
        <div className="title-border">
          <span>Set Result</span>
        </div>
        <div className="create-set-content__header">
          <div className="create-set-content__block">
            {
              withCheckboxes &&
              <Checkbox
                id="create-set-checkbox"
                checked={allSelected}
                onChange={() => { this.toggleAllConcepts(allSelected); }}
                disabled={!concepts || concepts.length === 0}
              />
            }
            <span>Name</span>
            <ButtonCircle
              icon={
                sortBy === createSetSortingTypes.NAME && sortDirection === 'ASC' ?
                  <BiSortUp size={16} color="#4b3f63" /> :
                  <BiSortDown size={16} color="#4b3f63" />
              }
              onClick={() => { this.sortConcepts(createSetSortingTypes.NAME); }}
            />
          </div>
          {
            conceptsCount &&
            <div className="create-set-content__block create-set-content__block_center">
              <span>{conceptsCount}</span>
              <ButtonCircle
                icon={<FiDownload size={16} color="#4b3f63" />}
                onClick={this.exportToCsv}
              />
            </div>
          }
          <div className="create-set-content__block create-set-content__block_right">
            <span>References</span>
            <ButtonCircle
              icon={
                sortBy === createSetSortingTypes.COUNT && sortDirection === 'ASC' ?
                  <BiSortUp size={16} color="#4b3f63" /> :
                  <BiSortDown size={16} color="#4b3f63" />
              }
              onClick={() => { this.sortConcepts(createSetSortingTypes.COUNT); }}
            />
          </div>
        </div>
        <div className="create-set-concepts">
          {
            !loading && !error && concepts && concepts.length > 0 &&
            <AutoSizer disableHeight={true}>
              {({ width }) => (
                <List
                  ref={(ref) => { this.conceptsList = ref; }}
                  width={width}
                  height={withCheckboxes ? 470 : 280}
                  rowCount={concepts.length}
                  rowHeight={40}
                  rowRenderer={this.rowRenderer}
                />
              )}
            </AutoSizer>
          }
          <Loader isLoading={loading} />
          <NoData
            show={!loading && !error && concepts && concepts.length === 0}
            customClassName="create-set-concepts__no-data"
          />
          <Error
            show={!loading && error}
            error={error}
          />
        </div>
        {
          withCheckboxes &&
          <div className="create-set-info__save">
            <button
              className={selectBtnClass}
              onClick={this.getSelectedConcepts}
            >
              Select Concepts
            </button>
          </div>
        }
        {
          showPublicationsPopup &&
          <ModalComponent
            isOpen={showPublicationsPopup}
            closeCb={this.closePublicationsPopup}
          >
            <PublicationsList
              names={this.conceptsNames}
              ids={this.conceptsIds}
              filters={pubFilters}
              cooccurrenceType={createSetPublicationSourceValue}
            />
          </ModalComponent>
        }
        <a //eslint-disable-line
          hidden={true}
          ref={(ref) => { this.downloadLink = ref; }}
        />
      </div>
    );
  }
}

CreateSetContent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    concepts: getCreateSetConceptsSelector(state),
    loading: getCreateSetLoadingSelector(state),
    error: getCreateSetErrorSelector(state),
    pubFilters: getCreateSetPubFiltersDataSelector(state),
    sorting: getCreateSetSortingSelector(state),
    filterCooccurrenceValue: getCreateSetFilterCooccurrenceValue(state),
    createSetPublicationSourceValue: getCreateSetPublicationSourceValueSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCreateSetConcepts(data) {
      dispatch(setCreateSetConceptsAction(data));
    },
    sortConcepts(data) {
      dispatch(sortCreateSetConceptsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSetContent);
