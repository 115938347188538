import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import Spinner from '../../common/Spinner/Spinner';
import WordCloud from '../WordCloud/WordCloud';
import CreateSet from '../../Sets/CreateSet/CreateSet';
import ModalComponent from '../../ModalComponent/ModalComponent';
import CollapsibleTreeDiagram from '../CollapsibleTreeDiagram/CollapsibleTreeDiagram';
import SetAnalysisGenesDetail from '../../common/SetAnalysisGenesDetail/SetAnalysisGenesDetail';
import BilevelPartitionDiagram from '../../graphics/BilevelPartitionDiagram/BilevelPartitionDiagram';
// Utils
import { apiTypes } from '../../Sets/CreateSet/enums';
import { RELATIVE_PATH } from '../../../constantsCommon';
import {
  isEmptyObject,
  scrollIntoView,
  insertHTML,
} from '../../Utils/Utils';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Store
import { setNewFullSet } from '../../SetResult/SetResultPage/actions';
import { closeGenesDetail } from '../../common/SetAnalysisGenesDetail/actions';
import { getOpenKey as getOpenGeneDeatailsKey } from '../../common/SetAnalysisGenesDetail/selectors';
import {
  conceptDetailsRequest,
  loading,
  conceptCategoryAnalysisRequest,
  conceptCategoryAnalysisReset,
  conceptCategoryAnalysisNodesRequest,
  resetAction,
  getShortConceptsDetails,
  conceptDetailsSetLastVisitedConceptIdAction,
} from './actions';
import {
  getConceptDetails,
  getLoadingKey,
  getError,
  getCategoryAnalysisData,
  getCategoryAnalysisLoading,
  getCategoryAnalysisError,
  getCategoryAnalysisNodesCurrentPages,
  getCategoryAnalysisNodesTotalPages,
  getCategoryAnalysisNodesTotalItems,
  getCategoryAnalysisNodesLoading,
  getCategoryAnalysisNodesError,
  getLastVisitedConceptId,
} from './selectors';
// Store
import './ConceptDetails.css';

const propTypes = {
  closeGenesDetail: PropTypes.func,
  concept: PropTypes.instanceOf(Object),
  conceptCategoryAnalysisData: PropTypes.instanceOf(Object),
  reset: PropTypes.func,
  categoryAnalysisReset: PropTypes.func,
  conceptDetailsRequest: PropTypes.func,
  conceptId: PropTypes.number,
  loading: PropTypes.func,
  categoryAnalysisRequest: PropTypes.func,
  conceptCategoryAnalysisNodesRequest: PropTypes.func,
  loadingKey: PropTypes.bool,
  error: PropTypes.string,
  conceptCategoryAnalysisLoadingKey: PropTypes.bool,
  conceptCategoryAnalysisError: PropTypes.string,
  openGeneDetails: PropTypes.bool,
  setLastVisitedConceptId: PropTypes.func,
  lastVisitedConceptId: PropTypes.string,
  isPopup: PropTypes.bool,
  navigate: PropTypes.func,
  locationParams: PropTypes.instanceOf(Object),
};

class ConceptDetails extends React.Component {
  constructor(props) {
    super(props);
    this.shouldLoadCategoryAnalysis = true;
    this.state = {
      modalToShow: '',
      createSetPopup: {
        show: false,
        config: null,
      },
    };
  }

  componentDidMount() {
    const conceptId = this.props.conceptId ? this.props.conceptId : this.props.locationParams.conceptId;
    if (conceptId !== this.props.lastVisitedConceptId) {
      this.props.reset();
      this.props.setLastVisitedConceptId(conceptId);
      this.props.categoryAnalysisReset();
      this.props.conceptDetailsRequest(conceptId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const conceptId = this.props.locationParams.conceptId || this.props.conceptId;
    const nextConceptId = nextProps.locationParams.conceptId || nextProps.conceptId;

    if (conceptId !== nextConceptId) {
      this.props.reset();
      this.props.categoryAnalysisReset();
      this.props.conceptDetailsRequest(nextConceptId);
    }
    if (this.props.concept.id !== nextProps.concept.id) {
      this.shouldLoadCategoryAnalysis = true;
    }
  }


  handleGenesDetailClose = () => this.props.closeGenesDetail();

  publicationDetails = (id) => {
    this.props.navigate(`${RELATIVE_PATH}/publication-details/${id}`);
  };

  componentDidUpdate(prevProps) {
    if (
      this.isPageScrollable() &&
      isEmptyObject(this.props.conceptCategoryAnalysisData) &&
      isEmptyObject(prevProps.concept) &&
      !isEmptyObject(this.props.concept)
    ) {
      this.categoryAnalysis();
      scrollIntoView('.concept-name');
    }
  }

  getScrollHeight = () => (
    (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight
  );

  getScrollTop = () => (
    (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
  );

  getClientHeight = () => (
    (document.documentElement && document.documentElement.clientHeight) || document.body.clientHeight
  );

  isPageScrollable = () => {
    const scrollHeight = this.getScrollHeight();
    const clientHeigth = this.getClientHeight();
    return scrollHeight > clientHeigth;
  };

  conceptDetails = (id, isGene) => {
    const detailsPath = isGene ? 'gene-details' : 'concept-details';
    if (!isGene) this.props.loading();
    this.props.categoryAnalysisReset();
    this.props.navigate(`${RELATIVE_PATH}/${detailsPath}/${id}`);
  };

  initWordCloud = (wordCloudData) => {
    if (wordCloudData && wordCloudData instanceof Array) {
      const words = [];
      wordCloudData.forEach((item) => {
        item.concepts.forEach((concept) => {
          words.push({
            name: concept.name,
            score: concept.score,
            color: item.category.color,
            id: concept.id,
            categoryName: item.category.name,
          });
        });
      });
      return words;
    }
    return [];
  };

  openCreateSetPopup = (semanticType) => {
    const { concept } = this.props;
    const { id, name } = semanticType;
    this.setState({
      createSetPopup: {
        show: true,
        config: {
          conceptIds: [concept.id],
          recommendationName: name,
          semanticType: id,
          apiType: apiTypes.categoryAnalysisApi,
          conceptDetailsName: concept.name,
        },
      },
    });
  };

  closeCreateSetPopup = () => {
    this.setState({
      createSetPopup: {
        show: false,
        config: null,
      },
    });
  };

  categoryAnalysis = () => {
    if (this.shouldLoadCategoryAnalysis && this.props.concept.id) {
      this.props.categoryAnalysisReset();
      this.props.categoryAnalysisRequest(this.props.concept.id);
      this.shouldLoadCategoryAnalysis = false;
    }
    scrollIntoView('#conceptCategoryAnalysis');
  };

  handlePageClick = (pageNumber) => {
    this.props.conceptCategoryAnalysisNodesRequest({
      params: {
        size: 20,
        page: pageNumber.selected,
      },
    });
  };

  imgErrorCheck = (ev) => {
    // eslint-disable-next-line no-param-reassign
    ev.target.style = 'display: none';
    // eslint-disable-next-line no-param-reassign
    ev.target.src = '';
  };

  renderConceptImageBlock = () => {
    const { concept: { image } } = this.props;
    if (!image) return null;
    const imgUrl = image.type === 'IMAGE' ?
      `data:image/png;base64,${image.content}` :
      image.content;

    return (
      <img
        src={imgUrl}
        alt="conceptImage"
        onError={this.imgErrorCheck}
        className="concept-details-card-image"
      />
    );
  };

  render() {
    const {
      concept,
      loadingKey,
      error,
      conceptCategoryAnalysisData,
      conceptCategoryAnalysisLoadingKey,
      conceptCategoryAnalysisError,
      openGeneDetails,
      isPopup,
    } = this.props;

    const { createSetPopup } = this.state;

    const semanticTypes = concept.semanticTypes ? concept.semanticTypes.map((type, index, array) => (
      <span key={index}>
        { index === 0 && ', ' }{ type.name }{ index !== (array.length - 1) && ', '}
      </span>
    )) : '';

    const classifications = concept.classifications ? concept.classifications.map((classification, index, array) => (
      <span key={index}>
        { index === 0 && ', ' }{ classification.name }{ index !== (array.length - 1) && ', '}
      </span>
    )) : '';

    const synonyms = concept.synonyms ? concept.synonyms.slice(0, 15).map((synonym, index, array) => (
      <span key={index}>
        { synonym }{ index !== (array.length - 1) && '; '}
      </span>
    )) : '';

    const measures = concept.measures ? concept.measures.map((measure, index) => (
      <div key={index} className="flex-grid concept-details-card-measure-block">
        <div className="col-1 text-right">{measure.key}:</div>
        <div title={measure.value} className="col-1 text-left concept-details-card-measure-value">{measure.value}</div>
      </div>
    )) : '';

    const sourceUrl = concept.sourceUrl ? (
      <div>
        <div className="concept-definition-title">Source:</div>
        <a
          target="_blank"
          href={concept.sourceUrl} rel="noreferrer"
        >
          {concept.sourceUrl}
        </a>
      </div>) : '';

    const words = this.initWordCloud(concept.wordCloud);
    const neighbours = concept.contextNeighbours;
    const latestPublication = concept.latestPublication || {};

    const componentClass = classNames('concept-details', {
      'concept-details_popup': isPopup,
    });

    return (
      <div className={componentClass}>
        {
          !loadingKey && !error &&
          <div className="flex-grid align-self-center concept-details-container">
            <div className="col-1 text-center concept-details-left-column concept-details-columns">
              <div className="concept-details-columns-sidebar">
                <div className="concept-details-left-column-title title2">
                  Actions
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#conceptDefinition'); }}
                  className="concept-details-left-column-action"
                >
                  Definition
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#conceptPublications'); }}
                  className="concept-details-left-column-action"
                >
                  Recent publications
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#conceptContext'); }}
                  className="concept-details-left-column-action"
                >
                  Context
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#keyAspects'); }}
                  className="concept-details-left-column-action"
                >
                  Key aspects
                </div>
                <div
                  role="presentation"
                  onClick={() => { scrollIntoView('#conceptCategoryAnalysis'); }}
                  className="concept-details-left-column-action"
                >
                  Select related sets
                </div>
              </div>
            </div>
            <div className="col-6 concept-details-columns concept-details-right-column">
              <div className="concept-name">{ concept.name }</div>
              <div className="concept-details-separator" />
              <div className="concept-category">
                <span>{ concept.semanticCategory }</span>
                { semanticTypes }
                { classifications }
              </div>
              <div className="flex-grid">
                <div className="col-4">
                  <div id="conceptDefinition" className="concept-definition">
                    {
                      concept.definition &&
                      <div className="concept-definition-title">Definition:</div>
                    }
                    <div dangerouslySetInnerHTML={insertHTML(concept.definition)} />
                    {
                      concept.relatedGenes &&
                      <div >
                        <b>Related genes: </b>
                        {
                          concept.relatedGenes.map(gene => (
                            <Link
                              key={`related-gene-${gene.id}`}
                              to={`${RELATIVE_PATH}/gene-details/${gene.id}`}
                            >
                              {gene.name}
                            </Link>
                          ))
                        }
                      </div>
                    }
                    { sourceUrl }
                    <div className="concept-word-cloud">
                      {
                        words.length > 0 &&
                        <WordCloud
                          words={words}
                          height={400}
                          width={600}
                        />
                      }
                    </div>
                  </div>
                  {
                    !isEmptyObject(latestPublication) &&
                    <div id="conceptPublications" className="concept-publications">
                      <div className="concept-publications-title">Recent publications:</div>
                      <div className="concept-details-separator concept-details-separator-small" />
                      <div className="concept-publication">
                        <div
                          role="presentation"
                          className="concept-publication-title title3"
                          onClick={() => { this.publicationDetails(latestPublication.gi); }}
                          dangerouslySetInnerHTML={insertHTML(latestPublication.title)}
                        />
                        <div
                          className="concept-publication-description"
                          dangerouslySetInnerHTML={insertHTML(latestPublication.description)}
                        />
                      </div>
                    </div>
                  }
                  {
                    neighbours &&
                    <div id="conceptContext" className="concept-context">
                      <div className="concept-context-title">Context:</div>
                      <div className="concept-details-separator concept-details-separator-small" />
                      <div className="concept-context-diagram">
                        <CollapsibleTreeDiagram
                          data={neighbours}
                          height={480}
                          width={900}
                        />
                      </div>
                    </div>
                  }
                  {
                    concept.wordCloud.length > 0 &&
                    <div id="keyAspects" className="concept-key-aspects">
                      <div className="concept-key-aspects-title">Key aspects:</div>
                      <div className="concept-details-separator concept-details-separator-small" />
                      <div className="concept-key-aspects-data">
                        <div className="concept-key-aspects-data__content flex-grid">
                          {
                            concept.wordCloud.map((word, index) => (
                              <div key={index} className="col-1 concept-key-aspect">
                                <div className="concept-key-aspect-category title5">{ word.category.name }</div>
                                {
                                  word.concepts.map(wordConcept => (
                                    <div
                                      role="presentation"
                                      key={wordConcept.id}
                                      onClick={() => { this.conceptDetails(wordConcept.id, word.category.name === 'Genes'); }}
                                      className="concept-key-aspect-concept"
                                    >
                                      { wordConcept.name }
                                    </div>
                                  ))
                                }
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <div id="conceptCategoryAnalysis">
                    <div className="concept-category-analysis">
                      <div className="concept-category-analysis-title">Select related sets</div>
                      <div className="concept-details-separator concept-details-separator-small" />
                      {
                        !isEmptyObject(conceptCategoryAnalysisData) &&
                        <div className="flex-grid">
                          <div className="col-1">
                            <BilevelPartitionDiagram
                              selectTypeCb={this.openCreateSetPopup}
                              data={conceptCategoryAnalysisData}
                            />
                          </div>
                        </div>
                      }
                      <Spinner
                        isLoading={conceptCategoryAnalysisLoadingKey && !conceptCategoryAnalysisError}
                        containerClassName="row text-center"
                      />
                      {
                        conceptCategoryAnalysisError &&
                        <div className="row text-center error-text">
                          Sorry, error occurred.
                          <br />
                          {conceptCategoryAnalysisError}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <div className="concept-details-card">
                    <div className="concept-details-card-title">
                      { concept.name }
                    </div>
                    {this.renderConceptImageBlock()}
                    {
                      synonyms.length > 0 &&
                      <div>
                        <div className="concept-details-separator" />
                        <div className="concept-details-card-section">
                          Synonyms
                        </div>
                        <div className="concept-details-separator" />
                        <div className="concept-details-card-synonyms">
                          { synonyms }
                        </div>
                      </div>
                    }
                    {
                      measures.length > 0 &&
                      <div>
                        <div className="concept-details-separator" />
                        <div className="concept-details-card-section">
                          Measures
                        </div>
                        <div className="concept-details-separator" />
                        <div className="concept-details-card-measures">
                          { measures }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <Loader isLoading={loadingKey && !error} />
        {
          openGeneDetails &&
          <ModalComponent
            isOpen={openGeneDetails}
            closeCb={this.handleGenesDetailClose}
          >
            <SetAnalysisGenesDetail
              fromConceptDetails={true}
              modalToShow={this.state.modalToShow}
            />
          </ModalComponent>
        }
        {
          createSetPopup.show &&
          <ModalComponent
            isOpen={createSetPopup.show}
            closeCb={this.closeCreateSetPopup}
            modalClassName="modal_no-paddings"
          >
            <CreateSet
              config={createSetPopup.config}
              closePopup={this.closeCreateSetPopup}
            />
          </ModalComponent>
        }
        <Error show={!!error} error={error} />
      </div>
    );
  }
}

ConceptDetails.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    openGeneDetails: getOpenGeneDeatailsKey(state),
    concept: getConceptDetails(state),
    loadingKey: getLoadingKey(state),
    error: getError(state),
    conceptCategoryAnalysisData: getCategoryAnalysisData(state),
    conceptCategoryAnalysisLoadingKey: getCategoryAnalysisLoading(state),
    conceptCategoryAnalysisError: getCategoryAnalysisError(state),
    currentPage: getCategoryAnalysisNodesCurrentPages(state),
    totalPages: getCategoryAnalysisNodesTotalPages(state),
    totalItems: getCategoryAnalysisNodesTotalItems(state),
    conceptCategoryAnalysisNodesLoadingKey: getCategoryAnalysisNodesLoading(state),
    conceptCategoryAnalysisNodesError: getCategoryAnalysisNodesError(state),
    lastVisitedConceptId: getLastVisitedConceptId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLastVisitedConceptId(data) {
      dispatch(conceptDetailsSetLastVisitedConceptIdAction(data));
    },
    openFullSet(newFullSet) {
      dispatch(setNewFullSet(newFullSet));
    },
    conceptDetailsRequest(data) {
      dispatch(conceptDetailsRequest(data));
    },
    loading() {
      dispatch(loading());
    },
    categoryAnalysisRequest(id) {
      dispatch(conceptCategoryAnalysisRequest(id));
    },
    categoryAnalysisReset() {
      dispatch(conceptCategoryAnalysisReset());
    },
    conceptCategoryAnalysisNodesRequest(data) {
      dispatch(conceptCategoryAnalysisNodesRequest(data));
    },
    reset() {
      dispatch(resetAction());
    },
    closeGenesDetail() {
      dispatch(closeGenesDetail());
    },
    getShortConceptsDetails(data) {
      dispatch(getShortConceptsDetails(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConceptDetails));
